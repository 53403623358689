<template>
  <DraggableModal :visible="visible" width="800px" title2="Create 2D Model" @cancel="handleCancel"
    :confirmLoading="loading" :ok="handleApply" :okText="current == 0 ? 'Next' : 'Apply'">
    <template #footer>

      <a-upload style="display: inline-block;" :fileList="[]" :customRequest="() => { }"
        :before-upload="handleBeforeUpload">
        <a-button>
          <upload-outlined></upload-outlined>
          Import DataFile
        </a-button>
      </a-upload>
      <a-button @click="exportToTxt">
        <download-outlined />Export File</a-button>
      <a-button v-if="current == 0" key="back" :type="advanced ? 'primary' : 'default'" @click="advanced = !advanced">
        <tool-outlined />Advanced</a-button>
    </template>
    <a-steps :current="current" @change="(e) => current = e" size="small">
      <a-step title="2D Parameter" />
      <a-step title="3D Parameter" />
    </a-steps>
    <Create2D ref="formRef2" v-if="current == 0" :advanced="advanced" v-model:twoDInputMap="twoDInputMap"
      v-model:inputAddData="inputAddData" />

    <Create3D ref="formRef3" v-else-if="current == 1" v-model:threeDInputMap="threeDInputMap" />
  </DraggableModal>
</template>

<script setup>
import { defineProps, defineEmits, reactive, watch, ref, computed } from "vue";
import { saveAs } from 'file-saver';

import { DownloadOutlined, UploadOutlined, ToolOutlined } from '@ant-design/icons-vue';
import DraggableModal from "@/components/DraggableModal/index.vue";
import Create2D from "./Create2D";
import Create3D from "./Create3D";
import model from "@/api/modules/model";
import { to,formatArrayNoNewlines } from "@/utils/utils";
const current = ref(0)
import { useStore } from "vuex";

import { message } from 'ant-design-vue';
const store = useStore();
defineProps({
  visible: Boolean,
});
const emit = defineEmits(["update:visible"]);
const formRef2 = ref(null);
const formRef3 = ref(null);
const loading = computed(() => store.state.control.loading);
const advanced = ref(false);
const block2dInput = computed(() => store.state.model.block2dInput);
const block3dInput = computed(() => store.state.model.block3dInput);
const advancedData = computed(() => store.state.model.inputAddData);
let twoDInputMap = reactive({
  ...block2dInput.value,
});
let threeDInputMap = reactive({
  ...block3dInput.value,
});
let inputAddData = reactive([
  ...advancedData.value,
]);
watch(current, () => {
  twoDInputMap = reactive({
    ...twoDInputMap,
  });
  threeDInputMap = reactive({
    ...threeDInputMap,
  });
  inputAddData = reactive([
    ...inputAddData
  ]);
})
const exportToTxt = () => {
  const jsonData = {
    "2d": twoDInputMap,
    advanced: inputAddData,
    "3d": threeDInputMap
  };
  const jsonStr = JSON.stringify(jsonData, null, 2); // 使用两个空格进行缩进，保持可读性
  const formattedJsonStr = formatArrayNoNewlines(jsonStr); // 格式化数组，去除换行符

  const blob = new Blob([formattedJsonStr], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, 'data.txt');
};

function mergeObjects(a, b) {
  const merged = { ...a }; // 复制第一个对象

  for (let prop in b) {
    if (Object.prototype.hasOwnProperty.call(b, prop) && Object.prototype.hasOwnProperty.call(a, prop)) {
      merged[prop] = b[prop]; // Add properties from the second object that exist in the first object
    }
  }


  return merged;
}
const handleBeforeUpload = (file) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const contents = e.target.result;
    try {
      const data = JSON.parse(contents);
      if (data?.['2d']) {
        twoDInputMap = reactive({
         ...mergeObjects(twoDInputMap,data['2d'])
        });
      }
      if (data?.['3d']) {
        threeDInputMap = reactive({
         ...mergeObjects(threeDInputMap,data['3d'])
        });

      }
      if (data?.['advanced']) {
        inputAddData = reactive([
          ...data['advanced'],
        ]);
      }
      let f = current.value + 0;
      current.value = -1;
      setTimeout(() => {
        current.value = f;
      }, 100)
    } catch (error) {
      console.error(error)
      message.error('Data Invalid')
    }
  };

  reader.readAsText(file);
};

const handleApply = async () => {
  try {
    await (current.value ? formRef3 : formRef2).value.formRef.validate();
  } catch (errorInfo) {
    console.log('Failed:', errorInfo);
    return;
  }

  if (current.value == 0) {
    current.value = 1;
  } else {
    handleApply2();
  }
}
function handleCancel() {
  emit("update:visible", false);
}
function conversion (baseArray,n){ 
    let len = baseArray.length
    let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1)
    let res = []
    for (let i = 0; i < lineNum; i++) {
      // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
      let temp = baseArray.slice(i * n, i * n + n)
      res.push(temp)
    }
     return res
}
const line2dConfig=[
  [0,1],[0,15],[0,9],[0,24],
  [1,14],[1,25],[1,16],
  [24,4],[24,20],
  [25,17],[25,21],
  [2,3],[2,13],[2,26],[2,19],
  [3,12],[3,27],[3,11],
  [27,23],[27,10],
  [26,22],[26,18]
];

const create2D = async () => {
  store.commit("SET_2D_INPUT", twoDInputMap);
  store.commit("SET_3D_INPUT", threeDInputMap);
  const paramsData = {
    block2dInput: twoDInputMap,
    block3dInput: threeDInputMap.typeValue[threeDInputMap.type],
    inputAddData
  }


  const [err2, res] = await to(model.getGeoBlade2D(paramsData));
  if (err2) {

    store.commit('SET_LOADING', false);
    window.addLog.error('Network error, please refresh');
    return;
  }
  let xAxis = [0, 0];
  let yAxis = [0, 0];
  let points=[];
  let list = res.data.map(group => {
    let li = []
    let point=[];
    group.forEach((n, index) => {
        let i = 0;
      if(index==group.length-1){
        let arr=conversion(n,3);
        
        arr.forEach((n)=>{
          point.push(n[0],n[1])
        })
        line2dConfig.forEach((n,i)=>{
        li.push({ x: arr[n[0]][0], y: arr[n[0]][1], line:("line" + index+i),type:'point',pointId:n[0]});
        li.push({ x: arr[n[1]][0], y: arr[n[1]][1], line:("line" + index+i),type:'point',pointId:n[1]});
          
        })
      }else{

        while (i < n.length) {
        if (n[i] < xAxis[0]) {
          xAxis[0] = n[i];
        }
        if (n[i] > xAxis[1]) {
          xAxis[1] = n[i];
        }
        if (n[i + 1] < yAxis[0]) {
          yAxis[0] = n[i + 1];
        }
        if (n[i + 1] > yAxis[1]) {
          yAxis[1] = n[i + 1];
        }


        li.push({ x: n[i], y: n[i + 1], line:index==0?'blade curve': ("line" + index),type:'noPoint' });
        i += 3;
      }
      }
    })
    points.push(point);
    return li;
  })
  store.commit('SET_POINTS_2D',points);
  const getBound = (num, e) => {
    return (Math.floor(num / 10)+(num>=0?0:1)) * 10 + (e * 10);
  }
  xAxis = [getBound(xAxis[0], -1), getBound(xAxis[1], 1)];  //x axis limits 
  yAxis = [getBound(yAxis[0], -1), getBound(yAxis[1], 1)];  //y axis
  let data2d = {
    xAxis, yAxis,
    list
  }
  store.commit("SET_MODEL_2D_SHOW", true);
  store.commit('SET_DATA_STATUS', 0);

  setTimeout(() => {
    store.commit("SET_MODEL_2D", data2d);
    store.commit("SET_INPUT_ADD_DATA", inputAddData);
    
  })
  
  await store.dispatch('resetModelItems')
  return Promise.resolve();
}
const handleApply2 = async () => {

  store.commit('SET_LOADING', true);

  store.commit('SET_HISTORY_CURRENT', 0)
  await create2D();
  store.commit('SET_HISTORY', {
    hisType: 'CreateModel',
    CreateModel: {
      type: '2D',
      block2dInput: twoDInputMap,
      block3dInput: threeDInputMap,
      inputAddData
    }
  })
  store.commit('SET_LOADING', false);
  handleCancel();
}

const historyCurrent = computed(() => store.state.control.historyCurrent);
const historyList = computed(() => store.state.control.historyList);
const historyFalg = computed(() => store.state.control.historyFalg);

const generateModel = async () => {
  const paramsData = {
    block2dInput: twoDInputMap,
    block3dInput: threeDInputMap.typeValue[threeDInputMap.type],
    inputAddData
  }

  const [err] = await to(
    model.createModal(paramsData)
  );


  if (err) {
    window.addLog.error('Network error, please refresh');
    return;

  }
  const [err2] = await to(model.generateBlocks());
  if (err2) {
    window.addLog.error('Network error, please refresh');
    return;
  }
  await store.dispatch('resetModelItems')
  await store.dispatch('resetBlock')
  await store.dispatch('resetMesh')
  store.commit('SET_MODEL_IMPORT_TYPE', false);

  store.commit("SET_DATA_STATUSAdd");
  store.commit("SET_MODEL_2D_SHOW", false);
  return Promise.resolve();
}
watch(historyCurrent, async (newInd, oldInd) => {
  if (!historyFalg.value) return;

  if (historyList.value[newInd].hisType == 'CreateModel' && historyList.value[newInd]?.CreateModel?.type == '2D') {


    const historyData = historyList.value[newInd].CreateModel;
    twoDInputMap = historyData.block2dInput;
    threeDInputMap=historyData.block3dInput;
    inputAddData=historyData.inputAddData
  store.commit("SET_2D_INPUT", twoDInputMap);
  store.commit("SET_3D_INPUT", threeDInputMap);
    await create2D();
    await generateModel();
    window.addLog.info(newInd < oldInd ? "Undo Success" : "Redo Success")
    setTimeout(() => {
      store.commit('SET_LOADING', false);
    })
  }
  // else if (historyList.value[newInd].hisType == 'CreateModel' && historyList.value[newInd]?.CreateModel?.type == '3D') {
  //   const historyData = historyList.value[newInd].CreateModel;
  //   await create3D(historyData.block2dInput, historyData.block3dInput);

  //   window.addLog.info(newInd < oldInd ? "Undo Success" : "Redo Success")
  //   setTimeout(() => {
  //     store.commit('SET_LOADING', false);
  //   })
  // }
})

</script>

<style scoped lang="scss">
form {
  margin: 10px;
}

.line {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

label {
  flex: 1;
  height: 25px;
  float: left;
  width: 80px;
  line-height: 25px;
  font-size: 14px;
}

input {
  width: 150px;
  height: 20px;
  font-size: 14px;
}

.apply-btn {
  width: 324px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #adadad;
  background-color: #e1e1e1;
  margin-top: 15px;
  cursor: pointer;
}
</style>
