<template>
  <div class="wrap">
    <!-- 头部区域 -->
    <header>
      <MenuBar />
      <Toolbar @clicked="selectMenu" />
    </header>
    <!-- 主窗口 -->
    <main>
      <Split v-model="split1" min="280px">
        <template #left>
          <div class="sidebar-left">
            <ModelTree class="model-tree" />
            <PropertyBar class="property-bar" />
          </div>
        </template>
        <template #right>
          <Split v-model="split2" mode="vertical" class="sidebar-right">
            <template #top>
              <ModelDisplay :style="{ display: !model2DShow ? 'block' : 'none' }" class="model-display" />
              <Model2D :style="{ display: model2DShow ? 'block' : 'none' }" class="model-display-2d" />
              <div class="modelSwitch">
                <a-switch :checked="!model2DShow" @click="changeModelShow" checked-children="3D"
                un-checked-children="2D" />

                <a-button v-if="!model2DShow" shape="circle" size="small" @click="resetCenter">
                  <template #icon>
                  <border-inner-outlined />
                  </template>
                </a-button>
                
            <a-upload style="display: inline-block;" :fileList="[]" :customRequest="() => { }"
              :before-upload="handleBeforeUpload">
                <a-button v-if="model2DShow" shape="circle" size="small">
                  <template #icon>
                    <PictureOutlined />
                  </template>
                </a-button>
            </a-upload>

            
            <a-upload style="display: inline-block;" :fileList="[]" :customRequest="() => { }"
              :before-upload="import2dPoint">
                <a-button v-if="model2DShow" shape="circle" size="small">
                  <template #icon>
                    <CloudUploadOutlined />
                  </template>
                </a-button>
            </a-upload>

            
           
                <a-button v-if="model2DShow" @click="export2dPoints" shape="circle" size="small">
                  <template #icon>
                    <CloudDownloadOutlined />
                  </template>
                </a-button>

            <!-- <a-button style="display: inline-block;" v-if="model2DShow" shape="circle" size="small" @click="showCenter">
              <BorderOuterOutlined />
            </a-button> -->
              </div>
              
              <!-- <viewer :images="viewerImages" :options="viewerOptions" ref="viewer"></viewer> -->
              


              <div v-if="chartType && chartColors.length > 0" class="chartColors">
                <div style="color:white;background: black;height: 26px;line-height: 26px;">
                  Quality
                </div>
                <div :style="{ background: 'rgb(158, 1, 66)' }">
                  &lt; {{ chartColors[0].data.x1 / 100 }}
                </div>
                <div v-for="(item, index) in chartColors" :key="index" :style="{ background: item.color }">
                  &lt; {{ item.data.x2 / 100 }}
                </div>
                <div :style="{ background: 'rgb(94, 79, 162)' }">
                  &gt; {{ chartColors[chartColors.length - 1].data.x2 / 100 }}
                </div>

              </div>
            </template>
            <template #bottom>
              <MessageBar class="message-bar" />
            </template>
          </Split>
        </template>

      </Split>
    </main>
  </div>
</template>

<script setup>
import MenuBar from "./home/MenuBar";
import Model2D from "./home/Model2D";
import Toolbar from "./home/Toolbar";
import ModelTree from "./home/ModelTree";
import PropertyBar from "./home/PropertyBar";
import ModelDisplay from "./home/ModelDisplay";
import MessageBar from "./home/MessageBar";

import { message } from "ant-design-vue";
// import Test from './home/Test'
import { BorderInnerOutlined,PictureOutlined,CloudDownloadOutlined,CloudUploadOutlined } from '@ant-design/icons-vue';
import { useStore } from "vuex";
import { computed, ref } from "vue";
import {exportArrayAsJSON } from "@/utils/utils.js";
import { api as viewerApi } from "v-viewer"
const chartType = computed(() => store.state.menu.chartType);

const chartColors = computed(() => store.state.control.chartColors);
const store = useStore();
const split1 = ref('280px')
const split2 = ref(0.8)
const model2DShow = computed(() => store.state.control.model2DShow);
const points2D = computed(() => store.state.control.points2D);
// const viewer=ref(null)
const viewerImages = ref([])
// const viewerOptions = {
//       navbar: false, // 隐藏导航栏
//       toolbar: false, // 隐藏工具栏
//       button: false, // 隐藏播放、上一张和下一张按钮
//     };


function selectMenu(name) {
  console.log(name);
}
const resetCenter=()=>{

  store.commit("SET_RE_CENTER");
}
const changeModelShow = (e) => {
  store.commit("SET_MODEL_2D_SHOW", !e);
}
const handleBeforeUpload = (file) => {
  const reader = new FileReader();

  reader.onload = async(e) => {
    console.log(e);
    viewerImages.value=[{src:e.target.result,alt:'1'}]
    viewerApi({
          options: {
            toolbar: true,
            navbar: false,
            title:false,
            zoomRatio:0.005,
          },
          images:[{src:e.target.result,alt:'1'}]
        })
  };

  reader.readAsDataURL(file);
};
const import2dPoint=(file) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const contents = e.target.result;
    try {
      
      const data = JSON.parse(contents);

     
      store.commit("SET_POINTS_2D", data );
      setTimeout(()=>{
        store.commit("SET_UPDATE_POINTS_2D" );
      })
      
    } catch (error) {
      console.error(error)
      message.error('Data Invalid')
    }
  };

  reader.readAsText(file);
};

const export2dPoints= ()=>{
  
  exportArrayAsJSON(points2D.value)
}


</script>

<style scoped>
::v-deep .ant-upload-select-text{
  float: left;
}
.wrap {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.modelSwitch {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 10;
}

.modelSwitch>*{
  float: left;
  margin-right: 6px;
}
/* 头部区域 ----------------------*/
header {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

main {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
}

main .sidebar-left {
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  background-color: white;
}

.model-tree {
  height: 40%;
}

.property-bar {
  height: 60%;
}

/* [2] 右侧边栏 */
main .sidebar-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
}

.model-display {
  flex: 1;
  width: 100%;
}

.model-display-2d {

  flex: 1;
  background-color: white;
  width: 100%;
}

.chartColors {
  position: absolute;
  right: 30px;
  top: 10px;
  width: 60px;
  font-size: 12px;
  color: black;
  line-height: 20px;
  text-align: center;
}

.chartColors>div {
  height: 20px;
  width: 100%;
}

.message-bar {
  height: 100%;
  width: 100%;
}

</style>
