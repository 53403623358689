import { createStore } from "vuex";
import menu from "./modules/menu";
import model from "./modules/model";
import control from "./modules/control";
import user from "./modules/user";

export default createStore({
  modules: {
    menu,
    model,control,user
  },
});
