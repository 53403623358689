import { post, get } from "../request";

export default {
  
  login(data) {
    return post("/user/login", data);
  },
  enroll(data) {
    return post("/user/enroll", data);
  },
  sendMail(mail){
    return get(`/user/sendMail/${mail}`);
  },
  reset(data){
    return post(`/user/reset/`,data);
  },
  getUserData(id){
    return get(`/user/data/${id}`);
  },
  signOut(id){
    return get(`/user/signOut/${id}`);
  },
  //创建讨论组，成功则返回讨论组key。该接口在登录之后方可操作
  createDiscussionGroups(name){
    return get(`/projectGroup/createDiscussionGroups/${name}`);
  },
  //加入讨论组。该接口在登录之后方可操作
  joinDiscussionGroups(data){ //key  enterKey
    return get(`/projectGroup/joinDiscussionGroups`,data);
  },
  //获取进入讨论组的密钥
  getEnterKey(key){
    return get(`/projectGroup/getEnterKey/${key}`);
  },
  //退出讨论组。该接口在登录之后方可操作
  exitDiscussionGroups(id){
    return get(`/projectGroup/exitDiscussionGroups/${id}`);
  },
  //关闭/删除讨论组
  closeDiscussionGroups(id){
    return get(`/projectGroup/closeDiscussionGroups/${id}`);
  },
  //把xx踢出讨论组
  kickOutMembers(data){//key userId
    return get(`/projectGroup/kickOutMembers`,data);
  },
  //把xx设置为管理员
  addAdministrator(data){//key userId
    return get(`/projectGroup/addAdministrator`,data);
  },
  //获取讨论组信息
  getDiscussionGroups(id){
    return get(`/projectGroup/getDiscussionGroups/${id}`);
  },
  //获取讨论组内所有成员信息
  getDiscussionGroupsMembers(id){
    return get(`/projectGroup/getDiscussionGroupsMembers/${id}`);
  },
  //切换讨论组
  switchDiscussionGroups(id){
    return get(`/projectGroup/switchDiscussionGroups/${id}`);
  },
  //获取当前登录者所加入的所有讨论组
  getAllDiscussionGroups(){
    return get(`/projectGroup/getAllDiscussionGroups`);
  },
};
