<template>
  <DraggableModal :visible="visible" width="600px" title2="Set" @cancel="handleCancel"
    :confirmLoading="loading" :ok="handleApply" okText="Apply" class="setFrom">
    <el-form :model="set" >
      <el-form-item label="3D background-color" :label-width="200">
        <ColorPicker v-model="set.backgroundColor" @onChange="bgColorChange" />
      </el-form-item>
    </el-form>
  </DraggableModal>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import DraggableModal from "@/components/DraggableModal/index.vue";

const store = useStore();

defineProps({
  visible: Boolean,
});

const set = computed(() => store.state.control.set);
const emit = defineEmits(["update:visible"]);
const bgColorChange = (e) => {
  store.commit("SET_SET", {
    backgroundColor: e
  });
}
function handleCancel() {
  emit("update:visible", false);
}

async function handleApply() {


  handleCancel();
}
</script>

<style scoped lang="scss">
.setFrom form{
  margin: 10px;
  padding-bottom: 42px;
  min-height: 300px;
}
</style>
