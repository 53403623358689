<template>
  <div class="property-bar">
    <ToolTitle :titleName="toolName"></ToolTitle>
    <keep-alive>
      <component :is="componentsMap[toolName]"></component>
    </keep-alive>
  </div>
</template>

<script setup>
import {  defineProps, computed } from "vue";
import { useStore } from "vuex";
import ToolTitle from "./ToolTitle";
import CreateModel from "../property/CreateModel";
import ParameterAdjust from "../property/ParameterAdjust";
import ScanPlane from "../property/ScanPlane";
import CoolingsCut from "../property/CoolingsCut";
import CheckMeshQuality from "../property/CheckMeshQuality";
import IjkControl from "../property/IjkControl";
import RimSealing from "../property/RimSealing";

const store = useStore();
const toolName = computed(() => store.state.menu.toolName);
const componentsMap = {
  ToolTitle,
  CreateModel,
  ScanPlane,
  ParameterAdjust,
  CoolingsCut,
  CheckMeshQuality,
  IjkControl,
  RimSealing
};

// 接受来自父组件的传参
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  data: {
    type: String,
    default: "IjkControl",
    required: true,
  },
});
</script>

<style scoped></style>
