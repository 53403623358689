import model from "@/api/modules/model";
import { to } from "@/utils/utils";
export default {
  namespace: true,
  modelImportType: false,
  state: {
    block2dInput: {
      axial_chord_length: 26.0458,
      pitch: 21.9775,
      stagger_angle: -30,
      throat_length: 1.040721e+01,
      unguided_turning: 2.384479e+01,
      leading_edge_radius: 0.781374,
      trailing_edge_radius: 0.520916,
      leading_edge_wedge_angle: 30,
      trailing_edge_wedge_angle: 5,
      leading_edge_metal_angle: 46,
      trailing_edge_metal_angle: -66.5,
      length_over_chord_inlet:0.4,
      length_over_chord_outlet:1,
      
      // per_18:0.34,
      // per_19:0.34,
      // por_19:1,
      // por_6_0:0,
      // por_6_1:1,
    },
    inputAddData: [6.845508e-01, 7.345243e-01, 2.268695e-01, 3.353179e-01,9.918515e-01, 4.358876e-01, -2.223638e-01, 2.230405e-01,2.632947e-01, 2.384253e+00, 5.488735e-01, 1.496262e-01],
    block3dInput: {
      type: 2,
      typeValue: [
        {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0,
          twist_rotor: [2.426, 0, -2.83],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2],
          lean_angle_rotor: [5, 0, 5],
          sweep_angle_rotor: [2, 0, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 0,
        },
        {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0.008,
          twist_rotor: [2.426, 0, -2.83, -2.894],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2, -0.2],
          lean_angle_rotor: [5, 0, 5, 5],
          sweep_angle_rotor: [2, 0, 2, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 1,
        },
        {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0.008,
          twist_rotor: [2.426, 0, -2.83, -2.894, -2.945],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2, -0.2, -0.2],
          lean_angle_rotor: [5, 0, 5, 5, 5],
          sweep_angle_rotor: [2, 0, 2, 2, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 2,
        },

      ]
    },
    lineMeshMertrics: {
      index: undefined,
      m_num: undefined,
      m_length: undefined,
      m_law: 0,
      m_spacing1: 0.03,
      m_ratio1: 1.2,
      m_spacing2: 0.03,
      m_ratio2: 1.2,
    },
    // 模型树选项记录
    modelTree: {
      Geometry: {
        Curves: true,
      },
      Blocking: {
        Edges: true,
        Faces: true,
        Blocks: true,
      },
      mesh: {
        Lines: true,
        Shells: true,
        Volumns: true,
      },
      Group: true,
    },
    initLines: [],
    lines: [],
    surfaces: [],
    volumns: [],
    meshLine: [],
    meshShells: [],
    meshVolumns: [],
    dataStatus: -1,
    selectedTreeKeys: ["0-0", "0-0-0"],
    isSelectStatus: false,
    meshStatus: 0,
    lineIndex: undefined,
    coolingsCutStatus: 0,
    coolingsCutCheck: false,
    coolingsCutSelectedTreeKey: [],
    BableBlock: [],
    BableMesh: [],
    BableTipBlock: [],
    BableTipMesh: [],
    bableBlockMatrix: [],
    selectLineGroup: [],//选取的线组
  },
  mutations: {
    SET_2D_INPUT(state, data) {
      state.block2dInput = data;
    },
    SET_INPUT_ADD_DATA(state, data) {
      state.inputAddData = data;
    },
    SET_3D_INPUT(state, data) {
      state.block3dInput = data;
    },
    SET_LINES(state, data) {
      state.lines = data;
    },
    SET_INITLINES(state, data) {
      state.initLines = data;
    },
    SET_SURFACES(state, data) {
      state.surfaces = data;
    },
    SET_VOLUMNS(state, data) {
      state.volumns = data;
    },
    SET_DATA_STATUS(state, data) {
      state.dataStatus = data;
    },

    SET_DATA_STATUSAdd(state) {
      state.dataStatus = state.dataStatus + 1;
    },
    SET_TREE_KEYS(state, data) {
      state.selectedTreeKeys = data;
    },
    SET_COOLINGS_TREE_KEYS(state, data) {
      state.coolingsCutSelectedTreeKey = data;
    },
    SET_BABLE_BLOCK(state, data) {
      state.BableBlock = data;
    },
    SET_BABLE_MESH(state, data) {
      state.BableMesh = data;
    },
    SET_BABLE_TIP_BLOCK(state, data) {
      state.BableTipBlock = data;
    },
    SET_BABLE_TIP_MESH(state, data) {
      state.BableTipMesh = data;
    },
    SET_COOLINGS_CUT_CHECK(state, data) {
      state.coolingsCutCheck = data;
    },
    SET_COOLINGS_CUT_STATUS(state, type) {
      state.coolingsCutStatus = type ? 1 : state.coolingsCutStatus + 1;
    },
    RESET_COOLINGS_CUT_STATUS(state) {
      state.coolingsCutStatus = 0;
    },
    // 开启选择mesh状态
    SET_IS_SELECT_STATUS(state, data) {
      state.isSelectStatus = data;
    },
    GENERATE_MESH(state) {
      state.meshStatus = state.meshStatus + 1;
    },
    SET_NO_MESH(state) {
      state.meshStatus = 0
    },
    SET_LINE_INDEX(state, data) {
      state.lineIndex = data;
    },
    SET_LINE_MESH_MERTRICS(state, data) {
      state.lineMeshMertrics = data;
    },
    SET_MESH_LINE(state, data) {
      state.meshLine = data;
    },
    SET_MESH_SHELLS(state, data) {
      state.meshShells = data;
    },
    SET_MESH_VOLUMNS(state, data) {
      state.meshVolumns = data;
    },
    SET_SELECT_LINEGROUP(state, data) {
      state.selectLineGroup = data;
    },
    SET_BABLE_BLOCK_MATRIX(state, data) {
      state.bableBlockMatrix = data;
    },
    SET_MODEL_IMPORT_TYPE(state, data) {
      state.modelImportType = data;
    },
  },
  actions: {
    async resetEdges(store){

      const [err3, res3] = await to(model.getLines(2));
      if (err3) {
        return;
      }
      // 存储数据
      store.commit("SET_LINES", res3.data);
      store.commit("SET_DATA_STATUSAdd");
      return Promise.resolve();
    },
    async resetBlock(store) {
      const [err1, res1] = await to(model.getLines(0));
      if (err1) {
        return;
      }


      const [err3, res3] = await to(model.getLines(2));
      if (err3) {
        return;
      }
      // 存储数据
      store.commit("SET_LINES", res3.data);
      store.commit("SET_INITLINES", res1.data);

      const [err4, res4] = await to(model.getSurfaces(2));
      if (err4) {
        return;
      }
      store.commit("SET_SURFACES", res4.data);

      const [err5, res5] = await to(model.getVolumns(2));
      if (err5) {
        return;
      }
      store.commit("SET_VOLUMNS", res5.data);

      store.commit("SET_DATA_STATUSAdd");

      return Promise.resolve();
    },

    async resetBlockNoUpdate(store) {
      const [err1, res1] = await to(model.getLines(0));
      if (err1) {
        return;
      }


      const [err3, res3] = await to(model.getLines(2));
      if (err3) {
        return;
      }
      // 存储数据
      store.commit("SET_LINES", res3.data);
      store.commit("SET_INITLINES", res1.data);

      const [err4, res4] = await to(model.getSurfaces(2));
      if (err4) {
        return;
      }
      store.commit("SET_SURFACES", res4.data);

      const [err5, res5] = await to(model.getVolumns(2));
      if (err5) {
        return;
      }
      store.commit("SET_VOLUMNS", res5.data);


      return Promise.resolve();
    },
    async resetMesh(store) {
      const [err3, res3] = await to(model.getLines(1));
      if (err3) {
        return;
      }
      // 存储数据
      store.commit("SET_MESH_LINE", res3.data);

      const [err4, res4] = await to(model.getSurfaces(1));
      if (err4) {
        return;
      }
      store.commit("SET_MESH_SHELLS", res4.data);

      const [err5, res5] = await to(model.getVolumns(1));
      if (err5) {
        return;
      }
      store.commit("SET_MESH_VOLUMNS", res5.data);

      setTimeout(() => {
        store.commit(res3.data.length == 0 ? "SET_NO_MESH" : "GENERATE_MESH");
        return Promise.resolve();
      })


    },
    async cteateBable(store, { type, tipType }) {
      const [err2, res2] = await to(model.getCoolingBlocksIndexMatrix(type));
      if (err2) {
        window.addLog.error('Network error, please refresh');
        return
      }
      if (res2.code == 200) {
        store.commit(type == 0 ? "SET_BABLE_BLOCK" : "SET_BABLE_TIP_BLOCK", res2.data);
      }
      if (tipType) {

        store.commit("RESET_COOLINGS_CUT_STATUS");
        setTimeout(() => {
          store.commit("SET_COOLINGS_CUT_STATUS", true);
        })
      }
      return Promise.resolve();
    },
    async createModal() {
      const [err2] = await to(model.generateBlocks());
      if (err2) {
        return;
      }
      return Promise.resolve();
    }
  },
};
