<template xmlns:el-col="http://www.w3.org/1999/html">
  <el-form ref="formRef" :model="block3dInput" :rules="rules">

    <el-form-item label="Tip Mode" :label-width="270">
      <el-select v-model="block3dInput.type" @change="typeChange">
        <el-option :value="0" label="stator tip"></el-option>
        <el-option :value="1" label="flat tip"></el-option>
        <el-option :value="2" label="squealer tip"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Blade Direction" :label-width="270">
      <el-input :value="block3dInput.typeValue[block3dInput.type].blade_direction" type="number"
        @input="block3dInputChange(block3dInput)"
        @click="block3dInput.typeValue[block3dInput.type].blade_direction = block3dInput.typeValue[block3dInput.type].blade_direction == 1 ? -1 : 1" />
    </el-form-item>

    <el-form-item label="Flow Coefficient" prop="flow_coefficient" :label-width="270">
      <el-input v-model="block3dInput.typeValue[block3dInput.type].flow_coefficient" type="number"
        @input="block3dInputChange(block3dInput)" />
    </el-form-item>
    <el-form-item label="Number Blade" :label-width="270">
      <el-input v-model="block3dInput.typeValue[block3dInput.type].N_blade_rotor" autocomplete="off"
        @input="block3dInputChange(block3dInput)" />
    </el-form-item>
    <el-form-item label="Span (mm)" :label-width="270">
      <el-input v-model="block3dInput.typeValue[block3dInput.type].span_rotor" autocomplete="off"
        @input="block3dInputChange(block3dInput)" />
    </el-form-item>
    <el-form-item label="Tip Clearance" prop="tip_clearance_rotor" :label-width="270">
      <el-input v-model="block3dInput.typeValue[block3dInput.type].tip_clearance_rotor" type="number"
        @input="block3dInputChange(block3dInput)" :disabled="block3dInput.type == 0" />
    </el-form-item>
    <el-form-item label="Twist" :label-width="270">
      <el-row :gutter="16">
        <el-col v-for="index of tipModeArrLength[block3dInput.type]" :key="index" :span="4">
          <el-input v-model="block3dInput.typeValue[block3dInput.type].twist_rotor[index - 1]" type="number"
            :disabled="block3dInput.indicator_stack == 0" @input="block3dInputChange(block3dInput)" />
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="Rotation Center X Percentage" :label-width="270">
      <el-input v-model="block3dInput.typeValue[block3dInput.type].rotation_center_x_percentage_rotor" autocomplete="off"
        @input="block3dInputChange(block3dInput)" />
    </el-form-item>
    <el-form-item label="Taper Angle" :label-width="270">
      <el-row :gutter="16">
        <el-col v-for="index of tipModeArrLength[block3dInput.type]" :key="index" :span="4">
          <el-input v-model="block3dInput.typeValue[block3dInput.type].taper_angle_rotor[index - 1]" type="number"
            :disabled="block3dInput.indicator_stack == 0" @input="block3dInputChange(block3dInput)" />
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="Lean Angle" :label-width="270">
      <el-row :gutter="16">
        <el-col v-for="index of tipModeArrLength[block3dInput.type]" :key="index" :span="4">
          <el-input v-model="block3dInput.typeValue[block3dInput.type].lean_angle_rotor[index - 1]" type="number"
            :disabled="block3dInput.indicator_stack == 0" @input="block3dInputChange(block3dInput)" />
        </el-col>

      </el-row>
    </el-form-item>
    <el-form-item label="Sweep Angle" :label-width="270">
      <el-row :gutter="16">
        <el-col v-for="index of tipModeArrLength[block3dInput.type]" :key="index" :span="4">
          <el-input v-model="block3dInput.typeValue[block3dInput.type].sweep_angle_rotor[index - 1]" type="number"
            :disabled="block3dInput.indicator_stack == 0" @input="block3dInputChange(block3dInput)" />
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="Indicator Stack" :label-width="270">
      <el-select v-model="block3dInput.typeValue[block3dInput.type].indicator_stack" @change="indicatorStackChange">
        <el-option v-for="item in stackingMethodSelectData" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item label="Squealer Height" prop="squealerHeight" v-if="block3dInput.type === 2"
      :label-width="270">
      <el-row :gutter="16">
        <el-col v-for="index of 4" :key="index" :span="4">
          <el-input v-model="block3dInput.typeValue[block3dInput.type].squealer_height[index - 1]" type="number"
            @input="block3dInputChange(block3dInput)" />
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { defineEmits, defineProps, defineExpose, ref, reactive, onMounted } from "vue";

// import { useStore } from "vuex";
// const store = useStore();
const formRef = ref(null);
const props = defineProps({
  threeDInputMap: Object,
});
defineExpose({
  formRef
})
const tipModeArrLength = [3, 4, 5];
const stackingMethodSelectData = reactive([
  {
    label: "straight blade",
    value: 0,
  },
  {
    label: "free vortex",
    value: 1,
  },
]);
const validateSquealerHeight = (rule, value, callback) => {
  const type = block3dInput.value.type;
  const arr = block3dInput.value.typeValue[type].squealer_height;
  const val = (Number(arr[0]) + Number(arr[1]) + Number(arr[2]) + Number(arr[3])) / 4;
  if (block3dInput.value.type == 0) {
    callback();
  } else if (arr[0] <= 0 || arr[1] <= 0 || arr[2] <= 0 || arr[3] <= 0 || Number(block3dInput.value.typeValue[type].tip_clearance_rotor) <= 0) {
    callback(new Error('Data Invalid'));
  } else if ((val + Number(block3dInput.value.typeValue[type].tip_clearance_rotor)) > 0.4) {
    callback(new Error('Data Invalid'));
  } else {
    callback();
  }
};
const doubleThanZero = (rule, value, callback) => {
  if (value <= 0) {
    callback(new Error('Data Invalid'));
  } else {
    callback();
  }
}
const rules = {
  squealerHeight: [
    { type: 'number', validator: validateSquealerHeight, trigger: 'blur' }
  ],
  tip_clearance_rotor: [
    { type: 'number', validator: validateSquealerHeight, trigger: 'blur' }
  ],
  flow_coefficient: [
    { type: 'number', validator: doubleThanZero, trigger: 'blur' }
  ],
}

const block3dInput = ref({
  type: 2,
  typeValue: [
  {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0,
          twist_rotor: [2.426, 0, -2.83],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2],
          lean_angle_rotor: [5, 0, 5],
          sweep_angle_rotor: [2, 0, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 0,
        },
        {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0.008,
          twist_rotor: [2.426, 0, -2.83, -2.894],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2, -0.2],
          lean_angle_rotor: [5, 0, 5, 5],
          sweep_angle_rotor: [2, 0, 2, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 1,
        },
        {
          flow_coefficient: 0.7,
          blade_direction:1,
          N_blade_rotor: 64,
          span_rotor: 33.386,
          tip_clearance_rotor: 0.008,
          twist_rotor: [2.426, 0, -2.83, -2.894, -2.945],
          rotation_center_x_percentage_rotor: 0.4,
          taper_angle_rotor: [0.2, 0, -0.2, -0.2, -0.2],
          lean_angle_rotor: [5, 0, 5, 5, 5],
          sweep_angle_rotor: [2, 0, 2, 2, 2],
          indicator_stack: 1,
          squealer_height: [0.01, 0.01, 0.01, 0.01],
          type: 2,
        },

  ]
})
onMounted(() => {
  block3dInput.value = { ...props.threeDInputMap };
})

const emit = defineEmits(["update:threeDInputMap"]);

const block3dInputChange = (e) => {
  emit('update:threeDInputMap', e)
}

const indicatorStackChange = (e) => {
  block3dInputChange(block3dInput.value);

  if (e == 0) {
    const type = block3dInput.value.type;
    let len=[0,0,0,0,0];
    len.length=tipModeArrLength[type];
    block3dInput.value.typeValue[type].twist_rotor =[...len];
    block3dInput.value.typeValue[type].taper_angle_rotor =[...len];
    block3dInput.value.typeValue[type].sweep_angle_rotor =[...len];
    block3dInput.value.typeValue[type].lean_angle_rotor = [...len];
    block3dInput.value.typeValue[type].rotation_center_x_percentage_rotor = 0;

  }
}
const typeChange = (e) => {
  if (e == 0) {
    const type = block3dInput.value.type;
    block3dInput.value.typeValue[type].tip_clearance_rotor = 0
  }
  block3dInputChange(block3dInput.value);
}

</script>

<style scoped lang="scss">
form {
  margin: 10px;
  padding-bottom: 42px;
}

.line {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

label {
  flex: 1;
  height: 25px;
  float: left;
  width: 80px;
  line-height: 25px;
  font-size: 14px;
}

input,
select {
  width: 246px;
  height: 20px;
  font-size: 14px;
}

.line2 input {
  width: 47px;
  margin-left: 3px;
}

.apply-btn {
  width: 324px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #adadad;
  background-color: #e1e1e1;
  margin-top: 15px;
  margin-left: 30px;
  cursor: pointer;
}</style>
