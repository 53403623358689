<template>
    <div :style="{minWidth: '500px', minHeight: '300px', padding: '0 5px'}">
        <Blade :row="postData.row" :colx="postData.columnX" :coly="postData.columnY"
        v-model:visible="bladeVisible" v-model:mnMax="mnMax" v-model:bladeDisabled="bladeDisabled"
        :bladeFanType="bladeType == 0 ? bladeFanType : bladeTipFanType" v-model:bladeTipDisabled="bladeTipDisabled" />
    </div>
</template>
  
<script setup>
import { ref ,computed,reactive,watch } from "vue";;
import { useStore } from "vuex";

import Blade from "../../property/Blade.vue";
const store = useStore();

const bladeModalProps = computed(() => store.state.control.bladeModalProps);

const bladeType = computed(() => store.state.menu.bladeType);
let postData = reactive({...bladeModalProps.value.c});

const mnMax = ref(bladeModalProps.value.mnMax);
const bladeVisible = ref(bladeModalProps.value.bladeVisible);
const bladeDisabled = ref(bladeModalProps.value.bladeDisabled);
const bladeFanType = ref(bladeModalProps.value.bladeFanType);
const bladeTipFanType = ref(bladeModalProps.value.bladeTipFanType);

const bladeTipDisabled = ref(bladeModalProps.value.bladeTipDisabled);
// watch(bladeVisible,(newVal)=>{
//     console.log(newVal);
// })
watch([bladeVisible,mnMax,bladeDisabled,bladeTipDisabled],()=>{
  store.commit('SET_BLADE_MODAL_PROPS', {...bladeModalProps.value,bladeVisible:bladeVisible.value,mnMax:mnMax.value,bladeDisabled:bladeDisabled.value,bladeTipDisabled:bladeTipDisabled.value});
})
watch(bladeModalProps,(newVal)=>{
    postData=reactive({...newVal.postData});
    bladeVisible.value= newVal.bladeVisible;
    bladeDisabled.value=newVal.bladeDisabled;
    bladeFanType.value=newVal.bladeFanType;
    bladeTipFanType.value=newVal.bladeTipFanType;
    bladeTipDisabled.value=newVal.bladeTipDisabled;
}, { deep: true, immediate: true })
</script>
  
<style scoped></style>
  