export default {
  namespace: true,
  state: {
    toolName: "CreateModel",
    chartType: 0,
    chartData: false,
    bladeType: -1,
    logList:[]
  },
  mutations: {
    SET_TOOL_NAME(state, data) {
      state.toolName = data;
    },
    SET_CHART_TYPE(state, data) {
      state.chartType = data;
    },
    SET_CHART_DATA(state, data) {
      state.chartData = data;
    },
    SET_BLADE_TYPE(state, data) {
      state.bladeType = data;
    },
    SET_LOG_LIST(state, data) {
      state.logList.push(data);
    },
  },
  actions: {},
};
