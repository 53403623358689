<template>
  <a-dropdown :trigger="['contextmenu']" :disabled="!bableClickFalg">
    <div ref="webgl" id="webgl">
      <div id="viewHelper"></div>
    </div>

    <template #overlay>
      <a-menu @click="menuClick">
        <a-menu-item :disabled="dropdownList[0].disabled()" :key="0"
          >Clear Selection</a-menu-item
        >
        <a-menu-item :disabled="dropdownList[1].disabled()" :key="1"
          >Merge Block</a-menu-item
        >
        <a-menu-item :disabled="rightClickId == -1" :key="2"
          >Undo Merge</a-menu-item
        >
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script setup>
import * as THREE from "three";
import { onMounted, watch, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { EditorControls } from "../../utils/EditorControls";
import { ViewHelper } from "../../utils/ViewHelper.js";
import { addLines } from "@/components/methods/addLines";
import { addFace3, addModel } from "@/components/methods/addFace";
import {
  disposeScene,
  setGroupToCenter,
  binarySearch,
  connectAdjacentCubes,
  to,
} from "../../utils/utils";

import model from "@/api/modules/model";

const store = useStore();
const dataStatus = computed(() => store.state.model.dataStatus);
const meshStatus = computed(() => store.state.model.meshStatus);
const toolName = computed(() => store.state.menu.toolName);
const coolingsCutCheck = computed(() => store.state.model.coolingsCutCheck);
const coolingsCutStatus = computed(() => store.state.model.coolingsCutStatus);

const set = computed(() => store.state.control.set);
const webgl = ref(null);
// 创建场景
const scene = new THREE.Scene();
//coolingsCut 场景
const sceneCooling = new THREE.Scene();

const reset = (newVal) => {
  let bg = new THREE.Color(newVal.backgroundColor);
  scene.background = bg;
  sceneCooling.background = bg;
};
reset(set.value);
// const gui = new GUI();
let camera, renderer, animationId, controls, helper;
const reCenter = computed(() => store.state.control.reCenter);
watch(reCenter, () => {
  if (reCenter.value) {
    camera.position.set(0, 0, 200);
    camera.resetZoom();
  }
});
function init() {
  const container = document.getElementById("webgl");
  const viewHelperDiv = document.getElementById("viewHelper");
  const pointLightGroup = new THREE.Group();
  pointLightGroup.name = "lightGroup";
  const pointLight1 = new THREE.PointLight(0xffffff);

  pointLight1.position.set(0, 0, 0);
  pointLightGroup.add(pointLight1);
  const sceneCoolingPoint = pointLightGroup.clone();
  scene.add(pointLightGroup);
  sceneCooling.add(sceneCoolingPoint);
  let pointLight = pointLightGroup;
  let scene0 = scene;
  // 创建相机
  // camera = new THREE.PerspectiveCamera(
  //   45,
  //   container.clientWidth / container.clientHeight,
  //   0.1,
  //   10000
  // );
  const cameraGroup = new THREE.Group();
  camera = new THREE.OrthographicCamera(
    container.clientWidth / -2,
    container.clientWidth / 2,
    container.clientHeight / 2,
    container.clientHeight / -2,
    1,
    3000
  );
  camera.position.set(0, 0, 200);
  camera.zoom = 10;
  camera.lookAt(0, 0, 0);
  camera.updateProjectionMatrix();

  camera.resetZoom = () => {
    camera.zoom = 10;
    camera.updateProjectionMatrix();
  };
  cameraGroup.add(camera);
  cameraGroup.quaternion.setFromEuler(
    new THREE.Euler(-Math.PI / 4, Math.PI / 5.14, Math.PI / 6)
  );
  scene.add(cameraGroup);
  sceneCooling.add(cameraGroup);
  //测试使用 正式包需要去除
  window.THREE_test = scene;
  window.sceneCooling = sceneCooling;
  window.scenecamera = camera;
  // 轨道控制器
  controls = new EditorControls(camera, container);
  const clock = new THREE.Clock();
  // 渲染器
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    // logarithmicDepthBuffer: true
  });
  // renderer.setAnimationLoop(animate);
  // renderer.shadowMap.enabled = true;
  renderer.setClearColor(0xeeeeee);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // 光源
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
  scene.add(ambientLight);
  sceneCooling.add(ambientLight.clone());

  container?.appendChild(renderer.domElement);
  renderer.setSize(container.clientWidth, container.clientHeight);
  // 辅助线
  // const helper0 = new THREE.AxesHelper(2);
  // scene.add(helper0);

  helper = new ViewHelper(camera, renderer.domElement);
  helper.center = controls.center;
  viewHelperDiv.addEventListener("pointerup", (event) => {
    event.stopPropagation();
    helper.handleClick(event);
  });

  viewHelperDiv.addEventListener("pointerdown", function (event) {
    event.stopPropagation();
  });
  // 动画控制
  const tick = () => {
    const delta = clock.getDelta();
    if (helper.animating === true) {
      helper.update(delta);
    }
    renderer.autoClear = false;
    renderer.render(scene0, camera);

    helper.render(renderer);
    renderer.autoClear = true;
    // camera.updateProjectionMatrix();
    camera.getWorldPosition(pointLight.position);
    animationId = requestAnimationFrame(tick);
  };
  tick();
  const showScene = ref(false);
  watch([toolName, coolingsCutCheck], () => {
    // 获取画布对象
    const container = document.getElementById("webgl");
    if (
      toolName.value == "CoolingsCut" &&
      coolingsCutCheck.value &&
      !showScene.value
    ) {
      // 切换到冷却空切割模块视口
      showScene.value = true;
      scene0 = sceneCooling;
      pointLight = sceneCoolingPoint;
      camera.position.set(0, 0, 200);

      cameraGroup.quaternion.setFromEuler(new THREE.Euler(0, 0, 0));
      camera.zoom = 20;
      camera.resetZoom = () => {
        camera.zoom = 20;
      };
      camera.lookAt(0, 0, 0);
      camera.updateProjectionMatrix();

      if (bableClickFalg.value) {
        container.addEventListener("click", coolingCutClick, false);
      }
      container.onmouseup = (event) => {
        if (!event) event = window.event;
        if (event.button == 2) {
          coolingCutRightClick(event);
        }
      };
    } else if (showScene.value) {
      //切换回常规3d视口
      showScene.value = false;
      camera.position.set(0, 0, 200);
      camera.zoom = 10;

      cameraGroup.quaternion.setFromEuler(
        new THREE.Euler(-Math.PI / 4, Math.PI / 5.14, Math.PI / 6)
      );
      camera.resetZoom = () => {
        camera.zoom = 10;
      };
      scene0 = scene;
      pointLight = pointLightGroup;
      camera.lookAt(0, 0, 0);
      camera.updateProjectionMatrix();

      if (bableClickFalg.value) {
        container.removeEventListener("click", coolingCutClick);
      }
    }
  });
  const resetWebgl = () => {
    camera.left = container.clientWidth / -2;
    camera.right = container.clientWidth / 2;
    camera.top = container.clientHeight / 2;
    camera.bottom = container.clientHeight / -2;
    camera.updateProjectionMatrix();

    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.autoClear = false;
    renderer.render(scene0, camera);

    helper.render(renderer);
    renderer.autoClear = true;
  };
  const resizeObserver = new ResizeObserver(() => {
    resetWebgl();
  });
  resizeObserver.observe(webgl.value);
}

//线条点击材质
const lineClickMaterial = new THREE.LineBasicMaterial({
  color: 0xff0000,
  transparent: true,
});
const lineMaterial = new THREE.LineBasicMaterial({
  color: 0xe664c8,
  transparent: true,
});
const lineBlackMaterial = new THREE.LineBasicMaterial({
  color: 0x000000,
  transparent: true,
});
const meshlineMaterial = new THREE.LineBasicMaterial({
  color: 0x00ff00,
  transparent: true,
});
const meshShellsMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0x808080),
  side: THREE.DoubleSide,
  precision: "lowp",
  flatShading: true,
});
const blocksMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0x1fa04f),
  precision: "lowp",
  side: THREE.DoubleSide,
  flatShading: true,
});
const GEO_LINE_KEY = "0-0-0";
const BLOCK_EDGE_KEY = "1-0-0";
const BLOCK_FACE_KEY = "1-0-1";
const BLOCK_BLOCK_KEY = "1-0-2";
const MESH_LINE_KEY = "2-0-0";
const MESH_SHELLS_KEY = "2-0-1";
// const MESH_VOLUMES_KEY = "2-0-2";

// 监听模型树结果变更
const selectedTreeKeys = computed(() => store.state.model.selectedTreeKeys);
// 监听数据状态，绘制图形
watch(
  () => [...selectedTreeKeys.value, toolName, coolingsCutCheck],
  () => {
    if (dataStatus.value <= 0 || !scene.getObjectByName("geo_line_group")) {
      return;
    }
    const bableGroup = sceneCooling.getObjectByName("bable_group");
    if (
      bableGroup &&
      toolName.value == "CoolingsCut" &&
      coolingsCutCheck.value
    ) {
      const bableVisable = selectedTreeKeys.value.includes("0-0");
      const bableBlockGroup = sceneCooling.getObjectByName("bable_block_group");
      bableBlockGroup.visible =
        bableVisable || selectedTreeKeys.value.includes("0-0-0");

      const bableBlockMergeGroup = sceneCooling.getObjectByName("mergeGroup");
      bableBlockMergeGroup.visible = bableBlockGroup.visible;
      const bableMeshGroup = sceneCooling.getObjectByName("bable_mesh_group");
      bableMeshGroup.visible =
        bableVisable || selectedTreeKeys.value.includes("0-0-1");

      const bableTipVisable = selectedTreeKeys.value.includes("0-1");
      const bableTipBlockGroup = sceneCooling.getObjectByName(
        "bable_tip_block_group"
      );
      const bableTipMergeGroup = sceneCooling.getObjectByName("tipMergeGroup");
      bableTipBlockGroup.visible =
        bableTipVisable || selectedTreeKeys.value.includes("0-1-0");
      bableTipMergeGroup.visible = bableTipBlockGroup.visible;
      const bableTipMeshGroup = sceneCooling.getObjectByName(
        "bable_tip_mesh_group"
      );
      bableTipMeshGroup.visible =
        bableTipVisable || selectedTreeKeys.value.includes("0-1-1");
      return;
    }
    const geoLineMesh = scene.getObjectByName("geo_line_group");
    geoLineMesh.visible =
      !selectedTreeKeys.value.includes(BLOCK_EDGE_KEY) &&
      selectedTreeKeys.value.includes(GEO_LINE_KEY);

    const faceMesh = scene.getObjectByName("blocking_faces_group");
    faceMesh.visible = selectedTreeKeys.value.includes(BLOCK_FACE_KEY);

    const blockMesh = scene.getObjectByName("blocking_blocks_group");
    blockMesh.visible = selectedTreeKeys.value.includes(BLOCK_BLOCK_KEY);

    const meshLineMesh = scene.getObjectByName("blocking_edges_group");
    meshLineMesh.visible = selectedTreeKeys.value.includes(BLOCK_EDGE_KEY);

    if (meshStatus.value && scene.getObjectByName("mesh_line_group")) {
      geoLineMesh.visible =
        geoLineMesh.visible && !selectedTreeKeys.value.includes(MESH_LINE_KEY);

      const meshLinesMesh = scene.getObjectByName("mesh_line_group");
      meshLinesMesh.visible = selectedTreeKeys.value.includes(MESH_LINE_KEY);

      const meshShellsMesh = scene.getObjectByName("mesh_shells_group");
      meshShellsMesh.visible = selectedTreeKeys.value.includes(MESH_SHELLS_KEY);

      // const meshVolumesMesh = scene.getObjectByName("mesh_volumes_group");
      // meshVolumesMesh.visible = selectedTreeKeys.value.includes(MESH_VOLUMES_KEY);
    }
  },
  { deep: true }
);

const selectLineGroup = computed(() => store.state.model.selectLineGroup);
watch(selectLineGroup, (val, oldVal) => {
  const meshLineMesh = scene.getObjectByName("blocking_edges_group");
  oldVal.forEach((n) => {
    meshLineMesh.children[n].material = lineMaterial;
  });
  val.forEach((n) => {
    meshLineMesh.children[n].material = lineClickMaterial;
  });
});
const isSelectStatus = computed(() => store.state.model.isSelectStatus);

// 创建射线对象
const raycaster = new THREE.Raycaster();
raycaster.params.Line.threshold = 0.5; //线条可拾取区域

let initialThreshold = 0.5;
const updateThreshold = () => {
  // 计算缩放系数
  const scale = camera.zoom;

  // 更新阈值
  raycaster.params.Line.threshold = Math.min(
    0.5,
    (initialThreshold / scale) * 10
  );
};

const onDocumentClick = (event) => {
  let intersects = [];
  const mouse = new THREE.Vector3(
    (event.offsetX / renderer.domElement.clientWidth) * 2 - 1,
    -(event.offsetY / renderer.domElement.clientHeight) * 2 + 1,
    0.5
  );
  raycaster.setFromCamera(mouse, camera);
  // 射线捕获到的mesh对象
  intersects = raycaster
    .intersectObjects(
      scene.getObjectByName("block_group").children.filter((n) => {
        return n.visible;
      }),
      true
    )
    .filter((n) => {
      return n.object.visible && n.object.parent.visible;
    });
  if (intersects.length > 0 && intersects[0].object.type == "Line") {
    let blockingEdge = undefined;
    for (let i = 0; i < intersects.length; i++) {
      if (intersects[i].object.type != "Line") break;

      if (
        intersects[i].object.name == "blocking_edge" &&
        intersects[i].object.visible
      ) {
        blockingEdge = intersects[i].object;

        break;
      }
    }
    if (blockingEdge) {
      store.commit("SET_LINE_INDEX", blockingEdge.arrIndex);
    } else {
      store.commit("SET_LINE_INDEX", undefined);
    }
  } else {
    window.addLog.info("Line data not selected");
  }
};
const mergeBlockDisable = ref(true);
const mergeBlockTipDisable = ref(true);
const loadFileToMergeList=computed(() => store.state.control.loadFileToMergeList);
const rightClickId = ref(-1);
watch(loadFileToMergeList,(newVal)=>{
  if(!newVal) return;
  const blockGroup = sceneCooling.getObjectByName(
        bable_name.bable_block_group[newVal.pos]
      );
      const bladeGroup = sceneCooling.getObjectByName(
        bable_name.bable_group[newVal.pos]
      );
      const mergeGroup = bladeGroup.getObjectByName(
        newVal.pos?"tipMergeGroup":"mergeGroup"
      );
      mergeGroup.clear();
  newVal.data.forEach((n) => {
  let blockList = [];
    n.forEach((m) => {
      blockList.push({
          row: m.x,
          col: m.y,
          geo: blockGroup.children[m.x].children[m.y].geometry,
        });
    })
    const bridge = connectAdjacentCubes(
        blockList,
        bableBlocksMergeMaterial,
        blockGroup.children.length,
        blockGroup.children[0].children.length
      );
      mergeGroup.add(bridge);
      
      });
})
const dropdownList = [
  {
    name: "clearSelection",
    disabled: () => {
      const bableTipVisable =
        selectedTreeKeys.value.includes("0-1") ||
        selectedTreeKeys.value.includes("0-1-0");
      let mergeData = bableTipVisable
        ? coolingsCutMergeBladeTip.value
        : coolingsCutMergeBlade.value;
      return mergeData.length == 0;
    },
    click: () => {
      const bableTipVisable =
        selectedTreeKeys.value.includes("0-1") ||
        selectedTreeKeys.value.includes("0-1-0");
      store.commit(
        bableTipVisable
          ? "RESET_COOLING_CUT_MERGE_BLADE_TIP"
          : "RESET_COOLING_CUT_MERGE_BLADE"
      );
    },
  },
  {
    name: "mergeBlock",
    disabled: () => {
      const bableTipVisable =
        selectedTreeKeys.value.includes("0-1") ||
        selectedTreeKeys.value.includes("0-1-0");
      return bableTipVisable
        ? mergeBlockTipDisable.value
        : mergeBlockDisable.value;
    },
    click: () => {
      //合并block
      const bableTipVisable =
        selectedTreeKeys.value.includes("0-1") ||
        selectedTreeKeys.value.includes("0-1-0");
      let mergeData = bableTipVisable
        ? coolingsCutMergeBladeTip.value
        : coolingsCutMergeBlade.value;
      const blockGroup = sceneCooling.getObjectByName(
        bable_name.bable_block_group[bableTipVisable ? 1 : 0]
      );
      const bladeGroup = sceneCooling.getObjectByName(
        bable_name.bable_group[bableTipVisable ? 1 : 0]
      );
      const mergeGroup = bladeGroup.getObjectByName(
        bableTipVisable ? "tipMergeGroup" : "mergeGroup"
      );
      let blockList = [];
      mergeData.forEach((n) => {
        blockList.push({
          row: n.x,
          col: n.y,
          volumnId: n.volumnId,
          geo: blockGroup.children[n.x].children[n.y].geometry,
        });
      });

      const bridge = connectAdjacentCubes(
        blockList,
        bableBlocksMergeMaterial,
        blockGroup.children.length,
        blockGroup.children[0].children.length
      );
      mergeGroup.add(bridge);
      store.commit(
        bableTipVisable
          ? "RESET_COOLING_CUT_MERGE_BLADE_TIP"
          : "RESET_COOLING_CUT_MERGE_BLADE"
      );
      store.commit(
        bableTipVisable
          ? "SET_COOLING_CUT_MERGE_BLADE_TIP_LIST"
          : "SET_COOLING_CUT_MERGE_BLADE_LIST",
        {
          index: -1,
          data: blockList.map((n) => {
            return { x: n.row, y: n.col, volumnId: n.volumnId };
          }),
        }
      );
    },
  },
  {
    name: "undoMerge",
    click: () => {
      const bableTipVisable =
        selectedTreeKeys.value.includes("0-1") ||
        selectedTreeKeys.value.includes("0-1-0");
      const mergeGroup = sceneCooling.getObjectByName(
        bable_name.mergeGroup[bableTipVisable ? 1 : 0]
      );

      disposeScene(mergeGroup.children[rightClickId.value]);
      mergeGroup.remove(mergeGroup.children[rightClickId.value]);
      store.commit(
        bableTipVisable
          ? "SET_COOLING_CUT_MERGE_BLADE_TIP_LIST"
          : "SET_COOLING_CUT_MERGE_BLADE_LIST",
        { index: rightClickId.value }
      );
    },
  }
];
const menuClick = (e) => {
  dropdownList[e.key].click();
};
const bladeModalProps = computed(() => store.state.control.bladeModalProps);

const bableClickFalg = ref(false);
watch(
  bladeModalProps,
  (newVal) => {
    const container = document.getElementById("webgl");
    if (!container) return;
    if (
      (newVal.bladeVisible || !newVal.generateBladeDisabled) &&
      bableClickFalg.value
    ) {
      container.removeEventListener("click", coolingCutClick, false);
      bableClickFalg.value = false;
    } else if (
      !newVal.bladeVisible &&
      !bableClickFalg.value &&
      container &&
      newVal.generateBladeDisabled
    ) {
      container.addEventListener("click", coolingCutClick, false);
      bableClickFalg.value = true;
    }
  },
  { deep: true, immediate: true }
);
const coolingCutRightClick = (event) => {
  let intersects = [];
  const mouse = new THREE.Vector2(
    (event.offsetX / renderer.domElement.clientWidth) * 2 - 1,
    -(event.offsetY / renderer.domElement.clientHeight) * 2 + 1
  );
  raycaster.setFromCamera(mouse, camera);
  const bableTipVisable =
    selectedTreeKeys.value.includes("0-1") ||
    selectedTreeKeys.value.includes("0-1-0");
  const meshList = sceneCooling
    .getObjectByName(
      bableTipVisable ? "bable_tip_block_group" : "bable_block_group"
    )
    .children.map((n) => {
      return n.children;
    })
    .flat();
  // 射线捕获到的mesh对象
  intersects = raycaster.intersectObjects(meshList, false);
  if (intersects.length > 0) {
    let mergeDataList = bableTipVisable
      ? coolingsCutMergeBladeTipList.value
      : coolingsCutMergeBladeList.value;
    let falg = mergeDataList.findIndex((n) => {
      return (
        n.findIndex(
          (m) =>
            m.x == intersects[0].object.row && m.y == intersects[0].object.col
        ) != -1
      );
    });

    rightClickId.value = falg;
    // if(falg==-1) return;
  }
};
const coolingCutClick = (event) => {
  let intersects = [];
  const mouse = new THREE.Vector2(
    (event.offsetX / renderer.domElement.clientWidth) * 2 - 1,
    -(event.offsetY / renderer.domElement.clientHeight) * 2 + 1
  );
  raycaster.setFromCamera(mouse, camera);
  const bableTipVisable =
    selectedTreeKeys.value.includes("0-1") ||
    selectedTreeKeys.value.includes("0-1-0");
  const meshList = sceneCooling
    .getObjectByName(
      bableTipVisable ? "bable_tip_block_group" : "bable_block_group"
    )
    .children.map((n) => {
      return n.children;
    })
    .flat();
  // 射线捕获到的mesh对象
  intersects = raycaster.intersectObjects(meshList, false);
  if (intersects.length > 0) {
    let mergeDataList = bableTipVisable
      ? coolingsCutMergeBladeTipList.value
      : coolingsCutMergeBladeList.value;
    let falg =
      mergeDataList.filter((n) => {
        return (
          n.findIndex(
            (m) =>
              m.x == intersects[0].object.row && m.y == intersects[0].object.col
          ) != -1
        );
      }).length > 0;
    if (falg) return;
    let col = intersects[0].object.col;
    let row = intersects[0].object.row;
    // let volumnId = intersects[0].object.volumnId;
    store.commit(
      bableTipVisable
        ? "SET_COOLING_CUT_MERGE_BLADE_TIP"
        : "SET_COOLING_CUT_MERGE_BLADE",
      {
        x: row,
        y: col
        // volumnId: volumnId,
      }
    );
  }
};

// 选择模型操作
watch(isSelectStatus, () => {
  // 获取画布对象
  const container = document.getElementById("webgl");
  if (!isSelectStatus.value) {
    container.removeEventListener("click", onDocumentClick);
    container.onmousemove = undefined;
    controls.removeEventListener("change", updateThreshold);
    return;
  }

  // 监听相机缩放事件
  controls.addEventListener("change", updateThreshold);

  // 点击事件

  if (isSelectStatus.value) {
    container.addEventListener("click", onDocumentClick, false);
  }
});

const currentModelName = computed(() => store.state.control.currentModelName);
const volumns = computed(() => store.state.model.volumns);
const generrateModel = () => {
  const oldGeoGroup = scene.getObjectByName("geo_group");
  const oldBlockGroup = scene.getObjectByName("block_group");
  if (oldGeoGroup) {
    disposeScene(oldGeoGroup);
    scene.remove(oldGeoGroup);
    window.addLog.info("Geometry remove ok");
  }
  if (oldBlockGroup) {
    disposeScene(oldBlockGroup);
    scene.remove(oldBlockGroup);
    window.addLog.info("Blocking remove ok");
  }
  const geoGroup = new THREE.Group();
  geoGroup.name = "geo_group";
  const blockGroup = new THREE.Group();
  blockGroup.name = "block_group";
  scene.add(geoGroup);
  scene.add(blockGroup);

  const lines = computed(() => store.state.model.initLines);
  const lineGroup = new THREE.Group();
  lineGroup.name = "geo_line_group";
  // // 循环生成线模型
  const geolineMaterial = new THREE.LineBasicMaterial({ color: 0x4f5a5c });
  lines.value.forEach((line, index) => {
    const lineModel = addLines(line, geolineMaterial);
    lineModel.arrIndex = index;
    lineGroup.add(lineModel);
  });
  lineGroup.visible =
    !selectedTreeKeys.value.includes(BLOCK_EDGE_KEY) &&
    selectedTreeKeys.value.includes(GEO_LINE_KEY);

  // 生成face面数据
  const faceMaterial = new THREE.MeshStandardMaterial({
    color: new THREE.Color(0x505078),
    side: THREE.DoubleSide,
    precision: "lowp",
    flatShading: true,
  });
  const surfaces = computed(() => store.state.model.surfaces);
  const surfacesGroup = new THREE.Group();
  surfaces.value.forEach((face) => {
    const faceModel = addFace3(face, faceMaterial, lineBlackMaterial);
    surfacesGroup.add(faceModel);
  });
  surfacesGroup.name = "blocking_faces_group";
  surfacesGroup.visible = selectedTreeKeys.value.includes(BLOCK_FACE_KEY);

  // 循环生成面模型
  const volumnsGroup = new THREE.Group();

  volumns.value.forEach((volumn) => {
    const volumnModel = addFace3(volumn, blocksMaterial, lineBlackMaterial);
    volumnsGroup.add(volumnModel);
  });
  volumnsGroup.name = "blocking_blocks_group";
  volumnsGroup.visible = selectedTreeKeys.value.includes(BLOCK_BLOCK_KEY);

  // 循环生成线模型(blocking_edges)
  const blockEdges = computed(() => store.state.model.lines);
  const blockEdgeGroup = new THREE.Group();
  blockEdges.value.forEach((line, index) => {
    const blockEdgeModel = addLines(line, lineMaterial);
    blockEdgeModel.name = "blocking_edge";

    blockEdgeModel.arrIndex = index;
    blockEdgeGroup.add(blockEdgeModel);
  });
  blockEdgeGroup.name = "blocking_edges_group";
  blockEdgeGroup.visible = selectedTreeKeys.value.includes(BLOCK_EDGE_KEY);

  // 模型添加到场景
  geoGroup.add(lineGroup);
  blockGroup.add(surfacesGroup);
  blockGroup.add(volumnsGroup);
  blockGroup.add(blockEdgeGroup);
  setGroupToCenter(geoGroup);
  setGroupToCenter(blockGroup);
  camera.resetZoom();
  window.addLog.info("Geometry loaded successfully");
  window.addLog.info("Blocking Edges loaded successfully");
  window.addLog.info("Blocking Faces loaded successfully");
  window.addLog.info("Blocking Blocks loaded successfully");
  window.addLog.info("Blocking loaded successfully");
};

function meshModelCreate() {
  const oldMeshGroup = scene.getObjectByName("mesh_group");
  if (oldMeshGroup) {
    disposeScene(oldMeshGroup);
    scene.remove(oldMeshGroup);
  }
  const meshGroup = new THREE.Group();
  meshGroup.name = "mesh_group";
  const meshScanGroup = new THREE.Group();
  meshScanGroup.name = "mesh_scan_group";
  meshGroup.add(meshScanGroup);
  scene.add(meshGroup);

  const meshLine = computed(() => store.state.model.meshLine);
  const meshLineGroup = new THREE.Group();

  meshLine.value.forEach((line, index) => {
    const meshLineModel = addLines(line, meshlineMaterial);
    meshLineModel.name = "mesh_line";
    meshLineModel.arrIndex = index;
    meshLineGroup.add(meshLineModel);
  });
  meshLineGroup.name = "mesh_line_group";
  meshLineGroup.visible = selectedTreeKeys.value.includes(MESH_LINE_KEY);

  // 生成MESH_SHELLS
  const meshShells = computed(() => store.state.model.meshShells);

  const meshShellsGroup = new THREE.Group();
  // const meshShellsModel =mergeMesh(meshShells.value,meshShellsMaterial,lineBlackMaterial)
  // meshShellsGroup.add(meshShellsModel);
  meshShells.value.forEach((face) => {
    const meshShellsModel = addFace3(
      face,
      meshShellsMaterial,
      lineBlackMaterial
    );
    meshShellsGroup.add(meshShellsModel);
  });
  meshShellsGroup.name = "mesh_shells_group";
  meshShellsGroup.visible = selectedTreeKeys.value.includes(MESH_SHELLS_KEY);

  meshGroup.add(meshLineGroup);
  meshGroup.add(meshShellsGroup);

  if (currentModelName.value.indexOf("Cooling_Blade") != -1) {
    let type = currentModelName.value.indexOf("BladeTip") != -1 ? 1 : 0;
    let name = bable_name.bable_mesh_group[type];
    let x = currentModelName.value.split("_")[2];
    let y = currentModelName.value.split("_")[3];
    let obj = sceneCooling.getObjectByName(name).children[x].children[y];
    obj.geometry.dispose();
    let data = meshShells.value.flat();
    const blockModel = addFace3(data, meshShellsMaterial, lineBlackMaterial);
    obj.geometry = blockModel.geometry;
  }
  setGroupToCenter(meshGroup);
  window.addLog.info("Mesh Lines loaded successfully");
  window.addLog.info("Mesh Shells loaded successfully");
  window.addLog.info("Mesh loaded successfully");
}

const bableBlocksMaterial1 = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0xffff00),
  precision: "lowp",
  side: THREE.DoubleSide,
  flatShading: true,
});
const bableBlocksMaterial2 = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0xff00ff),
  precision: "lowp",
  side: THREE.DoubleSide,
  flatShading: true,
});
const bableBlocksMergeMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0xff2442),
  precision: "lowp",
  side: THREE.DoubleSide,
  flatShading: true,
});
const bableBlockMatrix = computed(() => store.state.model.bableBlockMatrix);
const coolingsCutMergeBlade = computed(
  () => store.state.control.coolingsCutMergeBlade
);
const coolingsCutMergeBladeTip = computed(
  () => store.state.control.coolingsCutMergeBladeTip
);
const coolingsCutMergeBladeList = computed(
  () => store.state.control.coolingsCutMergeBladeList
);
const coolingsCutMergeBladeTipList = computed(
  () => store.state.control.coolingsCutMergeBladeTipList
);

// const bladeType = computed(() => store.state.menu.bladeType);
watch([coolingsCutMergeBlade, coolingsCutMergeBladeTip], () => {
  const bableTipVisable =
    selectedTreeKeys.value.includes("0-1") ||
    selectedTreeKeys.value.includes("0-1-0");
  const group = sceneCooling.getObjectByName(
    bableTipVisable ? "bable_tip_block_group" : "bable_block_group"
  );
  let bable=bableTipVisable?BableTipBlock.value : BableBlock.value;
  let mergeData = bableTipVisable
    ? coolingsCutMergeBladeTip.value
    : coolingsCutMergeBlade.value;
  group.children.forEach((item, i) => {
    item.children.forEach((it, j) => {
      if (it?.isMesh) {
        let obj = group.children[i].children[j];
        if (obj?.isMesh && mergeData.find((it) => it.x == i && it.y == j)) {
          obj.material = bableBlocksMergeMaterial;
          obj.status = -1;
        } else if (obj?.isMesh && obj.status == -1) {
          obj.material = blocksMaterial;
          obj.status = 0;
        }
      }
    });
  });
  if (mergeData.length > 1&&bladeModalProps.value.generateBladeDisabled&&bladeModalProps.value.bladeDisabled == 1) {
    mergeJudge({ nums: mergeData.map((n) => bable[n.x][n.y]?.[0]) }, bableTipVisable);
  }
});
const mergeJudge = async (data, falg) => {
  const [err, res] = await to(model.checkIfMerge(data));
  if (err) {
    return;
  }
  window.addLog.info(
    res.data
      ? "Current selections can be merged"
      : "Current selection cannot be merged"
  );
  if (falg) {
    mergeBlockTipDisable.value = !res.data;
  } else {
    mergeBlockDisable.value = !res.data;
  }
};
watch(
  bableBlockMatrix,
  () => {
    const newVal = bableBlockMatrix.value;
    const bableTipVisable =
      selectedTreeKeys.value.includes("0-1") ||
      selectedTreeKeys.value.includes("0-1-0");
    const group = sceneCooling.getObjectByName(
      bableTipVisable ? "bable_tip_block_group" : "bable_block_group"
    );
    if (!group?.children?.length || newVal.length <= 1) {
      return;
    }
    console.log(newVal,bableTipVisable);
    newVal.forEach((item, i) => {
      item.forEach((it, j) => {
        if (it != -1) {
          let obj = group.children[i].children[j];
          if (obj?.isMesh && obj.status != it) {
            obj.material = [
              blocksMaterial,
              bableBlocksMaterial1,
              bableBlocksMaterial2,
            ][it];
            obj.status = it;
          }
        }
      });
    });
  },
  { deep: true }
);

const BableBlock = computed(() => store.state.model.BableBlock);
const BableMesh = computed(() => store.state.model.BableMesh);
const BableTipBlock = computed(() => store.state.model.BableTipBlock);
const BableTipMesh = computed(() => store.state.model.BableTipMesh);
const bable_name = {
  bable_group: ["bable_group", "bable_tip_group"],
  bable_block: ["bable_block", "bable_tip_block"],
  bable_block_group: ["bable_block_group", "bable_tip_block_group"],
  bable_mesh: ["bable_mesh", "bable_tip_mesh"],
  bable_mesh_group: ["bable_mesh_group", "bable_tip_mesh_group"],
  mergeGroup: ["mergeGroup", "tipMergeGroup"],
};
const loadBlade = (type) => {
  const oldMeshGroup = sceneCooling.getObjectByName(
    bable_name.bable_group[type]
  );
  if (oldMeshGroup) {
    disposeScene(oldMeshGroup);
    sceneCooling.remove(oldMeshGroup);
    store.commit("RESET_COOLING_CUT_MERGE_BLADE_LIST");
    store.commit("RESET_COOLING_CUT_MERGE_BLADE_TIP_LIST");
    store.commit("RESET_COOLING_CUT_MERGE_BLADE");
    store.commit("RESET_COOLING_CUT_MERGE_BLADE_TIP");
  }
  const bableGroup = new THREE.Group();
  bableGroup.name = bable_name.bable_group[type];
  sceneCooling.add(bableGroup);

  const bableMergeGroup = new THREE.Group();
  bableMergeGroup.name = bable_name.mergeGroup[type];
  bableGroup.add(bableMergeGroup);
  // 生成MESH_SHELLS
  const bableBlockGroup = new THREE.Group();
  const Block = type == 0 ? BableBlock : BableTipBlock;

  Block.value.forEach((n, row) => {
    const bableBlockChildren = new THREE.Group();

    n.forEach((data, col) => {
      if (data?.length==0) {
        bableBlockChildren.add(new THREE.Group());
      } else {
        let d=[];
        if(coolingsCutStatus.value == 1){

          data.forEach((id)=>{
            d.push(...volumns.value[id])
          })

        }
        
        const blockModel= addFace3(
            coolingsCutStatus.value == 1 ? d: data,
            blocksMaterial,
            lineBlackMaterial
          );
        blockModel.name = bable_name.bable_block[type] + row + "_" + col;
        if (coolingsCutStatus.value == 1) {
          blockModel.volumnId = data[0];
        }
        blockModel.row = row;
        blockModel.col = col;
        blockModel.status = 0;
        blockModel.arrIndex = col;
        bableBlockChildren.add(blockModel);
      }
    });

    bableBlockGroup.add(bableBlockChildren);
  });

  bableBlockGroup.name = bable_name.bable_block_group[type];
  bableBlockGroup.visible = selectedTreeKeys.value.includes(
    type ? "0-1-0" : "0-0-0"
  );

  const bableMeshGroup = new THREE.Group();
  const Mesh = type == 0 ? BableMesh : BableTipMesh;
  if (coolingsCutStatus.value != 1) {
    Mesh.value.forEach((n, row) => {
      const bableMeshChildren = new THREE.Group();
      n.forEach((data, col) => {
        if (data == -1) {
          bableMeshChildren.add(new THREE.Group());
        } else {
          const blockModel = addFace3(
            coolingsCutStatus.value == 1 ? volumns.value[data] : data,
            meshShellsMaterial,
            lineBlackMaterial
          );
          blockModel.name = bable_name.bable_mesh[type] + row + "_" + col;
          blockModel.arrIndex = col;
          bableMeshChildren.add(blockModel);
        }
      });
      bableMeshGroup.add(bableMeshChildren);
    });
  }
  bableMeshGroup.name = bable_name.bable_mesh_group[type];
  bableMeshGroup.visible = selectedTreeKeys.value.includes(
    type ? "0-1-1" : "0-0-1"
  );

  setGroupToCenter(bableMeshGroup);
  bableGroup.add(bableMeshGroup);

  setGroupToCenter(bableBlockGroup);
  bableGroup.add(bableBlockGroup);

  bableMergeGroup.position.x -= bableBlockGroup.resetX;
  bableMergeGroup.position.y -= bableBlockGroup.resetY;
  bableMergeGroup.position.z -= bableBlockGroup.resetZ;
  window.addLog.info("Bable Block loaded successfully");
  window.addLog.info("Bable Mesh loaded successfully");
  window.addLog.info("Bable loaded successfully");
};

const mnVal = computed(() => store.state.control.mnVal);
const mnValTip = computed(() => store.state.control.mnValTip);
const resetVisableBable = (type) => {
  const groupName = type ? "bable_group" : "bable_tip_group";
  let mnArr = type ? mnVal.value : mnValTip.value;
  const parentGroup = sceneCooling.getObjectByName(groupName);
  if (mnArr.m[0] == -1) {
    mnArr.m = [0, parentGroup.children[0].length];
  }
  if (mnArr.n[0] == -1) {
    mnArr.n = [0, parentGroup.children[0].children[0].length];
  }
  parentGroup.children.forEach((group) => {
    group.children.forEach((row, index) => {
      if (
        index < mnArr.m[0] ||
        (index >= mnArr.m[1] && mnArr.m[0] != -1) ||
        mnArr.m[0] == mnArr.m[1]
      ) {
        row.visible = false;
      } else {
        row.visible = true;
        row.children.forEach((col, ind) => {
          if (
            ind < mnArr.n[0] ||
            ind >= mnArr.n[1] ||
            mnArr.n[0] == mnArr.n[1]
          ) {
            col.visible = false;
          } else {
            col.visible = true;
          }
        });
      }
    });
  });
};

const IJKLines = computed(() => store.state.control.IJKLines);
const IJKFaces = computed(() => store.state.control.IJKFaces);
const IJKVolumns = computed(() => store.state.control.IJKVolumns);
const IJKStatus = computed(() => store.state.control.IJKStatus);
const RESET_IJK_STATUS = () => {
  const blockFacesGroup = scene.getObjectByName(
    "blocking_faces_group"
  ).children;
  const blockBlocksGroup = scene.getObjectByName(
    "blocking_blocks_group"
  ).children;
  const blockEdgesGroup = scene.getObjectByName(
    "blocking_edges_group"
  ).children;
  // const meshShellsGroup = scene.getObjectByName("mesh_shells_group")?.children;
  blockEdgesGroup.forEach((item, index) => {
    item.visible = binarySearch(IJKLines.value, index) != -1;
  });
  blockBlocksGroup.forEach((item, index) => {
    item.visible = binarySearch(IJKVolumns.value, index) != -1;
  });
  blockFacesGroup.forEach((item, index) => {
    item.visible = binarySearch(IJKFaces.value, index) != -1;

    // if (meshShellsGroup?.length)
    //   meshShellsGroup[index].visible = item.visible;
  });
};

const chartModelData = computed(() => store.state.control.chartModelData);
const chartModelId = computed(() => store.state.control.chartModelId);

const chartType = computed(() => store.state.menu.chartType);

const loadChartModel = (e) => {
  const oldMeshGroup = scene.getObjectByName("chart_model_group");
  let meshGroup;
  const chartModalMaterial = new THREE.MeshStandardMaterial({
    color: new THREE.Color(e.color),
    side: THREE.DoubleSide,
    precision: "lowp",
    flatShading: true,
  });
  if (oldMeshGroup) {
    meshGroup = oldMeshGroup;
    // disposeScene(oldMeshGroup);
    // scene.remove(oldMeshGroup);
  } else {
    meshGroup = new THREE.Group();
    meshGroup.name = "chart_model_group";
    meshGroup.position.copy(scene.getObjectByName("geo_group").position);
    scene.add(meshGroup);
  }
  if (e.data.length == 0) return;
  const chartModel = addModel(e.data, chartModalMaterial, lineBlackMaterial);
  chartModel.name = "chartModel" + e.index;
  chartModel.visible = chartModelId.value[e.index].visible;
  meshGroup.add(chartModel);
};
watch(chartType, (newVal) => {
  if (!newVal) {
    const meshGroup = scene.getObjectByName("chart_model_group");
    if (meshGroup) {
      disposeScene(meshGroup);
      scene.remove(meshGroup);
    }
  }
});
const updateChartModel = (list) => {
  const meshGroup = scene.getObjectByName("chart_model_group");
  if (!meshGroup) {
    return;
  }
  meshGroup.children.forEach((n) => {
    let index = n.name.slice(10);
    n.visible = list[index].visible;
  });
};
watch(chartModelData, (newVal) => {
  loadChartModel(newVal);
});
watch(chartModelId, (newVal) => {
  if (newVal.length > 0) updateChartModel(newVal);
});

const ScanShowList = computed(() => store.state.control.ScanShowList);
const ScanMesh0 = computed(() => store.state.control.ScanMesh0);
const ScanMesh1 = computed(() => store.state.control.ScanMesh1);
const ScanMesh2 = computed(() => store.state.control.ScanMesh2);
const ScanMesh3 = computed(() => store.state.control.ScanMesh3);
const scanMeshShellsMaterial = new THREE.MeshStandardMaterial({
  color: new THREE.Color(0x00ff00),
  side: THREE.DoubleSide,
  precision: "lowp",
  flatShading: true,
});

const loadScanMesh = (val, type) => {
  const meshScanGroup = scene.getObjectByName("mesh_scan_group");
  if (!meshScanGroup) return;
  const oldMeshGroup = scene.getObjectByName("scan_mesh_" + type);
  if (oldMeshGroup) {
    disposeScene(oldMeshGroup);
    meshScanGroup.remove(oldMeshGroup);
  }
  const ScanMesh = new THREE.Group();
  ScanMesh.name = "scan_mesh_" + type;
  meshScanGroup.add(ScanMesh);

  val.forEach((face) => {
    const meshShellsModel = addFace3(
      face,
      scanMeshShellsMaterial,
      lineBlackMaterial
    );
    ScanMesh.add(meshShellsModel);
  });
  ScanMesh.visible = ScanShowList.value.indexOf(type) != -1;
};
const scanList = [0, 1, 2, 3];
watch(ScanShowList, (newVal) => {
  scanList.forEach((ind) => {
    const ScanMesh = scene.getObjectByName("scan_mesh_" + ind);
    if (ScanMesh) {
      ScanMesh.visible = newVal.indexOf(ind) != -1;
    }
  });
});
watch(set, (newVal) => {
  if (newVal) {
    let bg = new THREE.Color(newVal.backgroundColor);
    scene.background = bg;
    sceneCooling.background = bg;
  }
});
watch(ScanMesh0, (newVal) => {
  loadScanMesh(newVal, 0);
});
watch(ScanMesh1, (newVal) => {
  loadScanMesh(newVal, 1);
});
watch(ScanMesh2, (newVal) => {
  loadScanMesh(newVal, 2);
});
watch(ScanMesh3, (newVal) => {
  loadScanMesh(newVal, 3);
});
watch(IJKStatus, (newVal) => {
  if (newVal == 0) return;
  RESET_IJK_STATUS(newVal);
});
watch(mnVal, (newVal) => {
  if (newVal.m[0] == -1) return;
  resetVisableBable(true);
});
watch(mnValTip, (newVal) => {
  if (newVal.m[0] == -1) return;
  resetVisableBable(false);
});

watch(coolingsCutStatus, (newVal) => {
  if (newVal) {
    loadBlade(0);
    loadBlade(1);
  }
  // else if (newVal > 1) {
  //   if (bladeType.value == 1) {
  //     loadBlade(1);
  //   } else {
  //     loadBlade(0);
  //   }
  // }
});
// 监听数据状态，绘制图形
watch(dataStatus, (newVal) => {
  if (newVal > 0) {
    setTimeout(() => {
      generrateModel();
    });
  }
});

watch(meshStatus, () => {
  // if (newVal) {
  setTimeout(() => {
    meshModelCreate();
  });
  // }
});

onMounted(() => {
  init();
});

onUnmounted(() => {
  // scene.dispose();
  // sceneCooling.dispose();
  renderer.dispose();
  cancelAnimationFrame(animationId);
});
</script>

<style lang="scss" scoped>
#webgl {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: rgb(238, 238, 238);
  background-color: white;
}

#viewHelper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 128px;
  height: 128px;
}
</style>
