<template>
  <div class="toolbar">
    <ul>
      <!-- Common -->
      <li>
        <a href="#">Common</a>
        <ul class="submenu">
          <li>
            <a-button @click="toolClicked('CreateModel')" type="text">
              <img src="@/assets/images/toolbar/CreateModel.png" title="CreateModel" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('ParameterAdjust')" :disabled="dataStatus<=0" type="text">
              <img src="@/assets/images/toolbar/ParameterAdjust.png" title="ParameterAdjust" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('CoolingsCut')" :disabled="dataStatus<=0" type="text">
              <img src="@/assets/images/toolbar/CoolingsCut.png" title="CoolingsCut" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('RimSealing')" :disabled="dataStatus<=0" type="text">
              <img src="@/assets/images/toolbar/CoolingHole.png" title="RimSealing" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('IjkControl')" :disabled="dataStatus<=0" type="text">
              <img src="@/assets/images/toolbar/IjkControl.png" title="IjkControl" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('ScanPlane')" :disabled="dataStatus<=0 || !meshStatus" type="text">
              <img src="@/assets/images/toolbar/ScanPlane.png" title="ScanPlane" />
            </a-button>
          </li>
          <li>
            <a-button @click="toolClicked('CheckMeshQuality')" :disabled="dataStatus<=0 || !meshStatus" type="text">
              <img src="@/assets/images/toolbar/CheckMeshQuality.png" title="CheckMeshQuality" />
            </a-button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed,watch } from "vue";

const store = useStore();
const dataStatus = computed(() => store.state.model.dataStatus);
const meshStatus = computed(() => store.state.model.meshStatus);
const toolName = computed(() => store.state.model.toolName);
const historyFalg = computed(() => store.state.control.historyFalg);
const historyCurrent = computed(() => store.state.control.historyCurrent);
const historyList = computed(() => store.state.control.historyList);
const toolClicked = (toolName) => {
  store.commit("SET_TOOL_NAME", toolName);
  store.commit('SET_HISTORY', {
    hisType: 'toolNameChange',
    ['toolNameChange']: toolName
  })
};

watch(historyCurrent, async(newVal) => {
  if(!historyFalg.value) return;
  if (historyList.value[newVal].hisType == 'toolNameChange'&& historyList.value[newVal][historyList.value[newVal].hisType]!=toolName.value) {
    store.commit('SET_TOOL_NAME',  historyList.value[newVal][historyList.value[newVal].hisType]);
  
    store.commit('SET_LOADING', false);
  }
})
</script>

<style scoped>
/* [2] 工具栏 */
.toolbar {
  width: 100%;
  height: 55px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
}

.toolbar ul {
  height: 100%;
  list-style: none;
}

.toolbar li {
  height: 100%;
  position: relative;
  float: left;
  width: auto;
}

button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 0;
}

.toolbar li a {
  display: block;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  font-size: 12px;
  line-height: 24px;
  min-width: 45px;
  padding: 0 10px;
  text-decoration: none;
  /* transition: all .3s ease-in-out; */
}

.toolbar .submenu {
  width: 100vw;
  height: 30px;
  position: absolute;
  top: 24px;
  left: 0px;
  border-top: 1px solid var(--border-color);
  /* transition: all 0.5s; */
}

.toolbar .submenu li {
  height: 100%;
  width: 36px;
}

.toolbar .submenu button:hover {
  background-color: rgba(35, 160, 234, 0.1);
  border: 1px solid rgb(35, 160, 234);
}

.toolbar .submenu li img {
  height: 100%;
}
</style>
