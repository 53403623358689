<template>
  <form>
    <div class="line">
      <label class="column1">On</label>
      <label class="column2">BlockIndex</label>
      <label class="column3">GridIndex</label>
    </div>
    <a-checkbox-group v-model:value="checkList" @change="changeShowList" style="width: 100%">
      <div class="line" v-for="(item, index) in rangeMartix" :key="index">
        <a-checkbox :value="index" >#{{ index }}</a-checkbox>

        <a-input-number v-model:value="blockVal[index]" :min="0" :max="item.length-1" @change="{changValue(index);gridVal[index]=0}" />

        <a-input-number v-model:value="gridVal[index]" :min="0" :max="item[blockVal[index]]" @change="changValue(index)" />
      </div>

    </a-checkbox-group>
  </form>
</template>

<script setup>
import { ref } from "vue";
import model from "@/api/modules/model";
import { to } from "@/utils/utils";
import { useStore } from "vuex";


const store = useStore();

const rangeMartix = ref([]);


const checkList = ref([]);
const blockVal = ref([0, 0, 0, 0]);
const gridVal = ref([0, 0, 0, 0]);

const getRange = async () => {
  const [err, res] = await to(model.getScanPlaneRange());
  if (err) {
    window.addLog.error('getScanPlaneRange failed');
    return;
  }
  window.addLog.info('getScanPlaneRange successfully');
  rangeMartix.value = res.data;
}
getRange();
const changeShowList=()=>{
  store.commit('SET_SCAN_SHOW_LIST',checkList.value);
}
const changValue = async (dir) => {
  const [err, res] = await to(model.getGridPatchesByScanPlanes({
    indexBlock: blockVal.value[dir],
    indexGrid: gridVal.value[dir],
    dir
  }));
  if (err) {
    return;
  }
  store.commit('SET_SCAN_MESH'+dir,res.data);
  setTimeout(()=>{
    store.commit('RESET_SCAN_STATUS');
  })
}
</script>

<style scoped>
form {
  margin: 10px;
}

.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.column2,.column3{
  width: 80px;
}
label {
  height: 32px;
  float: left;
  width: 80px;
  line-height: 32px;
  font-size: 14px;
}

.ant-input-number {
  width: 60px;
}
.ant-input-number:nth-of-type(even) {
  margin-left: 20px;
}
</style>
