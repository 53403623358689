// 执行传入的promise并返回一个数组，方便在async函数中使用await时判断异常
import * as THREE from "three";
function to(promise) {
  if (promise instanceof Promise) {
    return promise
      .then((res) => {
        return [null, res];
      })
      .catch((err) => {
        return [err, null];
      });
  }

  return [null, promise];
}

function objDispose(obj) {
  obj.geometry.dispose();
  obj.material.dispose();
}

//Clear memory when you remove three.js objects
function disposeHierarchy(node, callback) {
  for (var i = node.children.length - 1; i >= 0; i--) {
    var child = node.children[i];
    disposeHierarchy(child, callback);
    callback(child);
  }
};
function disposeNode(node) {
  if (node.geometry) node.geometry.dispose();
  if (node.material) node.material.dispose();
  if (node.texture) node.texture.dispose();
};
function disposeScene(scene) {
  while (scene.children.length > 0) {
    disposeHierarchy(scene.children[0], disposeNode);
    scene.remove(scene.children[0]);
  }
};
function setGroupToCenter(group) {
  // Create a box that contains all the added objects
  let box = new THREE.Box3().setFromObject(group);

  // Calculate the center of the box
  let center = new THREE.Vector3();
  box.getCenter(center);

  // Set group's position with respect to the new center
  group.resetX = center.x;
  group.resetY = center.y;
  group.resetZ = center.z;
  group.position.x -= center.x;
  group.position.y -= center.y;
  group.position.z -= center.z;
}

function binarySearch(arr, target) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = left + Math.floor((right - left) / 2);

    if (arr[mid] === target) {
      return mid;
    } else if (arr[mid] < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
}
function debounce(fn, delay = 300) {
  let timer = null
  return function (...args) {
    if (timer != null) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      fn.call(this, ...args)
    }, delay);
  }
}

export function formatArrayNoNewlines(jsonStr) {
  let insideArray = false;
  let formattedJsonStr = '';

  for (let i = 0; i < jsonStr.length; i++) {
    const char = jsonStr[i];

    if (char === '[') {
      insideArray = true;
      formattedJsonStr += char;
    } else if (char === ']') {
      insideArray = false;
      formattedJsonStr += char;
    } else if (char === '\n' && insideArray) {
      // 忽略数组元素内的换行符
      i += 2;
    } else {
      formattedJsonStr += char;
    }
  }

  return formattedJsonStr;
};
export const openDownloadDialog = (url, fileName) => {
  if (typeof url === 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = fileName;
  aLink.click();
};
function isValidCoordinate(x, y, matrixMap) {
  return matrixMap.has(`${x},${y}`);
}

function getAdjacentCoordinates(x, y, matrixWidth = 5, matrixHeight = 6) {
  return [
    [(x - 1 + matrixWidth) % matrixWidth, y],  // 对于左邻居，当x为0时，结果为matrixWidth-1
    [(x + 1) % matrixWidth, y],               // 对于右邻居，当x为matrixWidth-1时，结果为0
    [x, (y - 1 + matrixHeight) % matrixHeight], // 对于上邻居，考虑y为0的情况
    [x, (y + 1) % matrixHeight]                // 对于下邻居，考虑y为matrixHeight-1的情况
  ];
}
export function connectAdjacentCubes(elementList, material,matrixWidth,matrixHeight) {
  const combinedMesh = new THREE.Group();

  // 从elementList创建坐标到几何体的映射
  const matrixMap = new Map();
  for (const element of elementList) {
    matrixMap.set(`${element.row},${element.col}`, element.geo);
  }

  // 跟踪已经连接的立方体对，防止重复连接
  const connectedPairs = new Set();

  for (const element of elementList) {
    const currentGeometry = element.geo;
    const currentKey = `${element.row},${element.col}`;
    const adjacentCoords = getAdjacentCoordinates(element.row, element.col,matrixWidth,matrixHeight);

    for (const [adjX, adjY] of adjacentCoords) {
      const adjKey = `${adjX},${adjY}`;
      if (isValidCoordinate(adjX, adjY, matrixMap) && !connectedPairs.has(`${currentKey}-${adjKey}`) && !connectedPairs.has(`${adjKey}-${currentKey}`)) {
        const adjacentGeometry = matrixMap.get(adjKey);

        // 判断是否可以拼接
        if (currentGeometry && adjacentGeometry) {
          const [faceA, faceB] = findClosestFaces(currentGeometry, adjacentGeometry);
          const bridgeMesh = connectFaces(faceA, faceB, material);

          combinedMesh.add(bridgeMesh);
          connectedPairs.add(`${currentKey}-${adjKey}`);  // 标记为已连接
        }
      }
    }

    // combinedMesh.add(new THREE.Mesh(currentGeometry, material));
  }
  return combinedMesh;
}

// 找到两个几何体之间最接近的面
export function findClosestFaces(geometryA, geometryB) {
  let minDistance = Infinity;
  let closestFacePair = null;

  const verticesA = geometryA.attributes.position.array;
  const verticesB = geometryB.attributes.position.array;

  for (let i = 0; i < verticesA.length; i += 18) { // 每六个顶点，即18个坐标组成一个四边形面
    const faceAVertices = [
      new THREE.Vector3(verticesA[i], verticesA[i + 1], verticesA[i + 2]),
      new THREE.Vector3(verticesA[i + 6], verticesA[i + 7], verticesA[i + 8]),
      new THREE.Vector3(verticesA[i + 3], verticesA[i + 4], verticesA[i + 5]),
      new THREE.Vector3(verticesA[i + 15], verticesA[i + 16], verticesA[i + 17]),
    ];

    for (let j = 0; j < verticesB.length; j += 18) {
      const faceBVertices = [
        new THREE.Vector3(verticesB[j], verticesB[j + 1], verticesB[j + 2]),
        new THREE.Vector3(verticesB[j + 6], verticesB[j + 7], verticesB[j + 8]),
        new THREE.Vector3(verticesB[j + 3], verticesB[j + 4], verticesB[j + 5]),


        new THREE.Vector3(verticesB[j + 15], verticesB[j + 16], verticesB[j + 17])
      ];

      const centerA = faceAVertices.reduce((acc, v) => acc.add(v), new THREE.Vector3()).divideScalar(4);
      const centerB = faceBVertices.reduce((acc, v) => acc.add(v), new THREE.Vector3()).divideScalar(4);

      const distance = centerA.distanceTo(centerB);
      if (distance < minDistance) {
        minDistance = distance;
        closestFacePair = [faceAVertices, faceBVertices];
      }
    }
  }

  return closestFacePair;
}
// 连接这两个面的四个顶点
export function connectFaces(faceA, faceB, material) {
  const vertices = [];
  const indices = [];

  // 首先，我们将所有顶点放入vertices数组中
  for (const vertex of faceA) {
    vertices.push(vertex.x, vertex.y, vertex.z);
  }
  for (const vertex of faceB) {
    vertices.push(vertex.x, vertex.y, vertex.z);
  }

  // 然后，我们创建索引来形成所有可能的三角形
  const lenA = faceA.length;
  const lenB = faceB.length;

  for (let i = 0; i < lenA; i++) {
    for (let j = 0; j < lenB; j++) {
      indices.push(
        i, lenA + j, (i + 1) % lenA,
        i, (lenA + j + 1) % lenB + lenA, lenA + j,

        i, lenA + j, (lenA + j + 1) % lenB + lenA,
        i, (i + 1) % lenA, (lenA + j + 1) % lenB + lenA
      );
    }
  }
  const bridgeGeometry = new THREE.BufferGeometry();
  bridgeGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
  bridgeGeometry.setIndex(indices);

  return new THREE.Mesh(bridgeGeometry, material);
}

function exportArrayAsJSON(array, filename = 'data.json') {
  // 将数组转换为JSON字符串
  const jsonString = JSON.stringify(array, null, 2);

  // 创建一个Blob对象并将JSON字符串写入Blob
  const blob = new Blob([jsonString], { type: 'application/json' });

  // 创建一个下载链接
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // 触发下载
  document.body.appendChild(link);
  link.click();

  // 移除下载链接
  document.body.removeChild(link);
}
export { to, objDispose, disposeScene, setGroupToCenter, binarySearch, debounce,exportArrayAsJSON };
