<template>
  <div class="form">
    
  <a-spin :spinning="loading" tip="Loading...">
    <div class="wrap">
      <form>
        <a-row>
          <a-col :span="11">
            <div class="line">
          <label>Span</label>
          <a-input-number v-model:value="row" size="small" :min="2" :max="10000" />
        </div>

        <div class="line">
          <label>ColumnX</label>
          <a-input-number v-model:value="columnX" size="small" :min="block3dInput.type == 0 ? 1 : 2" :max="10000" />
        </div>

        <div class="line">
          <label>ColumnY</label>
          <a-input-number v-model:value="columnY" size="small" :min="1" :max="10000" />
        </div>
          </a-col>
          <a-col :span="1"></a-col>
          <a-col :span="12">
            <div class="line">
          <label class="widLabel">Tip margin</label>
          <a-input-number v-model:value="marginTip" size="small" :controls="false" :min="0" :max="0.999" />
        </div>
        <div class="line">
          <label class="widLabel">Hub margin</label>
          <a-input-number v-model:value="marginHub" size="small" :controls="false" :min="0" :max="0.999" />
        </div>
          </a-col>
        </a-row>
        
      </form>
      <a-button @click="handleApply">Apply</a-button>
      <div class="split-line"></div>
      <a-row class="fs16 mb12">
        <a-col :span="24">
  <a-checkbox v-model:checked="conformal" :disabled="bladeTipFanType!='Circle'||bladeFanType!='Circle'||bladeDisabled != 1 || bladeVisible">Conformal Mesh</a-checkbox></a-col>
      </a-row>
      <a-row class="fs16">
        <a-col :span="3">Type</a-col>
        <a-col :span="8">
          <a-select v-model:value="bladeFanType" @change="changeBladeFanType" style="width: 78px"
            :disabled="bladeDisabled != 1 || bladeVisible">
            <a-select-option value="Circle">Circle</a-select-option>
            <a-select-option value="Fan">Fan</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="13">
          <a-button type="primary" :disabled="bladeDisabled != 1 || generateBladeStatus || bladeVisible"
            @click="() => showBlade(0)" block>SetBladeCut</a-button>
        </a-col>
        <!-- 
        <a-button :style="{ marginTop: '10px' }" block :disabled="generateBladeDisabled || bladeVisible"
          @click="() => generateBladeMesh(0)">
          Generate
        </a-button> -->
      </a-row>
      <a-row class="fs16" v-if="block3dInput.type > 0" :style="{ marginTop: '10px' }">
        <a-col :span="3">Type</a-col>
        <a-col :span="8">
          <a-select v-model:value="bladeTipFanType" @change="changeBladeTipFanType" style="width: 78px"
            :disabled="bladeTipDisabled != 1 || bladeVisible">
            <a-select-option value="Circle">Circle</a-select-option>
            <a-select-option value="Fan">Fan</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="13">
          <a-button type="primary" :disabled="bladeDisabled != 1 || bladeVisible" @click="() => showBlade(1)"
            block>SetBladeTipCut</a-button>
        </a-col>
      </a-row>

      <a-button :style="{ marginTop: '10px' }" block :disabled="generateBladeDisabled || bladeVisible"
          @click="() => generateBladeMesh()">
          Generate
        </a-button>
      <a-row :style="{ marginTop: '10px' }">
        <a-col :span="6">
          <label>dim</label></a-col>
        <a-col :span="12"><label>Min</label></a-col>
        <a-col :span="6"><label>Max</label></a-col>
        <a-col :span="6">
          <label>M:</label>
        </a-col>
        <a-col :span="12">
          <a-input-number v-model:value="mValue[getShowType][0]" :min="0" :max="mValue[getShowType][1]" size="small"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible"
            :style="{ width: '40px', display: 'block' }" :step="1" />
        </a-col>

        <a-col :span="6">
          <a-input-number v-model:value="mValue[getShowType][1]" :min="0" :max="mnMax[getShowType][0]" size="small"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible"
            :style="{ width: '40px', display: 'block' }" :step="1" />
        </a-col>
        <a-col :span="6"></a-col>
        <a-col :span="18">
          <a-slider v-model:value="mValue[getShowType]" @change="(e) => changeMnVal(e, 0)" range :min="0"
            :max="mnMax[getShowType][0]"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible" />
        </a-col>

        <a-col :span="6">
          <label>N:</label>
        </a-col>
        <a-col :span="12">
          <a-input-number v-model:value="nValue[getShowType][0]" :min="0" :max="nValue[getShowType][1]" size="small"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible"
            :style="{ width: '40px', display: 'block' }" :step="1" />
        </a-col>

        <a-col :span="6">
          <a-input-number v-model:value="nValue[getShowType][1]" :min="0" :max="mnMax[getShowType][1]" size="small"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible"
            :style="{ width: '40px', display: 'block' }" :step="1" />
        </a-col>
        <a-col :span="6"></a-col>
        <a-col :span="18">
          <a-slider v-model:value="nValue[getShowType]" @change="(e) => changeMnVal(e, 1)" range :min="0"
            :max="mnMax[getShowType][1]"
            :disabled="(getShowType == 0 ? bladeDisabled : bladeTipDisabled) != 2 || bladeVisible" />
        </a-col>
      </a-row>
    </div>
  </a-spin>

  <ToolComponent/>
  </div>
</template>

<script setup>
import { ref, computed, reactive, watch, onDeactivated } from "vue";
import { useStore } from "vuex";
// import Blade from "./Blade.vue";
import { to } from "@/utils/utils";
import model from "@/api/modules/model";
import ToolComponent from "@/components/ToolComponent/index.vue";
const store = useStore();
const generateBladeDisabled = ref(true);
const generateBladeStatus = ref(false);
const block3dInput = computed(() => store.state.model.block3dInput);
const historyCurrent = computed(() => store.state.control.historyCurrent);
const historyList = computed(() => store.state.control.historyList);
const historyFalg = computed(() => store.state.control.historyFalg);
const loading = computed(() => store.state.control.loading);
const row = ref(2);
const bladeFanType = ref('Circle');
const bladeTipFanType = ref('Circle');
const mValue = ref([[0, 0], [0, 0]]);
const nValue = ref([[0, 0], [0, 0]]);

const mnMax = ref([[0, 0], [0, 0]]);

const bladeType = computed(() => store.state.menu.bladeType);
const columnX = ref(block3dInput.value.type == 0 ? 1 : 2);
const marginTip= ref(0);
const marginHub= ref(0);
const columnY = ref(1);
const postData = reactive({ row: 3, columnX: block3dInput.value.type == 0 ? 1 : 2, columnY: 0 });

const conformal=ref(false);

const bladeTipDisabled = ref(0);
const bladeDisabled = ref(0);
watch(block3dInput, (newVal) => {
  columnX.value = newVal.type == 1 ? 1 : 2;
  postData.columnX = newVal.type == 1 ? 1 : 2;
})
const coolingCutApply = async () => {
  postData.row = row.value;
  postData.columnX = columnX.value;
  postData.columnY = columnY.value;
  const [err, res] = await to(
    model.coolingCutApply({
      row: row.value,
      columnX: columnX.value,
      columnY: columnY.value,
      marginTip:marginTip.value,
      marginHub:marginHub.value,
    })
  );

  bladeDisabled.value = 1;
  bladeTipDisabled.value = 1;
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, postData, bladeDisabled: 1, bladeTipDisabled: 1, bladeVisible: false });

  if (err) {
    window.addLog.error("set data failed");
  }

  if (res.code === 200) {
    window.addLog.info("set data successfully");
  } else {
    window.addLog.error("set data failed");
  }
  await store.dispatch('resetModelItems')

  await store.dispatch('resetBlock')
  
  await store.dispatch('resetMesh');

  setTimeout(() => {
    store.dispatch("cteateBable", { type: 0, tipType: block3dInput.value.type == 0 })
    if (block3dInput.value.type > 0) {
      store.dispatch("cteateBable", { type: 1, tipType: true })
    }
  })

  
  const [err1, res1] = await to(model.getRowAndColumn(0));

  if (err1) {
    window.addLog.error('Network error, please refresh');
  }
  mnMax.value[0] = res1.data;
  if (block3dInput.value.type > 0) {

    const [err2, res2] = await to(model.getRowAndColumn(1));
    if (err2) {
      window.addLog.error('Network error, please refresh');
    }
    mnMax.value[1] = res2.data;
  }
  return Promise.resolve();
}
const changeBladeFanType = (e) => {
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, bladeFanType: e });
}
const changeBladeTipFanType = (e) => {
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, bladeTipFanType: e });
}
const getCoolingMatrix = async (b) => {
  let mn = mnMax.value[b];
  
  const [err3, res3] = await to(model.getCoolingMatrixVolumns({ type: 2, pos: b }));
  if (err3) {
    window.addLog.error('Network error, please refresh');
    return
  }
  if (res3.code == 200) {
    let newArr1 = [];
    for (let i = 0; i < mn[0]; i++) {
      newArr1.push(res3.data.slice(i * mn[1], (i + 1) * mn[1]));
    }
    store.commit(["SET_BABLE_BLOCK", "SET_BABLE_TIP_BLOCK"][b], newArr1)
  }
  const [err4, res4] = await to(model.getCoolingMatrixVolumns({ type: 1, pos: b }));
  if (err4) {
    window.addLog.error('Network error, please refresh');
    return
  }
  if (res4.code == 200) {
    let newArr2 = [];
    for (let i = 0; i < mn[0]; i++) {
      newArr2.push(res4.data.slice(i * mn[1], (i + 1) * mn[1]));
    }

    for (let index = 0; index < newArr2.length; index++) {
      const element = newArr2[index];
      for (let index2 = 0; index2 < element.length; index2++) {
        const item2 = element[index2];
        if (b == 1 && ((index == 0 || index == newArr2.length - 1) && (index2 == 0 || index2 == element.length - 1))) {
          continue;
        }
        if (item2?.length == 0&&conformal.value==false) {
          const res0 = await store.dispatch('selectModel', ["Cooling_Blade_", "Cooling_BladeTip_"][b] + index + '_' + index2);
          if (res0.code == 200) {
            const [err, res] = await to(model.getSurfaces(1));

            if (err) {
              window.addLog.error('Network error, please refresh');
              return
            }
            if (res.code == 200) {
              newArr2[index][index2] = res.data.flat();
            }
          }

        }
      }
    }

    // 更新数据
    store.commit(["SET_BABLE_MESH", "SET_BABLE_TIP_MESH"][b], newArr2)

  }

 


  generateBladeStatus.value = true;
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, generateBladeDisabled: true, bladeDisabled: 2, bladeTipDisabled: 2 });

  return Promise.resolve();
}
const generateBladeMesh = async () => {


  store.commit('SET_LOADING', true);
  const [err2,res2] = await to(model.coolingsCut0(conformal.value));

  if (err2) {

    store.commit('SET_LOADING', false);
    window.addLog.error(err2)
    return;
  }
  if(res2.data.mesList?.length){
    res2.data.mesList.forEach(n => {
      window.addLog.error(n)
    });
  }

  const currentName = await store.dispatch('getCurrentModelName');
  window.addLogFalg=true;
  
  await store.dispatch('selectModel', currentName);

  await store.dispatch('resetBlock');
  await store.dispatch('resetMesh');
  window.mnMax=mnMax.value;
  window.conformal=conformal.value;
  await getCoolingMatrix(0);
  if(block3dInput.value.type > 0){
    await getCoolingMatrix(1);
    await store.dispatch('selectModel', currentName);
  }
  
  store.dispatch('resetModelItems')
  store.commit("SET_COOLINGS_CUT_STATUS", false);
  window.addLogFalg=false;
  store.commit('SET_LOADING', false);
}
async function handleApply() {
  store.commit('SET_LOADING', true);

  window.bladeCutMatrix=undefined;
    window.bladeTipCutMatrix=undefined;
  await coolingCutApply()
  generateBladeStatus.value = false;

  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, generateBladeDisabled: true });
  store.commit('SET_HISTORY', {
    hisType: 'CoolingsCut',
    CoolingsCut: {
      params: {
        row: row.value,
        columnX: columnX.value,
        columnY: columnY.value,
      marginTip:marginTip.value,
      marginHub:marginHub.value,
      }
    }
  })
  setTimeout(() => {
    store.commit('SET_LOADING', false);
  })
}


const selectedTreeKeys = computed(() => store.state.model.selectedTreeKeys);

const getShowType = computed(() => {
  if (selectedTreeKeys.value.length) {
    return selectedTreeKeys.value[0].indexOf('0-0') != -1 ? 0 : 1
  } else {
    return 0
  }
});
const changeMnVal = (val, type) => {
  store.commit(getShowType.value == 1 ? "SET_MN_VAL_TIP" : "SET_MN_VAL", { val, type })
}
watch(mnMax, () => {
  if(bladeType.value!=-1){

    mValue.value[bladeType.value][1] = mnMax.value[bladeType.value][0];
  nValue.value[bladeType.value][1] = mnMax.value[bladeType.value][1];
  }
})
const bladeVisible = ref(false);
async function showBlade(type) {

  store.commit("SET_COOLINGS_CUT_CHECK", true)
  if (type === 0) {
    store.commit("SET_BLADE_TYPE", 0);
  } else if (type === 1) {
    store.commit("SET_BLADE_TYPE", 1);
  }
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, bladeVisible: true, generateBladeDisabled: true });

}

const bladeModalProps = computed(() => store.state.control.bladeModalProps);
watch(bladeModalProps, (newVal) => {
  mnMax.value = newVal.mnMax;
  bladeVisible.value = newVal.bladeVisible;
  bladeDisabled.value = newVal.bladeDisabled;
  bladeTipDisabled.value = newVal.bladeTipDisabled;
  generateBladeDisabled.value = newVal.generateBladeDisabled;
}, { deep: true, immediate: true })

watch(historyCurrent, async (newInd, oldInd) => {
  if (!historyFalg.value) return;
  if (historyList.value[newInd].hisType == 'CoolingsCut') {
    const historyData = historyList.value[newInd].CoolingsCut;
    row.value = historyData.params.row;
    columnX.value = historyData.params.columnX;
    columnY.value = historyData.params.columnY;
    marginTip.value = historyData.params.marginTip;
    marginHub.value = historyData.params.marginHub;

    await coolingCutApply()

    window.addLog.info(newInd < oldInd ? "Undo Success" : "Redo Success")
    setTimeout(() => {
      store.commit('SET_LOADING', false);
    })
  }
})

onDeactivated(() => {

  store.commit("SET_COOLINGS_CUT_CHECK", false);
  store.commit('SET_BLADE_MODAL_PROPS', { ...bladeModalProps.value, bladeVisible: false });
})
</script>

<style scoped>
.wrap {
  display: flex;
  flex-direction: column;

}

.mb12{
  margin-bottom: 12px;
}


.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

label {
  height: 25px;
  float: left;
  min-width: 65px;
  line-height: 25px;
  font-size: 14px;
}
.widLabel{
  
  min-width: 80px
}

.fs16 {
  font-size: 12px;
  line-height: 30px;
}

.split-line {
  border-top: 2px solid #000000;
  margin: 12px 0;
}
</style>
