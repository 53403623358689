import { createRouter, createWebHistory  } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
	  path: '/sign-in',
	  name: 'Sign-In',
	  component: () => import('../views/user/Sign-In.vue'),
  },
  {
	  path: '/user/privacy',
	  name: 'privacy',
	  component: () => import('../views/user/Privacy.vue'),
  },
  {
	  path: '/user/terms',
	  name: 'terms',
	  component: () => import('../views/user/Terms.vue'),
  },
  {
	  path: '/user/reset',
	  name: 'reset',
	  component: () => import('../views/user/Reset.vue'),
  },
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/user/Sign-Up.vue'),
	},
];

const router = createRouter({
  history: createWebHistory (),
  routes,
});
// function isJSON(str) {
//     if (typeof str == 'string') {
//         try {
//             const strJson=JSON.parse(str);
//             return strJson;
//         } catch(e) {
//             return false;
//         }
//     }
//     console.log('It is not a string!')    
// }
router.beforeEach((to,from,next)=>{
	next();
	// if(isJSON(localStorage.user)){
	// 	next();
	// }
	// else if(to.path == '/sign-in' || to.path == '/sign-up'||to.path.indexOf('/user')!=-1){
	//   next();
	// }else{
 
	//   next('/sign-in');
  
	// }
  
  })
export default router;
