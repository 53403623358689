<template>
  <a-modal style="top: 50px" v-bind="attrs" ref="modalRef" class="draggableModal" :wrap-style="{ overflow: 'visible',width:0,height:0 }" :maskClosable="false"
    :mask="false" getContainer>
    <children />
    <template #title>
      <div ref="modalTitleRef" style=" cursor: move">{{ title2 }}</div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode" />
      </div>
    </template>
    <template #footer>
      
    <template v-if="$slots.footer">
      <slot name="footer" />
    </template>
      <a-button key="submit" type="primary" :disabled="okButtonProps?.disabled" :loading="confirmLoading" @click="ok">{{
        okText }}</a-button>
    </template>
  </a-modal>
</template>
<script setup>
import { ref, computed,onMounted,onUnmounted, defineProps, watch, watchEffect, useSlots, useAttrs } from 'vue';
import { useDraggable } from '@vueuse/core';
defineProps({
  confirmLoading: Boolean,
  ok: Function,
  okText: String,
  title2: String,
  loading: Boolean,
  okButtonProps: Object
});
const attrs = useAttrs()
const slotsOrigin = useSlots()
const children = () => slotsOrigin.default?.()
const modalTitleRef = ref(null);
const { x, y, isDragging } = useDraggable(modalTitleRef);

const startX = ref(0);
const startY = ref(0);
const startedDrag = ref(false);
const transformX = ref(100);
const transformY = ref(0);
const preTransformX = ref(0);
const preTransformY = ref(0);
const dragRect = ref({ left: 0, right: 0, top: 0, bottom: 0 });
const updateDragRect = () => {
  const bodyRect = document.body.getBoundingClientRect();
  const titleRect = modalTitleRef.value?.getBoundingClientRect();
  dragRect.value.right = bodyRect.width - titleRect?.width;
  dragRect.value.bottom = bodyRect.height - titleRect?.height;
};
watch([x, y], () => {
  if (!startedDrag.value) {
    startX.value = x.value;
    startY.value = y.value;
    const bodyRect = document.body.getBoundingClientRect();
    const titleRect = modalTitleRef.value.getBoundingClientRect();
    dragRect.value.right = bodyRect.width - titleRect.width;
    dragRect.value.bottom = bodyRect.height - titleRect.height;
    preTransformX.value = transformX.value;
    preTransformY.value = transformY.value;
  }
  startedDrag.value = true;
});
watch(isDragging, () => {
  if (!isDragging) {
    startedDrag.value = false;
  }
});

watchEffect(() => {
  if (startedDrag.value) {
    transformX.value =
      preTransformX.value +
      Math.min(Math.max(dragRect.value.left, x.value), dragRect.value.right) -
      startX.value;
    transformY.value =
      preTransformY.value +
      Math.min(Math.max(dragRect.value.top, y.value), dragRect.value.bottom) -
      startY.value;
  }
});
const transformStyle = computed(() => {
  return {
    transform: `translate(${transformX.value}px, ${transformY.value}px)`,
  };
});

const handleWindowResize = () => {
  updateDragRect();
};

onMounted(() => {
  window.addEventListener('resize', handleWindowResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleWindowResize);
});
</script>
<style scoped>

::v-deep .draggableModal .ant-modal-header {
  padding: 0;
}

::v-deep .draggableModal .ant-modal-title>div {
  padding: 16px 24px;
}
</style>