import model from "@/api/modules/model";
import { to } from "@/utils/utils";
export default {
  namespace: true,
  state: {
    mnVal: { m: [-1, 0], n: [-1, 0] },
    mnValTip: { m: [-1, 0], n: [-1, 0] },
    IJKLines: [],
    IJKFaces: [],
    IJKVolumns: [],
    IJKStatus: 0,
    ScanStatus: 0,
    ScanShowList: [],
    ScanMesh0: [],
    ScanMesh1: [],
    ScanMesh2: [],
    ScanMesh3: [],
    loading: false,
    model2DType: 0,
    model2DShow: false,
    model2D: {},
    points2D:[],
    updatePoints2D:0,
    reCenter: 0,
    chartModelId: [],
    chartModelData: [],

    chartColors: [],

    selectModelItems: [],
    selectModelIndex: 0,

    rimSealingData: { pos: 0, prams: [0.8, 0.8, 0.5, 0.8, 0.8, 90, 120, 180] },
    rimSealingComputerData: {},
    currentModelName: "",
    set: localStorage?.set ? JSON.parse(localStorage.set) : {
      backgroundColor: '#ffffff'
    },

    historyCurrent: 0,
    historyList: localStorage?.historyList?.length ? JSON.parse(localStorage.historyList) : [{
      hisType: 'init',
    }],
    user: localStorage?.user ? JSON.parse(localStorage.user) : {},
    historyFalg: false,
    loadingHistory: false,

    bladeModalProps: {
      mnMax: [[], []],
      postData: { row: 3, columnX: 1, columnY: 0 },
      bladeVisible: false,
      bladeDisabled: false,
      bladeFanType: 'Circle',
      bladeTipFanType: 'Circle',
      bladeTipDisabled: false,
      generateBladeDisabled: true,
      formType: 'modal'
    },
    getCurrent2dDataFalg: 0,
    
    coolingsCutMergeBlade: [],
    coolingsCutMergeBladeTip: [],
    
    coolingsCutMergeBladeList: [],
    coolingsCutMergeBladeTipList: [],
    loadFileToMergeList:false,
    currentTypeMatrix:{
      blade: [],
      bladeTip: []
    },
  },
    mutations: {
      SET_LOAD_FILE_TO_MERGE_LIST(state, data) {
        state.loadFileToMergeList = data
      },
      SET_CURRENT_TYPE_MATRIX(state, data) {
        state.currentTypeMatrix = {
          ...state.currentTypeMatrix,
          [data.type?'bladeTip':'blade']:data.data
        }
      },
      RESET_COOLING_CUT_MERGE_BLADE(state) {
        state.coolingsCutMergeBlade = []
      },
      RESET_COOLING_CUT_MERGE_BLADE_TIP(state) {
        state.coolingsCutMergeBladeTip = []
      },
      RESET_COOLING_CUT_MERGE_BLADE_LIST(state) {
        state.coolingsCutMergeBladeList = []
      },
      RESET_COOLING_CUT_MERGE_BLADE_TIP_LIST(state) {
        state.coolingsCutMergeBladeTipList = []
      },
      SET_COOLING_CUT_MERGE_BLADE_LIST(state, data) {
        if(data.index==-2){
          state.coolingsCutMergeBladeList = data.data
        }else if(data.index!=-1){
          state.coolingsCutMergeBladeList.splice(data.index, 1)
        } else{
          state.coolingsCutMergeBladeList.push(data.data)
        }
      },
      SET_COOLING_CUT_MERGE_BLADE_TIP_LIST(state, data) {
        if(data.index==-2){
          state.coolingsCutMergeBladeTipList = data.data
        }else if(data.index!=-1){
          state.coolingsCutMergeBladeTipList.splice(data.index, 1)
        }else{
          state.coolingsCutMergeBladeTipList.push(data.data)
        }
      },
      SET_COOLING_CUT_MERGE_BLADE(state, data) {
        let index = state.coolingsCutMergeBlade.findIndex((ele) => ele.x == data.x && ele.y === data.y)
        if (index == -1) {
          state.coolingsCutMergeBlade=[...state.coolingsCutMergeBlade, data];
        } else {
          state.coolingsCutMergeBlade.splice(index, 1);
          state.coolingsCutMergeBlade=[...state.coolingsCutMergeBlade]
        }
      },

      SET_COOLING_CUT_MERGE_BLADE_TIP(state, data) {

        let index = state.coolingsCutMergeBladeTip.findIndex((ele) => ele.x == data.x && ele.y === data.y)
        if (index == -1) {
          state.coolingsCutMergeBladeTip=[...state.coolingsCutMergeBladeTip, data];
        } else {
          state.coolingsCutMergeBladeTip.splice(index, 1);
          state.coolingsCutMergeBladeTip=[...state.coolingsCutMergeBladeTip]
        }

      },
      SET_MN_VAL(state, data) {
        state.mnVal = { ...state.mnVal, [data.type == 0 ? 'm' : 'n']: data.val }
      },
      SET_MN_VAL_TIP(state, data) {
        state.mnValTip = { ...state.mnValTip, [data.type == 0 ? 'm' : 'n']: data.val }
      },
      SET_IJK_LINES(state, data) {
        state.IJKLines = data;
      },
      SET_MODEL_2D(state, data) {
        state.model2D = data;
      },
      SET_POINTS_2D(state, data) {
        state.points2D = data;
      },
      SET_UPDATE_POINTS_2D(state) {
        state.updatePoints2D=state.updatePoints2D+1;
      },
      SET_MODEL_2D_TYPE(state, data) {
        state.model2DType = data;
      },
      SET_MODEL_2D_SHOW(state, data) {
        state.model2DShow = data;
      },
      SET_LOADING(state, data) {
        document.body.style.cursor = data ? "wait" : "auto";
        state.loading = data;
      },
      SET_IJK_FACES(state, data) {
        state.IJKFaces = data;
      },
      SET_IJK_VOLUMNS(state, data) {
        state.IJKVolumns = data;
      },
      RESET_IJK_STATUS(state) {
        state.IJKStatus = state.IJKStatus + 1;
      },
      RESET_SCAN_STATUS(state, type) {
        state.ScanStatus = type == 0 ? 0 : state.ScanStatus + 1;
      },
      SET_SCAN_MESH0(state, data) {
        state.ScanMesh0 = data;
      },
      SET_SCAN_MESH1(state, data) {
        state.ScanMesh1 = data;
      },
      SET_SCAN_MESH2(state, data) {
        state.ScanMesh2 = data;
      },
      SET_SCAN_MESH3(state, data) {
        state.ScanMesh3 = data;
      },
      SET_SCAN_SHOW_LIST(state, data) {
        state.ScanShowList = data;
      },
      SET_RE_CENTER(state) {
        state.reCenter = state.reCenter + 1;
      },
      SET_CHART_MODEL_ID(state, data) {
        state.chartModelId = data;
      },
      SET_CHART_MODEL_ID_VIS(state, data) {
        let chartModelId = JSON.parse(JSON.stringify(state.chartModelId));

        chartModelId[data].visible = !state.chartModelId[data].visible;
        state.chartModelId = chartModelId;
      },
      SET_CHART_MODEL_ID_GET(state, data) {
        let chartModelId = JSON.parse(JSON.stringify(state.chartModelId));

        chartModelId[data].getData = true;
        state.chartModelId = chartModelId;
      },
      SET_CHART_MODEL_DATA(state, data) {
        state.chartModelData = data;
      },
      SET_CHART_COLORS(state, data) {
        state.chartColors = data;
      },
      SET_SELECT_MODEL_ITEMS(state, data) {
        state.selectModelItems = data;
      },
      SET_SELECT_MODEL_INDEX(state, data) {
        state.selectModelIndex = data;
      },
      SET_RIM_SEALING_DARA(state, data) {
        state.rimSealingData = data;
      },
      SET_CURRENT_MODEL_NAME(state, data) {
        state.currentModelName = data;
      },
      SET_RIM_SEALING_COMPUTER_DARA(state, data) {
        state.rimSealingComputerData = data;
      },
      SET_SET(state, data) {
        state.set = data;
        localStorage.set = JSON.stringify(data);
      },
      SET_HISTORY(state, data) {
        state.historyList.splice(state.historyCurrent + 1)
        state.historyList.push(data);
        state.historyCurrent = state.historyList.length - 1;
        localStorage.historyList = JSON.stringify(state.historyList);
        localStorage.historyCurrent = state.historyCurrent;
      },
      SET_HISTORY_CURRENT(state, data) {
        if (data == 0) {
          state.historyCurrent = 0
        }
        else {
          state.historyCurrent = state.historyCurrent + data;
        }
        localStorage.historyCurrent = state.historyCurrent;
      },

      SET_HISTORY_FALG(state, data) {
        state.historyFalg = data;
      },
      SET_LOADING_HISTORY(state, data) {
        state.loadingHistory = data;
      },
      SET_BLADE_MODAL_PROPS(state, data) {
        state.bladeModalProps = data;
      },
      RESET_GET_CURRENT_2D_DATA_FALG(state) {
        state.getCurrent2dDataFalg = state.getCurrent2dDataFalg + 1;
      },

    },
    actions: {
      async resetModelItems(store) {
        const [err, res] = await to(model.getAllModelNames());
        if (err) {
          return;
        }
        // 存储数据
        store.commit("SET_SELECT_MODEL_INDEX", res.data[0]);
        store.commit("SET_SELECT_MODEL_ITEMS", res.data);

        return Promise.resolve();
      },
      async selectModel(store, name) {
        const [err, res] = await to(model.selectModel(name));
        if (err) {
          return;
        }
        return Promise.resolve(res);
      },
      async getCurrentModelName() {
        const [err, res] = await to(model.getCurrentModelName());
        if (err) {
          return;
        }
        return Promise.resolve(res.data);
      },
      async getModelTypeToBeCut() {
        const [err, res] = await to(model.getModelTypeToBeCut());
        if (err) {
          return;
        }
        return Promise.resolve(res.data);
      }
    },
  };
