
import user from "@/api/modules/user";
import { to } from "@/utils/utils";
export default {
  namespace: true,
  state: {
    teamKey: "0",
    teamList: [],
  },
  mutations: {
    SET_TEAM_KEY(state, data) {
      state.teamKey = data;
    },
    SET_TEAM_LIST(state, data) {
      state.teamList = data;
    },
  },
  actions: {
    
    async createDiscussionGroups(store,name) {
      const [err,res] = await to(user.createDiscussionGroups(name));
      if (err) {
        return;
      }

      return Promise.resolve(res.data);
    },

    async getDiscussionGroupsMembers(store,key){
      const [err,res] = await to(user.getDiscussionGroupsMembers(key));
      if (err) {
        return;
      }

      return Promise.resolve(res.data);

    },
    
    async kickOutMembers(store,data){
      const [err,res] = await to(user.kickOutMembers(data));
      if (err) {
        return;
      }

      return Promise.resolve(res.data);

    },
    async getAllDiscussionGroups(store){
      const [err,res] = await to(user.getAllDiscussionGroups());
      if (err) {
        return;
      }
      
      store.commit("SET_TEAM_LIST",res.data);
      return Promise.resolve(res.data);
    },
    
    async getUserData(){
      const userInfo=JSON.parse(localStorage.user);
      const [err,res] = await to(user.getUserData(userInfo.id));
      if (err) {
        return;
      }
      return Promise.resolve(res.data);
    },
    async getEnterKey(store,key){
      const [err,res] = await to(user.getEnterKey(key));
      if (err) {
        return;
      }
      return Promise.resolve(res.data);
    },
  },
};
