// const baseURL = "www.turbothing.com:9999";
const baseURL = "/api";
// const baseURL = "http://192.168.32.34:9999";

import { Modal } from 'ant-design-vue';
let errFalg = false;
const tokenErr = () => {
  if (errFalg) return;
  errFalg = true;
  Modal.error({
    title: 'Network Error',
    content: 'The token is not valid, please log in again',
    onOk() {
      localStorage.clear();
      window.location.href = '/sign-in';
    },
  });
}
// 统一对 get 方法进行封装
export const get = async (url, data = {}) => {
  const urlRes = baseURL + url;
  let res = { err: true };
  try {
    // 拼接 get 请求参数
    let query = "";

    Object.entries(data).forEach(([key, value], index) => {
      query += (index === 0) ? "?" : "&";
      query += `${key}=${value}`;
    });
    let token = ''
    if (isJSON(localStorage.user)) {
      token = JSON.parse(localStorage.user).token;
    }

    // 发送异步请求
    const response = await fetch(`${urlRes}${query}`, {
      headers: {
        'Accept-Encoding': 'gzip', "Authorization": token,
      }
    });
    if (response) {
      res = await response.json();
      if (res.code == 4000 && !window.addLogFlag) {
        window.addLog.info(res.message)

        throw new Error(res.message)
      }
      if (res.code != 200) {
        if (res.message == "The token is not valid, please log in again") {
          tokenErr();
        }
        throw new Error(res.message)
      }
    }
    return res;
  } catch (error) {
    
    if (!window.addLogFlag)
      window.addLog.error(error)
    return { err: true,message:res.message }
  }
};
export const post = async (url, data = {}) => {
  const urlRes = baseURL + url;
  let res = { err: true };
  try {
    let token = ''
    if (isJSON(localStorage.user)) {
      token = JSON.parse(localStorage.user).token;
    }
    const response = await fetch(urlRes, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Authorization": token,
        "Content-Type": "application/json",
        'Accept-Encoding': 'gzip'
      },
    });
    if (response) {
      res = await response.json();
    }
    if (res.code != 200) {
      if (res.message == "The token is not valid, please log in again") {
        tokenErr();
      }
      throw new Error(res.message)
    }
    return res;
  } catch (error) {
    if (!window.addLogFlag)
      window.addLog.error(error);

    return { err: true,message:res.message };
  }
};
export const upload = async (url, formData = {}) => {
  const urlRes = baseURL + url;
  let res = null;
  try {

    let token = ''
    if (isJSON(localStorage.user)) {
      token = JSON.parse(localStorage.user).token;
    }
    const response = await fetch(urlRes, {
      method: "POST",
      body: formData,

      headers: {
        "Authorization": token
      },
    });
    if (response) {

      res = await response.json();
      if (res.code == 4000 && !window.addLogFlag) {
        window.addLog.error(res.message);
        throw new Error(res.message)
      }
    }

    if (res.code != 200) {
      if (res.message == "The token is not valid, please log in again") {
        tokenErr();
      }
      throw new Error(res.message)
    }
    return res;
  } catch (error) {
    window.addLog.error(error)
    return { err: true,message:res.message };
  }
};

function isJSON(str) {
  if (typeof str == 'string') {
    try {
      const strJson = JSON.parse(str);
      return strJson;
    } catch (e) {
      return false;
    }
  }
  return false;
}