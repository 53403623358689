import * as THREE from "three";

function addFace(faceData, faceMaterial, lineMaterial) {
  let [p0, p1, p2, p3] = [
    new THREE.Vector3(faceData[0], faceData[1], faceData[2]),
    new THREE.Vector3(faceData[3], faceData[4], faceData[5]),
    new THREE.Vector3(faceData[6], faceData[7], faceData[8]),
    new THREE.Vector3(faceData[9], faceData[10], faceData[11])
  ];
  const facePointList = [
    p0, p1, p3, p1, p2, p3
  ]; // 最终面的点数据


  // 根据点信息 构建物体
  const geometry = new THREE.BufferGeometry().setFromPoints(facePointList);

  const edgeGeo = new THREE.EdgesGeometry(geometry, 60)//边框生成
  const edgeMesh = new THREE.LineSegments(edgeGeo, lineMaterial);
  // 生成mesh
  const faceMesh = new THREE.Mesh(geometry, faceMaterial);
  faceMesh.add(edgeMesh);
  return faceMesh;
}
function addFace3(faceDataList, faceMaterial, lineMaterial) {
  let facePointList = [];
  let lines = [];
  faceDataList.forEach(faceData => {
    let [p0, p1, p2, p3] = [
      new THREE.Vector3(faceData[0], faceData[1], faceData[2]),
      new THREE.Vector3(faceData[3], faceData[4], faceData[5]),
      new THREE.Vector3(faceData[6], faceData[7], faceData[8]),
      new THREE.Vector3(faceData[9], faceData[10], faceData[11])
    ];
    facePointList.push(p0, p2, p1, p0, p2, p3)

    lines.push(
      p0, p1, p1, p2, p2, p3, p3, p0
    )
  });

  // window.addLog.info('triangle: '+facePointList.length/3)
  // 根据点信息 构建物体
  const geometry = new THREE.BufferGeometry().setFromPoints(facePointList);

  const lineGeometry = new THREE.BufferGeometry().setFromPoints(lines);
  // const edgeGeo=new THREE.LineSegments(geometry,Math.PI/4)//边框生成
  const edgeMesh = new THREE.LineSegments(lineGeometry, lineMaterial);
  // 生成mesh
  const faceMesh = new THREE.Mesh(geometry, faceMaterial);
  faceMesh.add(edgeMesh);
  return faceMesh;
}
function addModel(data, faceMaterial, lineMaterial) {
  const MAX_POINTS_PER_CHUNK = 100000; // 你可以根据需要调整这个值

  function createBufferGeometryChunks(points) {
    const geometries = [];

    for (let i = 0; i < points.length; i += MAX_POINTS_PER_CHUNK) {
      const chunkPoints = points.slice(i, i + MAX_POINTS_PER_CHUNK);
      const geometry = new THREE.BufferGeometry().setFromPoints(chunkPoints);
      geometries.push(geometry);
    }

    return geometries;
  }

  const facePointList = [];
  const lines = [];
  data.forEach((faceData) => {
    let [p1, p2, p3, p4, p5, p6, p7, p8] = [
      new THREE.Vector3(faceData[0], faceData[1], faceData[2]),
      new THREE.Vector3(faceData[3], faceData[4], faceData[5]),
      new THREE.Vector3(faceData[6], faceData[7], faceData[8]),
      new THREE.Vector3(faceData[9], faceData[10], faceData[11]),
      new THREE.Vector3(faceData[12], faceData[13], faceData[14]),
      new THREE.Vector3(faceData[15], faceData[16], faceData[17]),
      new THREE.Vector3(faceData[18], faceData[19], faceData[20]),
      new THREE.Vector3(faceData[21], faceData[22], faceData[23]),
    ];
    facePointList.push(
      p1, p3, p2,
      p1, p3, p4,
      p1, p6, p2,
      p1, p6, p5,
      p1, p8, p4,
      p1, p8, p5,
      p5, p7, p6,
      p5, p7, p8,
      p2, p7, p6,
      p2, p7, p3,
      p4, p7, p8,
      p4, p7, p3
    );
    lines.push(
      p1, p2, p2, p3, p3, p4, p4, p1,
      p1, p5, p7, p3, p4, p8, p2, p6,
      p5, p6, p6, p7, p7, p8, p8, p5,
    );
  });

  console.log(facePointList.length);
  window.addLog.info("triangle: " + facePointList.length / 3);
  window.addLog.info("elements: " + facePointList.length / 36);

  const faceGeometries = createBufferGeometryChunks(facePointList);
  const lineGeometries = createBufferGeometryChunks(lines);

  const modelGroup = new THREE.Group();

  faceGeometries.forEach((geometry) => {
    const faceMesh = new THREE.Mesh(geometry, faceMaterial);
    modelGroup.add(faceMesh);
  });

  lineGeometries.forEach((geometry) => {
    const edgeMesh = new THREE.LineSegments(geometry, lineMaterial);
    modelGroup.add(edgeMesh);
  });

  return modelGroup;
}


export { addFace, addFace3, addModel };
