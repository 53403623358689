<template>
  <a-spin :spinning="loading">
    <a-form class="form" :class="!rimSealingComputerData ? 'error' : ''" labelAlign="left" :model="from"
      :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">

      <a-form-item label="Model" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-select ref="select" v-model:value="from.modelName" :options="modelNameList" @change="changeModal"></a-select>
      </a-form-item>
      <a-form-item label="Position" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-select ref="select" v-model:value="from.pos" @change="paramsChange" :options="posList"></a-select>
      </a-form-item>

      <div class="split-line"></div>


      <a-form-item label="Δx">
        <a-input-number :controls="false" v-model:value="from.prams[0]"
          :rules="[{ required: true, message: 'Please Input Δx!' }]" @change="paramsChange" :min="0" />
      </a-form-item>
      <a-form-item label="d">
        <a-input-number :controls="false" v-model:value="from.prams[1]"
          :rules="[{ required: true, message: 'Please Input d!' }]" @change="paramsChange" :min="0.001" />
      </a-form-item>
      <a-form-item label="L1">
        <a-input-number :controls="false" v-model:value="from.prams[2]"
          :rules="[{ required: true, message: 'Please Input L1!' }]" @change="paramsChange" :min="0.001" />
      </a-form-item>
      <a-form-item label="L2">
        <a-input-number :controls="false" v-model:value="from.prams[3]"
          :rules="[{ required: true, message: 'Please Input L2!' }]" @change="paramsChange" :min="0.001" />
      </a-form-item>
      <a-form-item label="L3">
        <a-input-number :controls="false" v-model:value="from.prams[4]"
          :rules="[{ required: true, message: 'Please Input L3!' }]" @change="paramsChange" :min="0.001" />
      </a-form-item>
      <a-form-item label="L4">
        <a-input-number :controls="false" :value="rimSealingComputerData ? rimSealingComputerData[5] : null"
          :disabled="true" />
      </a-form-item>
      <a-form-item label="θ1">
        <a-input-number :controls="false" v-model:value="from.prams[5]"
          :rules="[{ required: true, message: 'Please Input θ1!' }]" @change="paramsChange" />
      </a-form-item>
      <a-form-item label="θ2">
        <a-input-number :controls="false" v-model:value="from.prams[6]"
          :rules="[{ required: true, message: 'Please Input θ2!' }]" @change="paramsChange" />
      </a-form-item>
      <a-form-item label="θ3">
        <a-input-number :controls="false" v-model:value="from.prams[7]"
          :rules="[{ required: true, message: 'Please Input θ3!' }]" @change="paramsChange" />
      </a-form-item>
      <a-form-item label="θ4">
        <a-input-number :controls="false" :value="rimSealingComputerData ? rimSealingComputerData[9] : null"
          :disabled="true" />
      </a-form-item>

      <a-space :style="{ width: '100%', marginTop: '10px' }" direction="vertical">
        <a-button block @click="apply" html-type="submit" :disabled="!rimSealingComputerData">Apply</a-button>
      </a-space>
    </a-form>
  </a-spin>
</template>

<script setup>
import { computed, ref, onActivated ,watch} from "vue";
import { useStore } from "vuex";
import { to } from "@/utils/utils";
import model from "@/api/modules/model";
const store = useStore();
const loading = computed(() => store.state.control.loading);
const rimSealingComputerData = computed(() => store.state.control.rimSealingComputerData);
const historyCurrent = computed(() => store.state.control.historyCurrent);
const historyList = computed(() => store.state.control.historyList);
const historyFalg= computed(() => store.state.control.historyFalg);
const blockEdges = computed(() => store.state.model.lines);
const modelNameList = ref([]);
const blockBase = ref(null)
const posList = [
  {
    value: 0,
    label: "UP",
  },
  {
    value: 1,
    label: "DOWN",
  }
]
const currentModelName = computed(() => store.state.control.currentModelName);
const oldValue = ref(null)
const from = ref({
  pos: 0,
  prams: [0.8, 0.8, 0.5, 0.8, 0.8, 90, 120, 180],
  modelName: null
});
const paramsChange = () => {
  store.commit('SET_RIM_SEALING_DARA', { ...from.value })
}
const generateRimSealingModel = async (newFrom) => {
  const [err] = await to(
    model.generateRimSealingModel(newFrom)
  );
  if (err) {
    window.addLog.error(err);
    return;
  }
  await store.dispatch('resetModelItems')

  await store.dispatch('resetBlockNoUpdate')
  store.commit("SET_LINES", [...blockEdges.value, ...blockBase.value]);

  store.commit("SET_DATA_STATUSAdd");

  oldValue.value = { ...from.value, prams: [...rimSealingComputerData.value] };
  return Promise.resolve();
}
const apply = async () => {

  const newFrom = { ...from.value, prams: rimSealingComputerData.value };
  await generateRimSealingModel(newFrom);

  store.commit('SET_HISTORY', {
    hisType: 'RimSealing',
    RimSealing: {
      oldVal: oldValue.value,
      currentModelName: currentModelName.value,
      newVal: { ...from.value, prams: rimSealingComputerData.value }
    }
  })


}
const changeModal = async (e) => {

  store.commit('SET_LOADING', true);
  store.commit('SET_CURRENT_MODEL_NAME', e)
  await store.dispatch('selectModel', e)
  await store.dispatch('resetBlock');
  setTimeout(() => {
    blockBase.value = JSON.parse(JSON.stringify(blockEdges.value));
  })
  store.commit("SET_NO_MESH");

  store.commit('SET_LOADING', false);
  return Promise.resolve();
}
const deleteModal = async (name) => {
  const [err] = await to(
    model.deleteModel(name)
  );
  if (err) {
    window.addLog.error(err);
    return;
  }
}
watch(historyCurrent, async(newVal) => {
  if(!historyFalg.value) return;
  
  if (historyList.value[newVal].hisType == 'RimSealing') {
    const rimSealing = historyList.value[newVal].RimSealing
    if (rimSealing.oldVal == null) {
      deleteModal(rimSealing.currentModelName);
      from.value = {
        pos: 0,
        prams: [0.8, 0.8, 0.5, 0.8, 0.8, 90, 120, 180],
        modelName: null
      }
    }else{
      const pra=rimSealing.newVal.prams
      from.value ={
        ...rimSealing.newVal,
        prams:[pra[0],pra[1],pra[2],pra[3],pra[4],pra[6],pra[7],pra[8]]
      };
      await changeModal(from.value.modelName);
      
      await generateRimSealingModel(rimSealing.newVal);
    }
  }
})
onActivated(async () => {
  
  store.commit('SET_LOADING', true);
  const [err, res] = await to(
    model.getAllModelNamesAboutRimSealing()
  );

  if (err) {

    window.addLog.error(err);
    return;
  }
  modelNameList.value = (res.data||[]).map(n => {
    return {
      value: n,
      label: n,
    }
  });
  
  store.commit('SET_LOADING', false);
  if (modelNameList.value.length) {
    from.value.modelName = modelNameList.value[0].value;
    await changeModal(from.value.modelName);
  }

})


</script>
<style lang="scss" scoped>
.form ::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.ant-input-group-addon {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 8px;
}


.split-line {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.icon-select {
  display: flex;
  padding: 7px 11px;
  line-height: 32px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.ant-input-number {
  width: 100%;
}

.ant-input-group-addon {
  background-color: white;
  padding: 0 5px;
}

.error {
  .ant-input-number {
    border-color: red;
  }
}
</style>
<style>
.selected .ant-input-group-addon {
  background-color: #409eff;
  color: white;
}
</style>
