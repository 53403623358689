<template>
  <div class="blade">
    <DraggableModal
      v-if="formType == 'modal'"
      :visible="visible"
      :width="getWidth(matrix.data0[0].length) + 'px'"
      :title2="
        (bladeType ? 'BladeTip' : 'Blade') +
        (type > 0 ? '-' + nowConfig[type - 1]?.name : '') +
        (type == 0 ? '' : ' (Parameter: ' + type + '/' + nowConfig.length + ')')
      "
      @cancel="handleCancel"
      :confirmLoading="loading"
      :okButtonProps="{ disabled: changeData0 }"
      :ok="handleApply"
      :okText="type != nowConfig.length ? 'Next' : 'Apply'"
    >
      <template #footer>
        <a-button
          type="primary"
          style="float: left"
          :disabled="type == 0"
          @click="back"
          >Back</a-button
        >
      </template>
      <a-row justify="space-between">
        <a-col>
          M * N: {{ matrix.data0.length }} * {{ matrix.data0[0].length }}
        </a-col>
        <a-col>
          <a-space>
            <a-upload
              style="display: inline-block"
              :fileList="[]"
              :customRequest="() => {}"
              :before-upload="handleBeforeUpload"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                Import DataFile
              </a-button>
            </a-upload>
            <a-button @click="exportToTxt">
              <download-outlined />Export File</a-button
            >
            <a-tooltip>
              <template #title>embedded page</template>
              <a-button @click="() => (formType = 'bar')">
                <fullscreen-exit-outlined />
              </a-button>
            </a-tooltip>
          </a-space>
        </a-col>
      </a-row>
      <div
        style="
          overflow: overlay;
          width: 100%;
          height: 100%;
          padding-bottom: 20px;
        "
      >
        <div :style="{ minWidth: matrix.data0[0].length * 100 + 'px' }">
          <a-row v-if="!bladeType" class="arrow-tips">
            <div :style="{ flex: colx }" class="ps">
              PS
              <div class="line"></div>
            </div>
            <div :style="{ flex: coly }" class="te">
              TE
              <div class="line"></div>
            </div>
            <div :style="{ flex: colx }" class="ss">
              SS
              <div class="line"></div>
            </div>
            <div :style="{ flex: coly }" class="le">
              LE
              <div class="line"></div>
            </div>
          </a-row>

          <form style="position: relative">
            <div v-for="(item, index) in mergeMatrixList" :key="index">
              <div
                v-if="item.length == 1"
                class="mergeBlock"
                :style="{
                  left: item[0].startYPercentage + '%',
                  top: item[0].startXPercentage + '%',
                  width: item[0].widthPercentage + '%',
                  height: item[0].heightPercentage + '%',
                }"
              ></div>
              <div v-else>
                <div
                  class="mergeBlockLeft"
                  :style="{
                    left: item[0].startYPercentage + '%',
                    top: item[0].startXPercentage + '%',
                    width: item[0].widthPercentage + '%',
                    height: item[0].heightPercentage + '%',
                  }"
                ></div>
                <div
                  class="mergeBlockRight"
                  :style="{
                    left: item[1].startYPercentage + '%',
                    top: item[1].startXPercentage + '%',
                    width: item[1].widthPercentage + '%',
                    height: item[1].heightPercentage + '%',
                  }"
                ></div>
              </div>
            </div>
            <a-row
              class="ptr4"
              v-for="(item, mIndex) in matrix.data0"
              :key="'line' + mIndex"
            >
              <div
                v-for="(it, nIndex) in item"
                :key="'line' + mIndex + 'col' + nIndex"
                style="flex: 1"
              >
                <a-tooltip>
                  <template #title>
                    Row {{ mIndex + 1 }} Column {{ nIndex + 1 }}
                  </template>
                  <a-input-number
                    v-if="type == 0"
                    class="number0"
                    :disabled="
                      (bladeType == 1 &&
                        (mIndex == 0 || mIndex == matrix.data0.length - 1) &&
                        (nIndex == 0 ||
                          nIndex == matrix.data0[0].length - 1)) ||
                      (matrix.data0[mIndex][nIndex] != 0 &&
                        matrix.data0[mIndex][nIndex] !=
                          coolingType[bladeFanType])
                    "
                    @click="
                      () => {
                        dataClick(mIndex, nIndex);
                        matrix.data0[mIndex][nIndex] = matrix.data0[mIndex][
                          nIndex
                        ]
                          ? 0
                          : coolingType[bladeFanType];
                      }
                    "
                    v-model:value="matrix.data0[mIndex][nIndex]"
                    :min="0"
                    :max="coolingType[bladeFanType]"
                    :controls="false"
                  />

                  <a-input-number
                    v-else
                    :disabled="
                      matrix.data0[mIndex][nIndex] != coolingType[bladeFanType]
                    "
                    :class="{ errInput: matrix.errorList[mIndex][nIndex] }"
                    v-model:value="matrix.data[type - 1][mIndex][nIndex]"
                    @change="() => dataClick(mIndex, nIndex)"
                    :min="
                      !matrix.data0[mIndex][nIndex]
                        ? 0
                        : nowConfig[type - 1].min
                    "
                    :max="
                      matrix.data0[mIndex][nIndex] != coolingType[bladeFanType]?Infinity:(nowConfig[type - 1] == 'Radius'
                        ? radiusMax[mIndex][nIndex]
                        : nowConfig[type - 1].max)
                    "
                    :controls="false"
                  />
                </a-tooltip>
              </div>
            </a-row>
          </form>
        </div>
      </div>
    </DraggableModal>

    <div v-else-if="visible">
      <a-card
        :title="
          (bladeType ? 'BladeTip' : 'Blade') +
          (type > 0 ? '-' + nowConfig[type - 1]?.name : '') +
          (type == 0
            ? ''
            : '(Parameter: ' + type + '/' + nowConfig.length + ')')
        "
        style="width: 100%"
      >
        <template #extra>
          <a-tooltip>
            <template #title>set as popup</template>

            <a-button type="text" @click="() => (formType = 'modal')"
              ><expand-outlined />
            </a-button>
          </a-tooltip>

          <a-button type="text" @click="handleCancel"
            ><close-outlined
          /></a-button>
        </template>
        <a-row justify="space-between">
          <a-col>
            M * N: {{ matrix.data0.length }} * {{ matrix.data0[0].length }}
          </a-col>
          <a-col>
            <a-button
              type="primary"
              :disabled="type == 0"
              @click="back"
              :style="{ marginLeft: '8px' }"
              >Back</a-button
            >
            <a-button
              type="primary"
              :loading="loading"
              :disabled="changeData0"
              @click="handleApply"
              :style="{ marginLeft: '8px' }"
              >{{ type != nowConfig.length ? "Next" : "Apply" }}</a-button
            >
          </a-col>
        </a-row>
        <div
          style="
            overflow: overlay;
            width: 100%;
            height: 100%;
            padding-bottom: 20px;
          "
        >
          <div :style="{ minWidth: matrix.data0[0].length * 100 + 'px' }">
            <a-row v-if="!bladeType" class="arrow-tips">
              <div :style="{ flex: colx }" class="ps">
                PS
                <div class="line"></div>
              </div>
              <div :style="{ flex: coly }" class="te">
                TE
                <div class="line"></div>
              </div>
              <div :style="{ flex: colx }" class="ss">
                SS
                <div class="line"></div>
              </div>
              <div :style="{ flex: coly }" class="le">
                LE
                <div class="line"></div>
              </div>
            </a-row>

            <form style="position: relative">
              <div v-for="(item, index) in mergeMatrixList" :key="index">
                <div
                  v-if="item.length == 1"
                  class="mergeBlock"
                  :style="{
                    left: item[0].startYPercentage + '%',
                    top: item[0].startXPercentage + '%',
                    width: item[0].widthPercentage + '%',
                    height: item[0].heightPercentage + '%',
                  }"
                ></div>
                <div v-else>
                  <div
                    class="mergeBlockLeft"
                    :style="{
                      left: item[0].startYPercentage + '%',
                      top: item[0].startXPercentage + '%',
                      width: item[0].widthPercentage + '%',
                      height: item[0].heightPercentage + '%',
                    }"
                  ></div>
                  <div
                    class="mergeBlockRight"
                    :style="{
                      left: item[1].startYPercentage + '%',
                      top: item[1].startXPercentage + '%',
                      width: item[1].widthPercentage + '%',
                      height: item[1].heightPercentage + '%',
                    }"
                  ></div>
                </div>
              </div>
              <a-row
                class="ptr4"
                v-for="(item, mIndex) in matrix.data0"
                :key="'line' + mIndex"
              >
                <div
                  v-for="(it, nIndex) in item"
                  :key="'line' + mIndex + 'col' + nIndex"
                  style="flex: 1"
                >
                  <a-tooltip>
                    <template #title>
                      Row {{ mIndex + 1 }} Column {{ nIndex + 1 }}
                    </template>
                    <a-input-number
                      v-if="type == 0"
                      class="number0"
                      :disabled="
                        (bladeType == 1 &&
                          (mIndex == 0 || mIndex == matrix.data0.length - 1) &&
                          (nIndex == 0 ||
                            nIndex == matrix.data0[0].length - 1)) ||
                        (matrix.data0[mIndex][nIndex] != 0 &&
                          matrix.data0[mIndex][nIndex] !=
                            coolingType[bladeFanType])
                      "
                      @click="
                        () => {
                          dataClick(mIndex, nIndex);
                          matrix.data0[mIndex][nIndex] = matrix.data0[mIndex][
                            nIndex
                          ]
                            ? 0
                            : coolingType[bladeFanType];
                        }
                      "
                      v-model:value="matrix.data0[mIndex][nIndex]"
                      :min="0"
                      :max="coolingType[bladeFanType]"
                      :controls="false"
                    />

                    <a-input-number
                      v-else
                      :disabled="
                        matrix.data0[mIndex][nIndex] !=
                        coolingType[bladeFanType]
                      "
                      :class="{ errInput: matrix.errorList[mIndex][nIndex] }"
                      v-model:value="matrix.data[type - 1][mIndex][nIndex]"
                      :min="
                        !matrix.data0[mIndex][nIndex]
                          ? 0
                          : nowConfig[type - 1].min
                      "
                      @change="() => dataClick(mIndex, nIndex)"
                      :max="
                         matrix.data0[mIndex][nIndex] != coolingType[bladeFanType]?Infinity:(nowConfig[type - 1] == 'Radius'
                          ? radiusMax[mIndex][nIndex]
                          : nowConfig[type - 1].max)
                      "
                      :controls="false"
                    />
                  </a-tooltip>
                </div>
              </a-row>
            </form>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { to, formatArrayNoNewlines } from "@/utils/utils";
import {
  ExpandOutlined,
  FullscreenExitOutlined,
  DownloadOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import { saveAs } from "file-saver";
import DraggableModal from "@/components/DraggableModal/index.vue";
import model from "@/api/modules/model";

import { message } from "ant-design-vue";
const props = defineProps({
  visible: Boolean,
  row: Number,
  colx: Number,
  coly: Number,
  bladeTipDisabled: Number,
  bladeDisabled: Number,
  mnMax: Array,
  bladeFanType: String,
});
const emit = defineEmits([
  "update:visible",
  "update:bladeTipDisabled",
  "update:bladeDisabled",
  "update:mnMax",
]);
const store = useStore();
const type = ref(0);
const radiusMax = ref([]);
const matrix = reactive({ data0: [[]], data: [], errorList: [] });
const formType = ref("modal");
watch(formType, (newVal) => {
  store.commit("SET_BLADE_MODAL_PROPS", {
    ...bladeModalProps.value,
    formType: newVal,
  });
});
const loading = computed(() => store.state.control.loading);
const defaultConfig = ref([0.5, 0.5, 0, 30, 1.5, 2.5, 3.5, 7, 7, 0.5]);
const coolingType = ref({
  Circle: 1,
  Fan: 2,
});

const bladeModalProps = computed(() => store.state.control.bladeModalProps);
const config = reactive([
  { index: 0, name: "Position X", min: 0.001, max: 1 },
  { index: 1, name: "Position Y", min: 0.001, max: 1 },
  { index: 2, name: "Primary angle", min: -Infinity, max: Infinity },
  { index: 3, name: "Secondary angle", min: 0.001, max: 90 },
  { index: 4, name: "Cooling hole length (mm)", min: 0.001, max: Infinity },
  { index: 5, name: "Cooling hole radius (mm)", min: 0.001, type: "Circle" },
  {
    index: 6,
    name: "Metering section length / metering section diameter",
    min: 1,
    max: 4,
    type: "Fan",
  },
  {
    index: 7,
    name: "Forward section length / metering section diameter ",
    min: 1.6,
    max: 9.5,
    type: "Fan",
  },
  { index: 8, name: "Lateral expansion angle", min: 2, max: 25, type: "Fan" },
  { index: 9, name: "Forward expansion angle", min: 2, max: 18, type: "Fan" },
  {
    index: 10,
    name: "Rounding of four edges inside diffuser",
    min: 0.001,
    max: 0.5,
    type: "Fan",
  },
]);
const bladeType = computed(() => store.state.menu.bladeType);
const nowConfig = computed(() =>
  config.filter((n) => {
    return !n.type || n.type == props.bladeFanType;
  })
);
const changeData0 = computed(() => {
  let flag = true;
  matrix.data0.forEach((r) => {
    r.forEach((n) => {
      if (n) flag = false;
    });
  });
  return flag;
});

const coolingsCutMergeBladeList = computed(
  () => store.state.control.coolingsCutMergeBladeList
);
const coolingsCutMergeBladeTipList = computed(
  () => store.state.control.coolingsCutMergeBladeTipList
);

const mergeDataList = computed(() => {
  return bladeType.value
    ? coolingsCutMergeBladeTipList.value
    : coolingsCutMergeBladeList.value;
});

function getMergedRectangle(matrix, mergeData) {
  // 初始化最小和最大的x、y坐标
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  // 遍历mergeData来确定最小和最大的x、y坐标
  for (let data of mergeData) {
    minX = Math.min(minX, data.x);
    minY = Math.min(minY, data.y);
    maxX = Math.max(maxX, data.x);
    maxY = Math.max(maxY, data.y);
  }

  let commonX = mergeData[0].x;
  let horizontalData = mergeData.filter((data) => data.x === commonX);
  // 对x值进行排序
  let sortedX = horizontalData.sort((a, b) => a.y - b.y);

  // 找出断层
  let breakIndex = -1;
  for (let i = 0; i < sortedX.length - 1; i++) {
    if (sortedX[i + 1].y - sortedX[i].y > 1) {
      breakIndex = i;
      break;
    }
  }

  if (breakIndex != -1) {
    // 计算两个长度
    let firstLength =
      breakIndex >= 0
        ? sortedX[breakIndex].y - sortedX[0].y + 1
        : sortedX.length;
    let secondLength =
      breakIndex >= 0
        ? sortedX[sortedX.length - 1].y - sortedX[breakIndex + 1].y + 1
        : 0;

    let firstRectangle = {
      startXPercentage: (minX / matrix.length) * 100,
      startYPercentage: 0,
      widthPercentage: (firstLength / matrix[0].length) * 100,
      heightPercentage: ((maxX - minX + 1) / matrix.length) * 100,
    };

    let secondRectangle = {
      startXPercentage: (minX / matrix.length) * 100,
      startYPercentage: (sortedX[breakIndex + 1].y / matrix[0].length) * 100,
      widthPercentage: (secondLength / matrix[0].length) * 100,
      heightPercentage: ((maxX - minX + 1) / matrix.length) * 100,
    };

    return [firstRectangle, secondRectangle];
  } else {
    // 计算长方形的宽度和高度占的百分比
    let rectangleWidthPercentage = ((maxY - minY + 1) / matrix[0].length) * 100;
    let rectangleHeightPercentage = ((maxX - minX + 1) / matrix.length) * 100;

    // 计算起始位置的百分比
    let startXPercentage = (minX / matrix.length) * 100;
    let startYPercentage = (minY / matrix[0].length) * 100;

    // 返回长方形的长、高以及起始位置的百分比
    return [
      {
        startXPercentage: startXPercentage,
        startYPercentage: startYPercentage,
        widthPercentage: rectangleWidthPercentage,
        heightPercentage: rectangleHeightPercentage,
      },
    ];
  }
}
const mergeMatrixList = computed(() => {
  const list = mergeDataList.value.map((n) => {
    return getMergedRectangle(matrix.data0, n);
  });
  return list;
});
const getWidth = (len) => {
  if (len < 5) {
    return 500;
  } else if (len < 12) {
    return len * 100 + 100;
  }
  return 1200;
};
let matrixFalg = true;
const dataClick = (x, y) => {
  nextTick(() => {
    let ind = mergeDataList.value.findIndex((n) => {
      return n.findIndex((j) => j.x == x && j.y == y) != -1;
    });
    if (ind != -1) {
      matrixFalg = false;
      let data = type.value == 0 ? matrix.data0 : matrix.data[type.value - 1];
      mergeDataList.value[ind].forEach((n) => {
        data[n.x][n.y] = data[x][y];
      });
      nextTick(() => {
        matrixFalg = true;
      });
    }
  });
};
watch(
  matrix,
  (newValue) => {
    if (matrixFalg) {
      store.commit("SET_BABLE_BLOCK_MATRIX", newValue.data0);
    }
  },
  { deep: true }
);
const getNewMatrix = async () => {
  let res = { data: props.mnMax[bladeType.value] };
  type.value = 0;
  const [err1, res1] = await to(model.getCoolingsMatrix(bladeType.value));
  if (err1) {
    store.commit("SET_LOADING", false);
    window.addLog.error(err1);
    return;
  }
  matrix.data0 = res1.data.length
    ? res1.data
    : createMatrix(res.data[0], res.data[1], 0);
    
    if(window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix']==undefined){
       window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix']={};
    }
  matrix.errorList = createMatrix(res.data[0], res.data[1], 0);
  matrix.data.length = 0;
  config
    .filter((n) => {
      return !n.type || n.type == props.bladeFanType;
    })
    .forEach(() => {
      matrix.data.push(createMatrix(res.data[0], res.data[1], 0));
    });
  radiusMax.value = createMatrix(res.data[0], res.data[1], 0);
};
watch(bladeType, (newVal) => {
  if (newVal == -1) {
    return;
  }
  getNewMatrix(newVal);
});
watch(props.bladeFanType, () => {
  getNewMatrix(bladeType.value);
});

const back = () => {
  type.value = type.value - 1;
};
const BableBlock = computed(() => store.state.model.BableBlock);
const BableTipBlock = computed(() => store.state.model.BableTipBlock);

const next = async () => {
  const setCoolingsMatrix =
    type.value == 0 ? model.setCoolingsMatrix : model.setCoolingDesignMatrix;
  let design=type.value == 0 ? -1 : nowConfig.value[type.value - 1].index;
  let currentMatrix= type.value == 0 ? matrix.data0 : matrix.data[type.value - 1];
   //处理 mergelist 只上传选择的
   let mergeList=mergeDataList.value.filter(n=>{
    return matrix.data0[n[0].x][n[0].y]!=0;
  })

  let bable=!bladeType.value?BableBlock.value:BableTipBlock.value;
  let volumnIdList=mergeList.map(n=>{
    return n.map(m=>{return bable[m.x][m.y]?.[0]})
  })

  const [err] = await to(
    setCoolingsMatrix({
      design: design,
      matrix: currentMatrix,
      merge: volumnIdList,
      matrixFirst: matrix.data0,
      position: bladeType.value,
    })
  );
  store.commit("SET_CURRENT_TYPE_MATRIX",{type:bladeType.value,data:matrix.data0});
  if (err) {
    store.commit("SET_LOADING", false);
    window.addLog.error(err);
    return;
  }
  if(design>=0){
    
      window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix'][design]=currentMatrix;
   
  } 
 
  if (type.value == 5 && props.bladeFanType != "Fan") {
    const [err1, res] = await to(
      model.getCoolingDesignMatrix({ type: 5, pos: bladeType.value })
    );
    if (err1) {
      store.commit("SET_LOADING", false);
      window.addLog.error(err1);
      return;
    }
    matrix.data0.forEach((it, index) => {
      it.forEach((item, ind) => {
        if(item==coolingType.value[props.bladeFanType]){
          
          matrix.data[5][index][ind] = res.data[index][ind];
        }
      })})
    radiusMax.value = res.data;
  }
  if (type.value == (props.bladeFanType == "Fan" ? 10 : 6)) {
    if (props.bladeFanType == "Fan") {
      matrix.errorList = matrix.errorList.map((item) => {
        return item.map(() => {
          return 0;
        });
      });
      const [err3, res3] = await to(
        model.checkFanShapedHoleMetrics(bladeType.value)
      );

      if (err3) {
        window.addLog.error(err3);
        store.commit("SET_BLADE_TYPE", -1);
        store.commit("SET_LOADING", false);
        return;
      }
      if (res3.data) {
        let errMsg = "Following data is invalid:";
        res3.data.forEach((n) => {
          matrix.errorList[n[0]][n[1]] = 1;
          errMsg += `'Row ${n[0]},Col ${n[1]}'`;
        });
        store.commit("SET_LOADING", false);
        window.addLog.error(errMsg);
        return;
      } else {
        okCancel();
      }
    } else {
      okCancel();
    }
  }
  if (type.value < (props.bladeFanType == "Fan" ? 10 : 6)) {
    if(window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix'][type.value]){
      
      let mat=window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix'][type.value];
      matrix.data0.forEach((it, index) => {
      it.forEach((item, ind) => {
        if(item!=coolingType.value[props.bladeFanType]&&item!=0){
          
          matrix.data[type.value][index][ind] =mat?.[index]?.[ind]||0;
        }
      })})
    }
    type.value = type.value + 1;

    store.commit("SET_LOADING", false);
  }
  return Promise.resolve();
};
const okCancel = () => {
  matrix.data0 = [[]];
  store.commit("SET_LOADING", false);
  emit("update:visible", false);
  setTimeout(() => {
    store.commit("SET_BLADE_MODAL_PROPS", {
      ...bladeModalProps.value,
      generateBladeDisabled: false,
    });
    store.commit("SET_BLADE_TYPE", -1);
  });
};
function createMatrix(m, n, val) {
  const matrix = [];

  for (let i = 0; i < m; i++) {
    matrix.push(Array.apply(null, Array(n)).map(Number.prototype.valueOf, val));
  }

  return matrix;
}

const handleCancel = async () => {
  store.commit("SET_LOADING", true);
  let martixInit = matrix.data0.map((n) => {
    return n.map((n2) => {
      return n2 == coolingType.value[props.bladeFanType] ? 0 : n2;
    });
  });
  let bable=!bladeType.value?BableBlock.value:BableTipBlock.value;
  let volumnIdList=mergeDataList.value.map(n=>{
    return n.map(m=>{return bable[m.x][m.y]?.[0]})
  })
  const [err] = await to(
    model.setCoolingsMatrix({
      design: 0,
      matrix: martixInit,
      merge: volumnIdList,
      matrixFirst: martixInit,
      position: bladeType.value,
    })
  );
  
  store.commit("SET_CURRENT_TYPE_MATRIX",{type:bladeType.value,data:matrix.data0});
  window[bladeType.value?'bladeTipCutMatrix':'bladeCutMatrix'][0]=martixInit;
  if (err) {
    store.commit("SET_LOADING", false);
    window.addLog.error(err);
    return;
  }
  window.addLog.info("blade cancel");
  matrix.data0 = martixInit;
  setTimeout(() => {
    store.commit("SET_BLADE_TYPE", -1);
  });
  store.commit("SET_LOADING", false);
  emit("update:visible", false);
};
function handleApply() {
  store.commit("SET_LOADING", true);
  if (type.value == 0) {
    matrix.data0.forEach((it, index) => {
      it.forEach((item, ind) => {
        nowConfig.value.forEach((n, i) => {
          matrix.data[i][index][ind] =
            item == coolingType.value[props.bladeFanType]
              ? defaultConfig.value[i]
              : 0;
        });
      });
    });
  }

  next();
}

const exportToTxt = () => {
  let jsonData = {
    mergeList:mergeDataList.value,
    init: matrix.data0,
  };
  nowConfig.value.forEach((n, i) => {
    jsonData[n.name] = matrix.data[i];
  });
  jsonData.position = bladeType.value == 0 ? "BladeCut" : "BladeTipCut";

  const jsonStr = JSON.stringify(jsonData, null, 2); // 使用两个空格进行缩进，保持可读性
  let formattedJsonStr = formatArrayNoNewlines(jsonStr); // 格式化数组，去除换行符
  formattedJsonStr = formattedJsonStr.replaceAll(
    "[  [",
    `[
    [`
  );
  const blob = new Blob([formattedJsonStr], {
    type: "text/plain;charset=utf-8",
  });
  saveAs(blob, "data.txt");
};
const fileSetMatrix = (index, data) => {
  matrix.data[index].forEach((n, nIndex) => {
    n.forEach((m, mIndex) => {
      if (data?.[nIndex]?.[mIndex])
        matrix.data[index][nIndex][mIndex] = data[nIndex][mIndex];
    });
  });
};
const mergeJudge = async (data) => {
  const [err] = await to(model.checkIfMerge({nums:data}));
  if (err) {
    return Promise.resolve(false);
  }else{
    return Promise.resolve(true);
  }
  
};
const handleBeforeUpload = (file) => {
  const reader = new FileReader();

  reader.onload = async (e) => {
    const contents = e.target.result;
    try {
      const data = JSON.parse(contents);
      let pos = data.position == "BladeCut" ? 0 : 1;

      let bable=!pos?BableBlock.value:BableTipBlock.value;
      for(let i=0;i<data.mergeList.length;i++){
        let volumnIdList=data.mergeList[i].map(n=>{
          return bable[n.x][n.y]?.[0]
        })
        console.log(volumnIdList);
        const falg=await mergeJudge(volumnIdList);
        if(!falg){
          throw "Merge Data Error";
        }
      }
      store.commit(
        pos
          ? "RESET_COOLING_CUT_MERGE_BLADE_TIP"
          : "RESET_COOLING_CUT_MERGE_BLADE"
      );
      
      store.commit(pos ? "SET_COOLING_CUT_MERGE_BLADE_TIP_LIST" : "SET_COOLING_CUT_MERGE_BLADE_LIST",{index:-2,data:data.mergeList});
      store.commit("SET_LOAD_FILE_TO_MERGE_LIST" ,{pos,data:data.mergeList});
      if (pos != bladeType.value) {
        throw "Blade Type Invalid";
      }

      if (
        data.init.length != matrix.data0.length ||
        data.init[0]?.length != matrix.data0[0].length
      ) {
        throw `Data Invalid`;
      }

      store.commit("SET_LOADING", true);
      type.value = 0;
      matrix.data0.forEach((n, nIndex) => {
        n.forEach((m, mIndex) => {
          if (data.init?.[nIndex]?.[mIndex])
            matrix.data0[nIndex][mIndex] = data.init[nIndex][mIndex];
        });
      });

      await next();
      nowConfig.value.forEach((n, index) => {
        if (
          data[n.name].length != matrix.data0.length ||
          data[n.name][0]?.length != matrix.data0[0].length
        ) {
          throw `Data '${n.name}' Invalid`;
        }

        fileSetMatrix(index, data[n.name]);
      });
      for (let i = 0; i < nowConfig.value.length; i++) {
        await next();
      }
    } catch (error) {
      console.error(error);
      message.error("File Read Error:" + error);
      window.addLog.error("File Read Error:" + error);
    }
  };

  reader.readAsText(file);
};
</script>

<style scoped lang="scss">
.mergeBlock {
  position: absolute;
  border: 1px solid #00ff;
}
.mergeBlockLeft {
  position: absolute;
  border: 1px solid #00ff;
  border-left: 0px;
}
.mergeBlockRight {
  position: absolute;
  border: 1px solid #00ff;
  border-right: 0px;
}
::v-deep .ant-card-head-wrapper {
  min-height: 30px;
}

::v-deep .ant-card-head {
  min-height: 30px;
}

::v-deep .ant-card-head-title {
  padding: 4px 0;
}

::v-deep .ant-card-extra {
  padding: 4px 0;
}

.arrow-tips {
  text-align: center;
  font-size: 26px;
  font-family: fantasy;

  .line {
    border: 1px solid;
    height: 1px;
    width: 100%;
  }

  .ps {
    color: #096dd9;
  }

  .ss {
    color: red;
  }

  .te {
    color: greenyellow;
  }

  .le {
    color: black;
  }
}

.errInput {
  border-color: #ff4d4f;
}

.errInput ::v-deep .ant-input-number {
  border-color: #ff4d4f;
}

.number0 ::v-deep {
  // .ant-input-number-handler-wrap,.ant-input-number-handler-up-inner,.ant-input-number-handler-down-inner{
  //   width: 100%;
  //   right: 0;
  // }
  .ant-input-number-input {
    padding: 0;
    text-align: center;
  }
}

.ptr4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-input-number {
  width: 90%;
  margin-left: 5%;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}
</style>
