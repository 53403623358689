<template>
  
  <a-spin :spinning="loading">
  <form>
    <div class="qualityType container">
      <div class="title">QualityType</div>
      <el-row>
        <el-col :span="6" style="margin: auto"><label>Criterion</label></el-col>
        <el-col :span="2" />
        <el-col :span="16">
          <el-select v-model="cirterion">
            <el-option v-for="item in qualitTypeSelectData" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>
    <br />
    <a-button @click="apply" :loading="loading" block>Apply</a-button>
    
    <a-button  style="margin-top: 15px;" @click="exportChartData" block>Export</a-button>
  </form>
  </a-spin>
</template>

<script setup>
import { ref,computed } from "vue";
import { useStore } from "vuex";

import model from "@/api/modules/model";
import { to } from "@/utils/utils";
const store = useStore();

const chartData = computed(() => store.state.menu.chartData);
const cirterion = ref(0);
const qualitTypeSelectData = ref([
  {
    value: 0,
    label: "JacobianRatio",
  },
  // {
  //   value: 1,
  //   label: "CornerAngle",
  // },
  {
    value: 1,
    label: "MaxAngle",
  },
  {
    value: 2,
    label: "MinAngle",
  },
  {
    value: 3,
    label: "Skewness",
  },
  {
    value: 4,
    label: "EquiangleSkewness",
  },
  {
    value: 5,
    label: "Warpingfactor",
  },
  {
    value: 6,
    label: "MaxWarp_ICEM",
  },
  {
    value: 7,
    label: "AspectRatio",
  },
  {
    value: 8,
    label: "AspectRatio_ICEM",
  },
  {
    value: 9,
    label: "ParallelDeviation",
  },
  {
    value: 10,
    label: "OrthogonalQuality",
  },
  {
    value: 11,
    label: "Volume",
  },
]);

const loading = computed(() => store.state.control.loading);
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
async function apply() {
  store.commit('SET_LOADING', true);
  const [err, res] = await to(
    model.getMeshQualityHistogramDefault(cirterion.value)
  );

  if (err) {
    window.addLog.error('Network error, please refresh');
  }


  window.addLog.info(`${qualitTypeSelectData.value[cirterion.value].label} loading successfully`);
  let list = [];
  for (let i = 0; i + 3 < res.data.length; i += 3) {
    let x1 = parseInt(getFullNum(res.data[i]) * 100);
    let x2 = parseInt(getFullNum(res.data[i + 1]) * 100);
    list.push({
      x1,
      x2,

      x: i / 3 * 2 + 1,
      val: res.data[i + 2],
      valLog:res.data[i + 2]==0?0 :Math.log(res.data[i + 2])
    })
  }

  store.commit("SET_CHART_DATA", list);
  store.commit("SET_CHART_TYPE", true);

  store.commit('SET_LOADING', false);

}

const exportChartData= ()=>{
  const data = chartData.value;
    let txtOutput = "";

    for (let i = 0; i < data.length; i ++) {
        txtOutput += `${data[i].x1/100} ~ ${data[i].x2/100} : ${data[i].val}\n`;
    }

    // 创建一个Blob对象，指定内容类型为text/plain
    const blob = new Blob([txtOutput], { type: 'text/plain' });

    // 创建一个指向该Blob的URL
    const url = URL.createObjectURL(blob);

    // 创建一个临时的a标签用于下载
    const a = document.createElement('a');
    a.href = url;
    a.download = qualitTypeSelectData.value[cirterion.value].label+'.txt'; // 设置下载文件的名称
    document.body.appendChild(a); // 添加到文档中以允许点击
    a.click(); // 模拟点击以触发下载

    // 清理：移除临时创建的元素，并释放Blob对象的URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}
</script>

<style scoped lang="scss">
form {
  margin: 10px;
  font-size: 14px;
}

.container {
  padding: 6px 10px;
  border: 1px solid #ccc;
  position: relative;

  .title {
    position: absolute;
    top: -11px;
    left: 8px;
    background-color: #fff;
  }

  .line {
    display: flex;

    .left,
    .center {
      flex: 1;
    }
  }

  .right {
    flex: 2;
    display: flex;
  }

  .header .right {
    flex: 1;
  }

  .radio-left,
  .radio-right {
    flex: 1;
  }
}

.mesh-type,
.elements {
  margin-bottom: 24px;
}

.qualityType {
  display: flex;
  padding: 12px 8px;
}

.apply-btn {
  width: 90%;
  margin: 20px auto;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #adadad;
  background-color: #e1e1e1;
}
</style>
