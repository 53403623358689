<template>
  <a-float-button-group :style="{ top: '90px', height: '40px' }">
    <a-popover v-if="toolName=='CoolingsCut'" placement="left"  trigger="click">
      <template #content>
        <a-space wrap :style="{maxWidth:'130px',maxHeight:'300px'}">
          <a-tag v-for="(item, index) in list" :key="index" :bordered="false" closable @close.prevent  @close="handleClose(item)">
            {{["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable? 1 : 0] + item.x+"_"+item.y}}
          </a-tag>
        </a-space>
        {{ list.length==0?"no select":"" }}
      </template>
      <template #title>
        <a-flex :justify="'space-between'" :align="'center'">
        
          <span>BladeList</span>
          
          <a v-if="isOneType()" title="Select in batches and jump to ParameterAdjust" @click="goParameterAdjust">ParameterAdjust</a>
          <a-tooltip v-else title="There are different types in the list" :color="'#f50'">
            <a disabled>ParameterAdjust</a>
          </a-tooltip>
        </a-flex>
      </template>
      <a-float-button />
    </a-popover>

    
    <a-popover v-if="toolName=='ParameterAdjust'" placement="left"  trigger="click">
      <template #content>
        <a-space wrap :style="{maxWidth:'130px',maxHeight:'300px'}">
          <a-tag v-for="(item, index) in list" :key="index" :bordered="false" closable @close.prevent  @close="handleClose(item)">
            {{["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable? 1 : 0] + item.x+"_"+item.y}}
          </a-tag>
        </a-space>
        {{ list.length==0?"no select":"" }}
      </template>
      <template #title>
        <a-flex :justify="'space-between'" :align="'center'">
        
          <span>BladeList</span>
          
          <a v-if="isOneType()" title="Apply current adjust rules to all" @click="batchApply">BatchApply</a>
          <a-tooltip v-else title="There are different types in the list" :color="'#f50'">
            <a disabled>BatchApply</a>
          </a-tooltip>
        </a-flex>
      </template>
      <a-float-button />
    </a-popover>
  </a-float-button-group>
</template>
<script setup>
import { computed,defineProps,ref } from "vue";
import { useStore } from "vuex";
import model from "@/api/modules/model";
import { to } from "@/utils/utils";

import { Modal } from 'ant-design-vue';
const store = useStore();
const props = defineProps({
  generateMeshClick: Function,
});

const toolName = computed(() => store.state.menu.toolName);
const currentTypeMatrix=computed(() => store.state.control.currentTypeMatrix);
const selectedTreeKeys = computed(() => store.state.model.selectedTreeKeys);
const bableTipVisable= computed(() => selectedTreeKeys.value.includes("0-1") ||
    selectedTreeKeys.value.includes("0-1-0"));
const coolingsCutMergeBlade = computed(
  () => store.state.control.coolingsCutMergeBlade
);
const coolingsCutMergeBladeTip = computed(
  () => store.state.control.coolingsCutMergeBladeTip
);
const numFalg=ref(-1);
const selectModelItems = computed(() => store.state.control.selectModelItems);
const list = computed(() => {
  let list0 = bableTipVisable.value
    ? coolingsCutMergeBladeTip.value
    : coolingsCutMergeBlade.value;
  return list0.filter((n)=>{
      const name=["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable.value? 1 : 0] + n.x+"_"+n.y;
      return selectModelItems.value.filter(j=>{return j==name}).length==1;
    })
});
const isOneType=()=>{
  if(list.value.length==0||!currentTypeMatrix.value[bableTipVisable.value?"bladeTip":"blade"]?.length){
    return false;
  }
  
  let type=currentTypeMatrix.value[bableTipVisable.value?"bladeTip":"blade"][list.value[0].x][list.value[0].y]
  let falg=true;
  list.value.forEach(n=>{
    if(currentTypeMatrix.value[bableTipVisable.value?"bladeTip":"blade"][n.x][n.y]!=type){
      return falg=false;
    }
  })
  return falg;
}
const goParameterAdjust=async()=>{
  
  if(list.value.length==0){
    store.commit("SET_TOOL_NAME", 'ParameterAdjust');
    return 
  }
  const name=["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable.value? 1 : 0] + list.value[0].x+"_"+list.value[0].y;
  store.commit('SET_CURRENT_MODEL_NAME', name);
  await store.dispatch('selectModel', name)
  await store.dispatch('resetBlock');
  await store.dispatch('resetMesh');
  
  store.commit("SET_TOOL_NAME", 'ParameterAdjust');
}
const handleClose= (data) => {

store.commit(
      bableTipVisable.value
        ? "SET_COOLING_CUT_MERGE_BLADE_TIP"
        : "SET_COOLING_CUT_MERGE_BLADE",
      data
    );
}

async function batchApply(){
  const name=await store.dispatch('getCurrentModelName');
  if(name.indexOf("Cooling_Blade")==-1){
    return;
  }
  const [err] = await to(model.batchCoolingsMesh({
    name,
    names:list.value.map((item)=>{
      return ["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable.value? 1 : 0] + item.x+"_"+item.y;
    })
  }));
  
  if (err) {
    window.addLog.error(err);
    return;
  }
  Modal.confirm({
    title: 'Batch adjustment has been completed.Are you sure you need to generate mesh?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    async onOk() {
      try {
        const list1=list.value.map((item)=>{
          return ["Cooling_Blade_", "Cooling_BladeTip_"][bableTipVisable.value? 1 : 0] + item.x+"_"+item.y;
        });
        const currentName=await store.dispatch('getCurrentModelName');
  
        for(numFalg.value=0;numFalg.value<list1.length;numFalg.value++){
          if(list1[numFalg.value]!=currentName){
          store.commit('SET_CURRENT_MODEL_NAME', list1[numFalg.value]);
          await store.dispatch('selectModel', list1[numFalg.value])

          }
          await props.generateMeshClick()
        }
        numFalg.value=-1;
        store.commit('SET_CURRENT_MODEL_NAME', currentName);
          await store.dispatch('selectModel', currentName)
          await props.generateMeshClick()
      } catch (error) {
        console.log(error);
      }
     
      
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

</script>
