<template>
  <div class="menubar">
    <ul>
      <!-- 文件 -->
      <li>
        <a href="#">File</a>
        <ul class="submenu">
          <li>
            <a-upload
              :before-upload="beforeUpload"
              :fileList="[]"
              :customRequest="uploadFiles"
              :multiple="true"
            >
              <a-button type="text" href="#">Import</a-button>
            </a-upload>
          </li>

          <li>
            <div class="border"></div>
          </li>
          <li>
            <a-button
              :disabled="dataStatus==0"
              :class="{ disabled: dataStatus==0 }"
              @click="exportEdges()"
              type="text"
              href="#"
              >Export edges(Blocking)</a-button
            >
          </li>
          <li>
            <a-button
              :disabled="!meshStatus"
              :class="{ disabled: !meshStatus }"
              @click="exportModal(0)"
              type="text"
              href="#"
              >Export .msh (Fluent)</a-button
            >
          </li>
          <li>
            <a-button
              :disabled="!meshStatus"
              :class="{ disabled: !meshStatus }"
              @click="exportModal(1)"
              type="text"
              href="#"
              >Export .cfx5 (CFX)</a-button
            >
          </li>
          <li>
            <a-button
              :disabled="!meshStatus"
              :class="{ disabled: !meshStatus }"
              @click="exportModal(2)"
              type="text"
              href="#"
              >Export .k (LS-Dyna)</a-button
            >
          </li>

          <li>
            <div class="border"></div>
          </li>

          <li>
            <a-button
              :disabled="!getCutAndBlade()"
              :class="{ disabled: !getCutAndBlade() }"
              @click="exportAllModal(0)"
              type="text"
              href="#"
              >Export .msh (MultiModels)</a-button
            >
          </li>
          <li>
            <a-button
              :disabled="!getCutAndBlade()"
              :class="{ disabled: !getCutAndBlade() }"
              @click="exportAllModal(1)"
              type="text"
              href="#"
              >Export .cfx5 (MultiModels)</a-button
            >
          </li>

          <li>
            <div class="border"></div>
          </li>

          <li>
            <a-button @click="exportHisToTxt()" type="text" href="#">Export .his</a-button>
          </li>
          
          <li>

            
            <a-upload
              :before-upload="importHisToTxt"
              :fileList="[]"
              :customRequest="() => { }"
              :multiple="true"
            >
              <a-button type="text" href="#">
              Import .his</a-button>
            </a-upload>
          </li>
        </ul>
      </li>
      <!-- 编辑 -->
      <li>
        <a href="#">Edit</a>
        <ul class="submenu">
          <li>
            <a-button
              :disabled="historyCurrent == 0"
              :class="{ disabled: historyCurrent <= 1 }"
              @click="setCuttentHis(-1)"
              type="text"
              href="#"
              >Undo</a-button
            >
          </li>
          <li>
            <a-button
              :disabled="historyCurrent == historyList.length - 1"
              :class="{ disabled: historyCurrent == historyList.length - 1 }"
              @click="setCuttentHis(1)"
              type="text"
              href="#"
              >Redo</a-button
            >
          </li>
        </ul>
      </li>
      <li>
        <a href="#">Info</a>
        <ul class="submenu">
          <li><a href="#" @click="()=>logElementInfo(1)">Element info</a></li>
          <li><a href="#" @click="()=>logElementInfo(0)">Node info</a></li>
        </ul>
      </li>
      <!-- 帮助 -->
      <li>
        <a href="#">Help</a>
        <ul class="submenu">
          <li><a href="#" @click="showSet">Set</a></li>
        </ul>
      </li>
    </ul>
    <ul class="Copyright" @click="copy">
      Copyright please contact: Penghao.Duan@hotmail.com
    </ul>
    <ul v-if="user?.username" class="user">
      <li>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            {{ user?.username }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="logout()">Logout</a>
              </a-menu-item>
              <!-- <a-menu-item>
                <a href="javascript:;" @click="openTeam()">Team</a>
              </a-menu-item> -->
            </a-menu>
          </template>
        </a-dropdown>
      </li>
    </ul>
    <!-- <Team v-model:visible="teamVisible" /> -->
    <Set v-model:visible="setVisible" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, createVNode } from "vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import model from "@/api/modules/model";
import userApi from "@/api/modules/user";
import { to } from "@/utils/utils";
import Set from "../help/Set.vue";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { saveAs } from "file-saver";

// import Team from "./user/Team.vue";
const store = useStore();
const router = useRouter()
const dataStatus = computed(() => store.state.model.dataStatus);
const meshStatus = computed(() => store.state.model.meshStatus);
const historyList = computed(() => store.state.control.historyList);
const historyCurrent = computed(() => store.state.control.historyCurrent);
const loading = computed(() => store.state.control.loading);
const user = computed(() => store.state.control.user);

const selectModelItems = computed(() => store.state.control.selectModelItems);
const currentModelName = computed(() => store.state.control.currentModelName);
const setVisible = ref(false);
// const teamVisible = ref(false);
function showSet() {
  setVisible.value = !setVisible.value;
}
const getCutAndBlade = () => {
  return (
    currentModelName.value.indexOf("cut") != -1 &&
    selectModelItems.value.some((n) => {
      return n.indexOf("Blade") != -1;
    })
  );
};
const fileList = ref([]);
const uploadFalg = ref(false);
const beforeUpload = (file) => {
  if (uploadFalg.value == false) {
    fileList.value = [];
  }
  fileList.value.push(file);
  uploadFalg.value = true;
  // return false; // 阻止自动上传
};
const resetHis=async ()=>{
  let id = 0;
        store.commit("SET_HISTORY_FALG", true);
        const num = localStorage.historyCurrent
          ? Number(localStorage.historyCurrent)
          : 0;
        while (id < num) {
          await getLoading();
          store.commit("SET_LOADING", true);
          store.commit("SET_HISTORY_CURRENT", 1);
          await getLoading();
          id++;
        }
        setTimeout(() => {
          store.commit("SET_HISTORY_FALG", false);
        });
}
const importHisToTxt = (file) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const contents = e.target.result;
    try {
      
      const data = JSON.parse(contents);
     if(!data?.historyCurrent||!data?.historyList?.length||data?.historyCurrent<0||data?.historyCurrent>data?.historyList?.length){
      throw new Error('Data Invalid');
     }
      localStorage.historyList = JSON.stringify(data.historyList);
     localStorage.historyCurrent = data.historyCurrent;
     resetHis();
    } catch (error) {
      console.error(error)
      message.error('Data Invalid')
    }
  };

  reader.readAsText(file);
};
const setCuttentHis = (i) => {
  // -1: undo all, 0: undo last, 1: redo last. 创

  store.commit("SET_HISTORY_FALG", true);
  store.commit("SET_HISTORY_CURRENT", i);
  setTimeout(() => {
    store.commit("SET_HISTORY_FALG", false);
  });
};

const exportHisToTxt = () => {
  const jsonData = {
    historyList: historyList.value,
    historyCurrent: historyCurrent.value,
    fileType: "his",
  };
  const jsonStr = JSON.stringify(jsonData, null, 2); // 使用两个空格进行缩进，保持可读性

  const blob = new Blob([jsonStr], { type: "text/plain;charset=utf-8" });
  saveAs(blob, "data.his");
};

const uploadFiles = async () => {
  if (!uploadFalg.value) return;
  uploadFalg.value = false;

  store.commit("SET_LOADING", true);
  const formData = new FormData();
  fileList.value.forEach((file) => {
    formData.append("files", file, file.name);
  });
  const [err, res0] = await to(model.readGeoFiles(formData));

  if (err) {
    window.addLog.error("upload error");
    return;
  }

  if (res0.data) {
    window.addLog.info("upload successfully");
  } else {
    window.addLog.info("upload error");
    return;
  }
  store.commit("SET_DATA_STATUS", 0);
  const [err2] = await to(model.generateBlocks());
  if (err2) {
    window.addLog.error("Network error, please refresh");
    return;
  }
  await store.dispatch("resetModelItems");
  await store.dispatch("resetBlock");
  await store.dispatch("resetMesh");

  const res = await store.dispatch("getModelTypeToBeCut");
  console.log(res);

  store.commit("SET_MODEL_IMPORT_TYPE", true);
  store.commit("SET_TIP_MODE", res == 1 ? "flat tip" : "squealer tip");
  store.commit("SET_TOOL_NAME", "CreateModel");
  store.commit("SET_LOADING", false);
};
function isJSON(str) {
    if (typeof str == 'string') {
        try {
            const strJson=JSON.parse(str);
            return strJson;
        } catch(e) {
            return false;
        }
    }
    console.log('It is not a string!')  
            return false;  
}
const getLoginStatus=()=>{
  if(isJSON(localStorage.user)==false){
    Modal.confirm({
    title: 'If you need to download resource files, please log in and use',
    icon: createVNode(ExclamationCircleOutlined),
    okText: 'Ok',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      router.push('/sign-in');
    }
  });
    return false;
  }else return true;
}
const exportModal = async (type) => {
  
  if(getLoginStatus()==false) return;
  const hide = message.loading("Generating model file...", 0);
  const [err, res] = await to(model.exportFiles(type));

  if (err) {
    window.addLog.error(err);
    return;
  }
  hide();
  window.open("/api" + res.data);
};
const exportAllModal = async (type) => {
  if(getLoginStatus()==false) return;

  const hide = message.loading("Generating model file...", 0);
  const [err, res] = await to(
    model.exportFilesMultiModels({ type, outputFile: "cut" })
  );

  if (err) {
    window.addLog.error(err);
    return;
  }
  hide();
  window.open("/api" + res.data);
};
const blockEdges = computed(() => store.state.model.lines);
const IJKLines = computed(() => store.state.control.IJKLines);
const exportEdges= ()=>{
  
  if(getLoginStatus()==false) return;
  const ijkEdges=IJKLines.value.length==0?blockEdges.value:IJKLines.value.map(item=>{ return blockEdges.value[item]});
  const flattenedPoints = ijkEdges.flat(Infinity);
    let txtOutput = "";

    for (let i = 0; i < flattenedPoints.length; i += 3) {
        txtOutput += `${flattenedPoints[i]} ${flattenedPoints[i + 1]} ${flattenedPoints[i + 2]}\n`;
    }

    // 创建一个Blob对象，指定内容类型为text/plain
    const blob = new Blob([txtOutput], { type: 'text/plain' });

    // 创建一个指向该Blob的URL
    const url = URL.createObjectURL(blob);

    // 创建一个临时的a标签用于下载
    const a = document.createElement('a');
    a.href = url;
    a.download = 'points.txt'; // 设置下载文件的名称
    document.body.appendChild(a); // 添加到文档中以允许点击
    a.click(); // 模拟点击以触发下载

    // 清理：移除临时创建的元素，并释放Blob对象的URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

const logout = () => {
  Modal.confirm({
    title: "Are you sure you want to log out?",
    icon: createVNode(ExclamationCircleOutlined),
    okText: "Yes",
    cancelText: "No",
    async onOk() {
      await to(userApi.signOut());
      localStorage.clear();
      
      router.push('/sign-in');
    },
  });
};
const getLoading = () => {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (loading.value === false) {
        clearInterval(timer);
        resolve();
      }
    }, 200); // 1/5 second delay for smooth animation
  });
};
const logElementInfo=async(type)=>{

  const [err, res] = await to(model.getElementsInfo());

  if (err) {
    window.addLog.error(err);
    return;
  }
  
  window.addLog.info(`Number of ${['Nodes','Elements'][type]}:${res.data[type]}`);
}
onMounted(() => {
});
const copy=()=>{
      var text = 'Penghao.Duan@hotmail.com';
      var tempInput = document.createElement("input");  // 创建一个临时的input元素
    tempInput.setAttribute("value", text);  // 将需要复制的文字设置为input的value属性
    document.body.appendChild(tempInput);  // 将input元素添加到页面中
    tempInput.select();  // 选中input元素
    document.execCommand("copy");  // 执行复制操作
    document.body.removeChild(tempInput);  // 将临时的input元素从页面中移除
      message.success("复制成功");
    }
// const openTeam=()=>{
//   teamVisible.value = true;
// }
</script>

<style type="scss" scoped>
::v-deep .ant-upload-select-text {
  width: 100%;
}
/* [1] 菜单栏 */
.menubar {
  width: 100%;
  height: 25px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
}

.menubar ul {
  list-style: none;
}

.menubar li {
  position: relative;
  float: left;
  width: auto;
}

.menubar li > a,
.menubar li > span {
  text-align: left;
  display: block;
  background-color: var(--background-color);
  color: #888;
  font-size: 12px;
  line-height: 24px;
  min-width: 45px;
  padding: 0 10px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.menubar li > span {
  padding: 0;
}

.submenu li > span a {
  width: 100%;
  padding: 0 10px !important;
  color: #888;
  padding: 0;
}

::v-deep .ant-upload {
  display: block !important;
}

/* .menubar li:hover a {
  background-color: var(--secondary-color);
} */

.menubar .submenu {
  min-width: 150px;
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;

  /* text-align: center; */
  opacity: 0;
  transition: all 0.5s;
  /* border: 1px solid var(--border-color); */
  z-index: 20;
}

.menubar .submenu li {
  float: none;
  color: #666;
}

.menubar .submenu li:last-child {
  border-bottom: none;
}

.menubar .submenu a {
  min-width: auto;
  line-height: 32px;
  text-align: left;
  /* padding: 5px 10px; */
}

.menubar li:hover ul.submenu {
  opacity: 1;
  height: auto;
}

.menubar .submenu li:hover a {
  background-color: var(--primary-color);
  color: white;
}

.menubar .submenu li .disabled {
  background-color: var(--background-color);
  color: #bbb;
}

.menubar .submenu li:hover .disabled {
  background-color: var(--background-color);
  color: #bbb;
}

.border {
  border: 1px solid #ccc;
  width: 100%;
  height: 1px;
}

.user {
  float: right;
  font-size: 14px;
  line-height: 25px;
}
.Copyright{
  float: right;
  font-size: 14px;
  line-height: 25px;
  margin-right: 10px;;

}
</style>
