<template>
  <div class="model-tree">
    <div class="tool-title">
      <div v-if="selectModelItems.length == 0">
        ModelTree
      </div>
      <a-dropdown v-else-if="!coolingsCutCheck">
        <div>
          {{ selectName }}
          <DownOutlined />
        </div>
        <template #overlay>
          <a-menu @click="handleChange">
            <a-menu-item v-for="(item, index) in selectModelItems" :key="index">
              <a href="javascript:;">{{ item }}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div v-else>
        Local cooling hole matrix area
      </div>
      <a-switch v-if="toolName == 'CoolingsCut'" :checked="coolingsCutCheck" @change="setCoolingsCutCheck"
        :disabled="!coolingsCutStatus" style="margin: 6px;" />

    </div>
    <div class="atree">
      <a-tree v-if="toolName == 'CoolingsCut' && coolingsCutCheck" selectable v-model:selectedKeys="selectedKeys[1]"
        :tree-data=" block3dInput.type>0?coolingsCutTreeData:[coolingsCutTreeData[0]]" defaultExpandAll @select="handleCheck" />
      <a-tree v-else v-model:checkedKeys="selectedKeys[0]" checkable :tree-data="treeData" defaultExpandAll
        @check="handleCheck" />
    </div>

  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

import { DownOutlined } from '@ant-design/icons-vue';
const store = useStore();
const toolName = computed(() => store.state.menu.toolName);
const dataStatus = computed(() => store.state.model.dataStatus);
const meshStatus = computed(() => store.state.model.meshStatus);
const coolingsCutCheck = computed(() => store.state.model.coolingsCutCheck);
const coolingsCutStatus = computed(() => store.state.model.coolingsCutStatus);
const block3dInput = computed(() => store.state.model.block3dInput);
const bladeType = computed(() => store.state.menu.bladeType);
const selectModelItems = computed(() => store.state.control.selectModelItems);
const currentModelName = computed(() => store.state.control.currentModelName);
// const coolingsCutSelectedTreeKey = ref([]);
const selectName = ref('ModelTree');
const handleChange = async (e) => {
  selectName.value = selectModelItems.value[e.key];
  store.commit('SET_CURRENT_MODEL_NAME', selectName.value);
  await store.dispatch('selectModel', selectName.value)
  await store.dispatch('resetBlock')
  await store.dispatch('resetMesh')
}
watch(currentModelName, (newVal) => {
  selectName.value = newVal;
})
watch(selectModelItems, (newVal) => {
  if (newVal.length == 1){
    selectName.value = selectModelItems.value[0];
    
    store.commit('SET_CURRENT_MODEL_NAME', selectName.value);
  }
  else {
    store.dispatch('getCurrentModelName').then(data => {
      selectName.value = data
      
  store.commit('SET_CURRENT_MODEL_NAME', selectName.value);
    })
  }
})

const treeData = computed(() => [
  {
    title: "Geometry",
    key: "0-0",
    disabled: store.state.model.dataStatus<=0,
    children: [
      {
        title: "Curves",
        key: "0-0-0",
        disabled: store.state.model.dataStatus<=0,
      },
    ],
  },
  {
    title: "Blocking",
    key: "1-0",
    disabled: store.state.model.dataStatus<=0,
    children: [
      {
        title: "Edges",
        key: "1-0-0",
        disabled: store.state.model.dataStatus<=0,
      },
      {
        title: "Faces",
        key: "1-0-1",
        disabled: store.state.model.dataStatus<=0,
      },
      {
        title: "Blocks",
        key: "1-0-2",
        disabled: store.state.model.dataStatus<=0,
      },
    ],
  },
  {
    title: "Mesh",
    key: "2-0",
    disabled: !store.state.model.meshStatus,
    children: [
      {
        title: "Lines",
        key: "2-0-0",
        disabled: !store.state.model.meshStatus,
      },
      {
        title: "Shells",
        key: "2-0-1",
        disabled: !store.state.model.meshStatus,
      },
      {
        title: "Volumes",
        key: "2-0-2",
        disabled: !store.state.model.meshStatus,
      },
    ],
  },
  {
    title: "Group",
    disabled: true,
    key: "3-0",
  },
])
const selectedKeys = ref([["0-0", "0-0-0"], ["0-0-0", "0-0-1"]]);
const coolingsCutTreeData = [
  {
    title: "Blade",
    key: "0-0",
    children: [
      {
        title: "Block",
        key: "0-0-0",
      },
      {
        title: "Mesh",
        key: "0-0-1",
      }
    ]
  },
 {
    title: "BladeTip",
    key: "0-1",
    children: [
      {
        title: "Block",
        key: "0-1-0",
      },
      {
        title: "Mesh",
        key: "0-1-1",
      }
    ]
  },
]

watch(meshStatus, (newVal) => {
  if (newVal==1) {
    if (!selectedKeys.value[0].includes('2-0-0'))
      selectedKeys.value[0].push('2-0-0')
    if (!selectedKeys.value[0].includes('2-0-1'))
      selectedKeys.value[0].push('2-0-1')
    if (!selectedKeys.value[0].includes('2-0-2'))
      selectedKeys.value[0].push('2-0-2')
    handleCheck(selectedKeys.value[0])
  }
});
watch(dataStatus, (newVal) => {
  if (newVal==1) {
    if (!selectedKeys.value[0].includes('1-0-0'))
      selectedKeys.value[0].push('1-0-0')
    if (!selectedKeys.value[0].includes('1-0-1'))
      selectedKeys.value[0].push('1-0-1')
    if (!selectedKeys.value[0].includes('1-0-2'))
      selectedKeys.value[0].push('1-0-2')
    handleCheck(selectedKeys.value[0])
  }
})
watch(coolingsCutCheck,(newVal)=>{

  handleCheck(selectedKeys.value[newVal?1:0])
})
watch(toolName, (newVal) => {
  if (newVal == 'ParameterAdjust') {
    selectedKeys.value[0] = ["1-0-0"];
    handleCheck(selectedKeys.value[0])
  }
  else if (newVal == 'IjkControl') {
    selectedKeys.value[0] = ["1-0-0", "1-0-1", "1-0-2"];
    handleCheck(selectedKeys.value[0])
  }
  else if (newVal == 'ScanPlane') {
    selectedKeys.value[0] = ["2-0-0"];
    handleCheck(selectedKeys.value[0])
  }
})
watch(bladeType, (newVal) => {
  if (newVal == -1) return;
  let val = newVal ? ["0-1-0"] : ["0-0-0"];
  selectedKeys.value[1] = val;
  handleCheck(val)
})
const setCoolingsCutCheck = (checked) => {
  store.commit("SET_COOLINGS_CUT_CHECK", checked);
}
function handleCheck(checkedKeys) {
  store.commit("SET_TREE_KEYS", checkedKeys);
}
</script>
<style scoped>
.tool-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: var(--text-color-gray);
  border-bottom: 1px solid var(--border-color);
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding-left: 7px;
  display: flex;
  justify-content: space-between;
}

.atree {
  height: calc(100% - 35px);
  overflow-x: hidden;
  overflow-y: overlay;
}
</style>