<template>
  <div class="tool-title">
    {{ titleName }}
  </div>
</template>

<script setup>
import { toRefs, defineProps } from "vue";
const props = defineProps({
  titleName: String,
});
const { titleName } = toRefs(props);
</script>

<style scoped>
/* [1] 菜单栏 */
.tool-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: var(--text-color-gray);
  border-bottom: 1px solid var(--border-color);
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding-left: 7px;
}
</style>
