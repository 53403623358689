<template>
  <div class="messag-bar" id="messagBar" ref="barDiv">
    <Split v-model="split" :max="(chartType ? 120 / divWidth : 14 / divWidth)">
      <template #left>
        <div class="logList" id="logList">
          <div v-for="(item, index) in logList" :class="item.type" :key="index">
            {{ item.time }} [{{ item.type }}] {{ item.mes }}
          </div>
          <div class="liubai"></div>
        </div>
      </template>
      <template #right>
        <div
          :style="{ display: chartType && toolName == 'CheckMeshQuality' ? 'block' : 'none', width: '100%', height: '100%', padding: '10px' }">

          <!-- <a-spin :spinning="loading"> -->
          <div id="chartContainer"></div>
          <!-- </a-spin> -->
          <div @click="closeChart" class="chartClose">
            <close-square-filled />
          </div>
        </div>
        <div
          :style="{ display: toolName == 'RimSealing' ? 'block' : 'none', width: '100%', height: '100%', padding: '10px' }">
          <canvas id="rimSealing"></canvas>
        </div>
        <div :style="{ display:  toolName == 'CoolingsCut' ? 'block' : 'none', minWidth: '100%', height: '100%', padding: '10px',overflow: 'overlay' }">
          <BladeControl/>
      </div>
      </template>
    </Split>
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import BladeControl from "./messageMenu/BladeControl.vue";
import {
  CloseSquareFilled
} from '@ant-design/icons-vue';
import { Column } from '@antv/g2plot';
import * as d3 from 'd3-scale-chromatic';
import model from "@/api/modules/model";
import { to } from "@/utils/utils";
import { Decimal } from 'decimal.js';
const columnPlot = ref(undefined);
const PI = Decimal.acos(-1);

const split = ref(0.01);
const divWidth = ref(1);
const barDiv = ref(null);
const canvas = ref(null);
// 定义起始点和初始角度
let x, y, ctx, angle = new Decimal(0);

// 定义 ResizeObserver 对象
const canvasResizeObserver = new ResizeObserver(entries => {
  for (const entry of entries) {
    if (entry.target === canvas.value&&toolName.value=='RimSealing') {

      resetCanvas();
    }
  }
});
onMounted(() => {
  let width = document.getElementById('messagBar').clientWidth
  divWidth.value = width;
  split.value = (width - 14) / width


  const resizeObserver = new ResizeObserver(() => {

    let width = document.getElementById('messagBar').clientWidth
    divWidth.value = width;
    if ((1 - split.value) * width < 14) {
      split.value = (width - 14) / width
    }

  });
  resizeObserver.observe(barDiv.value);


  // 获取canvas元素和2d上下文对象
  canvas.value = document.getElementById('rimSealing');
  ctx = document.getElementById('rimSealing').getContext('2d');


  // 监听 canvas 元素
  canvasResizeObserver.observe(canvas.value);

})

const lenTimes = ref(100)
// 定义绘制线条的函数
function drawLine(length, angleInDegrees, text, angleText) {
  // 将角度从度转换为弧度
  const angleInRadians = angleInDegrees * PI / 180;

  angle = new Decimal(angle).plus(angleInRadians);
  // 计算线条的终点坐标
  const newX = new Decimal(x).plus(new Decimal(length).times(Decimal.cos(angle)));
  const newY = new Decimal(y).plus(new Decimal(length).times(Decimal.sin(angle)));

  const angleTextX = new Decimal(x).plus(10 * Decimal.cos((angle) / 2));
  const angleTextY = new Decimal(y).plus(10 * Decimal.sin((angle) / 2));
  // 绘制线条
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(newX, newY);
  ctx.stroke();
  // 标记起点和终点
  ctx.fillStyle = 'red';
  ctx.beginPath();
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.beginPath();
  ctx.arc(newX, newY, 3, 0, 2 * Math.PI);
  ctx.fill();
  // 在线条上标出参数名字和长度
  const textX = (new Decimal(x).plus(newX)) / 2;
  const textY = (new Decimal(y).plus(newY)) / 2;
  ctx.font = '12px Arial';
  ctx.textAlign = 'center';
  ctx.fillStyle = 'green';
  ctx.fillText(`${text}`, textX, textY);
  // 在夹角上标出参数名字和角度
  if (angleInDegrees !== 0) {
  ctx.fillStyle = 'blue';
    ctx.fillText(angleText, angleTextX, angleTextY);
  }
  // 更新起始点和角度
  x = new Decimal(newX);
  y = new Decimal(newY);
}
const store = useStore();
const chartData = computed(() => store.state.menu.chartData);
const chartType = computed(() => store.state.menu.chartType);
const logList = computed(() => store.state.menu.logList);
const chartModelIdList = computed(() => store.state.control.chartModelId);
const chartColors = computed(() => store.state.control.chartColors);
const rimSealingData = computed(() => store.state.control.rimSealingData);

const toolName = computed(() => store.state.menu.toolName);
const chartModelId = ref(-1);
watch(toolName, (newVal,oldVal) => {
  if (newVal == 'RimSealing') {
    split.value = 0.5;
  }
  if(oldVal=='RimSealing'){
    split.value = 0.99;
  }
})
//计算垂线
function distanceToLine(x0, y0, x1, y1, x2, y2) {
  const dx = new Decimal(x2).minus(x1);
  const dy = new Decimal(y2).minus(y1);
  const dot = new Decimal(dx).times(new Decimal(x0).minus(x1)).plus(new Decimal(dy).times(new Decimal(y0).minus(y1)));
  const lenSq = new Decimal(dx).times(dx).plus(new Decimal(dy).times(dy));
  const param = dot.div(lenSq);
  let xx, yy;

  if (param.lessThan(0)) {
    xx = x1;
    yy = y1;
  } else if (param.greaterThan(1)) {
    xx = x2;
    yy = y2;
  } else {
    xx = new Decimal(x1).plus(param.times(dx));
    yy = new Decimal(y1).plus(param.times(dy));
  }

  const dx2 = new Decimal(x0).minus(xx);
  const dy2 = new Decimal(y0).minus(yy);

  return Decimal.sqrt(new Decimal(dx2).times(dx2).plus(new Decimal(dy2).times(dy2)));
}
//计算夹角
function getAngleBetweenLines(B, D, F) {
  const BD = { x: new Decimal(D.x).minus(B.x), y: new Decimal(D.y).minus(B.y) }; // 计算 BD 向量
  const DF = { x: new Decimal(F.x).minus(D.x), y: new Decimal(F.y).minus(D.y) }; // 计算 DF 向量

  const dotProduct = new Decimal(BD.x).times(DF.x).plus(new Decimal(BD.y).times(DF.y)); // 计算 BD 和 DF 向量的点积
  const magBD = Decimal.sqrt(new Decimal(BD.x).times(BD.x).plus(new Decimal(BD.y).times(BD.y))); // 计算 BD 向量的模长
  const magDF = Decimal.sqrt(new Decimal(DF.x).times(DF.x).plus(new Decimal(DF.y).times(DF.y))); // 计算 DF 向量的模长

  const cosTheta = dotProduct.div(magBD.times(magDF)); // 计算 BD 和 DF 向量夹角的余弦值
  const angleInRadians = Decimal.acos(cosTheta); // 将余弦值转换为弧度值
  const angleInDegrees = angleInRadians.times(180).div(PI); // 将弧度值转换为角度值

  return angleInDegrees; // 返回角度值
}

function calculatePointC(ax, ay, bx, by, cLength) {
  // 计算向量 AB
  const abX = bx - ax;
  const abY = by - ay;

  // 计算向量 AC（将向量 AB 旋转 90 度并缩放）
  const acX = abX * (cLength / Math.sqrt(abX**2 + abY**2));
  const acY = abY * (cLength / Math.sqrt(abX**2 + abY**2));

  // 计算点 C 的坐标
  const cx = bx + acX;
  const cy = by + acY;

  return [cx, cy];
}
function calculateDPoint(A, B, angle, BDLength) {

  if(angle%180==0){
    const D=calculatePointC(A.x,A.y,B.x,B.y,angle%360==0?-BDLength:BDLength);
    return {x:D[0],y:D[1]}
  }
  const angleInRadians=new Decimal(angle).times(PI).div(180)
  const AB = Decimal.sqrt(
    Decimal.pow(new Decimal(B.x).minus(A.x), 2).plus(Decimal.pow(new Decimal(B.y).minus(A.y), 2))
  );
  const cosAngle = Decimal.cos(angleInRadians);
  const sinAngle = Decimal.sin(angleInRadians);

  const BDx = new Decimal(BDLength).times(cosAngle);
  const BDy = new Decimal(BDLength).times(sinAngle);

  const ABx = Decimal.div(new Decimal(B.x).minus(A.x), AB);
  const ABy = Decimal.div(new Decimal(B.y).minus(A.y), AB);
  const Dx = new Decimal(B.x)
    .plus(new Decimal(BDx).times(ABx))
    .minus(new Decimal(BDy).times(ABy))
    .toNumber();
  const Dy = new Decimal(B.y)
    .plus(new Decimal(BDx).times(ABy))
    .plus(new Decimal(BDy).times(ABx))
    .toNumber();

  return { x: Dx, y: Dy };
}
const resetCanvas = () => {

  angle = 0;
  const pos = rimSealingData.value.pos == 0 ? 1 : -1;
  // 清空画布
  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
  
  canvas.value.width = canvas.value.clientWidth;
  canvas.value.height = canvas.value.clientHeight;
  x = 0;
  y = pos > 0 ? 0 : canvas.value.height ;
  const C1=calculateDPoint({x:0,y:0},{x:rimSealingData.value.prams[0]||1,y:0},rimSealingData.value.prams[5],rimSealingData.value.prams[2])
  const E1=calculateDPoint({x:rimSealingData.value.prams[0]||1,y:0},C1,rimSealingData.value.prams[7],rimSealingData.value.prams[3]);

  let maxX=new Decimal(rimSealingData.value.prams[0]).times(2).plus(rimSealingData.value.prams[1]).toNumber();
  let maxY=E1.y;
  
const widDiv=new Decimal(canvas.value.width-40).div(maxX).abs();
const heiDiv=new Decimal(canvas.value.height-40).div(maxY).abs();
lenTimes.value=Decimal.min(widDiv,heiDiv).toNumber()
const startX=(canvas.value.width-maxX*lenTimes.value)/2;
const startY=(canvas.value.height-maxY*lenTimes.value)/2;
ctx.translate(startX,pos>0?startY:-startY);
  const data = {
    'Δx': rimSealingData.value.prams[0] * lenTimes.value,
    'd': rimSealingData.value.prams[1] * lenTimes.value,
    'L1': rimSealingData.value.prams[2] * lenTimes.value,
    'L2': rimSealingData.value.prams[3] * lenTimes.value,
    'L3': rimSealingData.value.prams[4] * lenTimes.value,
    'θ1': rimSealingData.value.prams[5],
    'θ2': rimSealingData.value.prams[6],
    'θ3': rimSealingData.value.prams[7]
  }
  const BDAngle = data['θ2'] * PI / 180;
  const B = {
    x: new Decimal(x).plus(data['Δx']).plus(data['d']),
    y: y
  }

  const D = {
    x: new Decimal(B.x).plus(new Decimal(data['L3']).times(Decimal.cos(BDAngle * pos))),
    y: new Decimal(B.y).plus(new Decimal(data['L3']).times(Decimal.sin(BDAngle * pos)))
  }
  // 调用绘制线条的函数，可以多次调用绘制多个线条
  drawLine(data['Δx'], 0, 'Δx', '');


  drawLine(data['L1'], (180 - data['θ1']) * pos, 'L1', 'θ1');
  const C = { x, y };
  drawLine(data['L2'], (180 + data['θ3']) * pos, 'L2', 'θ3');
  const E = { x, y };
  const L5 = distanceToLine(D.x, D.y, C.x, C.y, E.x, E.y)

  drawLine(L5, -90 * pos, '', '');//画到F
  const F = { x, y };
  const L4 = Decimal.sqrt(Decimal.pow(new Decimal(F.x).minus(D.x), 2).plus(Decimal.pow(new Decimal(F.y).minus(D.y), 2)))
  drawLine(L4, -90 * pos, 'L4', '');//画到D

  const θ4 = getAngleBetweenLines(B, D, F);
  drawLine(data.L3, θ4 * pos, 'L3', 'θ4');//画到B
  const B2 = { x, y };
  drawLine(data['Δx'], (180 - data['θ2']) * pos, '', 'θ2');//画完
  const end= { x, y };


  if(B.x - B2.x > 0.000001 || B.y - B2.y > 0.000001||end.y-B.y> 0.000001){
    window.addLog.error('不合格参数');
    store.commit('SET_RIM_SEALING_COMPUTER_DARA', null);
    return;
  }
  else {
    const prams = rimSealingData.value.prams;
    store.commit('SET_RIM_SEALING_COMPUTER_DARA', [
      prams[0], prams[1], prams[2], prams[3], prams[4], new Decimal(L4).div(lenTimes.value).toFixed(3), prams[5], prams[6], prams[7], new Decimal(180).minus(θ4).toFixed(3)
    ]);
  }
}
watch(rimSealingData, (newVal) => {

  if (newVal) {
    resetCanvas()
  }
})
// const loading = computed(() => store.state.control.loading);
const loadChart = (data) => {
  console.log(data);
  const colorScale = d3.interpolateSpectral;
  const NUM_COLORS = data.length + 2;
  const colors = [];
  for (let i = 1; i < NUM_COLORS - 1; i++) {
    const color = colorScale(i / (NUM_COLORS - 1)); // 计算当前位置对应的颜色值
    colors.push({
      data: data[i - 1],
      color
    });
  }

  store.commit('SET_CHART_COLORS', colors)
  let list = data.map(() => {
    return {
      visible: false,
      getData: false
    }
  })
  
  let ticksList = [...data.map(n => { return n.x1 }), data[data.length - 1].x2];
  store.commit("SET_CHART_MODEL_ID", list);
  if (columnPlot.value != undefined) {
    columnPlot.value.changeData(data)
    columnPlot.value.update({
      xAxis: {
      type: 'linear',
      min: 0,
      max: data[data.length - 1].x + 1,
      tickInterval: 2,
      nice: false,
      label: {
        formatter: (text, item, index) => {
          return ticksList[index] / 100;
        }
      }
    },
      colorField: 'x', // 部分图表使用 seriesField
      color: (item) => {
        return colors[(item.x - 1) / 2].color;
      }
    })
    return
  }

  split.value = 0.5;
  // 创建图表


  columnPlot.value = new Column('chartContainer', {
    data,
    xField: 'x',
    yField: 'valLog',
    height: 180,
    columnWidthRatio: 0.97,
    columnStyle: () => ({
      stroke: '#000000',
      // stroke: colors[(x - 1) / 2],
      lineWidth: 1
    }),
    label: false,
    colorField: 'x',
    color: (item) => {
      return colors[(item.x - 1) / 2].color;
    },
    xAxis: {
      type: 'linear',
      min: 0,
      max: data[data.length - 1].x + 1,
      tickInterval: 2,
      nice: false,
      label: {
        formatter: (text, item, index) => {
          return ticksList[index] / 100;
        }
      }
    },
    tooltip: {
      fields: ['val'],
      showTitle: false,
      formatter: (datum) => {
        return { name: 'value', value: datum.val };
      },
    }
  });

  columnPlot.value.render();
  columnPlot.value.on('element:click', (...args) => {

    const pieData = (args[0].data?.data.x - 1) / 2
    getMeshQualityHistogramElementData(pieData)
    if (chartModelId.value == pieData) {
      updateChart(pieData)
    } else {
      chartModelId.value = pieData;
    }
  });
}
const getMeshQualityHistogramElementData = async (index) => {
  if (chartModelIdList.value[index].getData) {

    store.commit("SET_CHART_MODEL_ID_GET", index);
    return;
  }

  window.addLog.info('get chart cube')
  store.commit('SET_LOADING', true);
  const [err, res] = await to(
    model.getMeshQualityHistogramElementData({ index })
  );

  if (err) {
    window.addLog.error('Network error, please refresh');

    store.commit('SET_LOADING', false);
    return;
  }
  store.commit("SET_CHART_MODEL_ID_GET", index);
  store.commit("SET_CHART_MODEL_DATA", { index, data: res.data, color: chartColors.value[index].color });
  store.commit("SET_TREE_KEYS", ["2-0-0"]);
  store.commit('SET_LOADING', false);

}
const updateChart = (newVal) => {
  if (!columnPlot.value) return;

  if (chartModelIdList.value[newVal].visible) {
    columnPlot.value.removeAnnotations([{ id: newVal + "chartModel" }]);
    store.commit("SET_CHART_MODEL_ID_VIS", newVal);
    return;
  }

  columnPlot.value.addAnnotations(
    [
      {
        id: newVal + "chartModel",
        type: 'region',
        start: (xScale) => {
          if (newVal < 0)
            return false;
          const ratio = xScale.ticks ? 1 / xScale.ticks.length : 1;
          const x = xScale.scale(newVal * 2 + 1) - ratio / 2;
          return [`${x * 100}%`, '0%'];
        },
        end: (xScale) => {
          if (newVal < 0)
            return false;
          const ratio = xScale.ticks ? 1 / xScale.ticks.length : 1;
          const x = xScale.scale(newVal * 2 + 1) + ratio / 2;
          return [`${x * 100}%`, '100%'];
        },
      }
    ],
    columnPlot.value.chart.views[0]
  );
  store.commit("SET_CHART_MODEL_ID_VIS", newVal);
}
watch(chartModelId, (newVal) => {
  updateChart(newVal)
})
const closeChart = () => {
  store.commit("SET_CHART_TYPE", false);
}
watch(chartType, (val) => {
  chartType.value = val ? 0.5 : 1
})
watch(chartData, (val) => {
  loadChart(val)
});

const bladeModalProps = computed(() => store.state.control.bladeModalProps);
watch(bladeModalProps,(newVal)=>{
  if(newVal.formType=='bar')
    split.value=newVal.bladeVisible?0.5:0.99;
}, { deep: true, immediate: true })
const log = (data) => {
  store.commit('SET_LOG_LIST', data)
  setTimeout(() => {
    let div = document.getElementById('logList');
    div.scrollTop = div.scrollHeight
  });
}
const getCurrentTime = () => {
  let date = new Date();
  let hh = date.getHours();
  let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return hh + ':' + mf + ':' + ss;
}
window.addLog = {
  info: (mes) => log({ type: 'INFO', mes, time: getCurrentTime() }),
  error: (mes) => log({ type: 'ERROR', mes, time: getCurrentTime() }),
  debug: (mes) => log({ type: 'DEBUG', mes, time: getCurrentTime() }),
}
</script>
<style scoped>
.messag-bar {
  width: 100%;
  height: 100%;
  margin-top: 6px;
  margin-left: 6px;
  position: relative;
  border-top: 1px solid #c8c8c8;
}

.chartClose {
  position: absolute;
  left: 5px;
  bottom: 0px;
  color: #ff4d4f;
  font-size: 18px;
}

.INFO {
  color: black;
}

.ERROR {
  color: #ff4d4f;
}

.DEBUG {

  color: black;
}

.logList {
  padding: 0 4px 8px 4px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: overlay;
  background-color: white;
  border-right: 1px solid #c8c8c8;
  font-size: 12px;
  line-height: 14px;
}

.liubai {
  height: 10px;
}

#chartContainer,
#rimSealing {

  height: 100%;
  width: 100%;
}

.ant-spin-nested-loading {
  height: 100%;
}
</style>
