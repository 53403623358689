<template>
  <form>
    <div class="line">
      
      <a-button block @click="setBlade" >Set Blade Parameter</a-button>
    </div>
    <div class="line">
      
      <a-button block @click="generateModel" :disabled="!model2DShow&&dataStatus!=0" :loading="loading">Generate 3D Model</a-button>
    </div>
    <!-- <div class="line">
      <a-button block @click="showCreate2D" >Create 2D Model</a-button>
    </div>

    <div class="line">
      <a-button block @click="showCreate3D" >Create 3D Model</a-button>
    </div> -->

    <div class="line">
      <a-button block @click="exportFilesModelCurves" :disabled="modelImportType||dataStatus<0">Export Model Curves</a-button>
    </div>
    <div class="line" v-if="model2DShow">
      <a-button block @click="exportCurrent2dData" :disabled="!model2DShow">Export Current 2D Data</a-button>
    </div>
    <SetBladeParameter v-model:visible="setBladeVisible" />
    <!-- <Create2D v-model:visible="create2Dvisible" />
    <Create3D v-model:visible="create3Dvisible" /> -->
  </form>
</template>

<script setup>
import { ref,computed } from "vue";
import SetBladeParameter from "./setBladeParameter/index.vue";
// import Create2D from "./setBladeParameter/Create2D.vue";
// import Create3D from "./setBladeParameter/Create3D.vue";
import { message } from 'ant-design-vue';
import { useStore } from "vuex";
import model from "@/api/modules/model";
import { to,openDownloadDialog } from "@/utils/utils";
const store=useStore()
const model2DShow = computed(() => store.state.control.model2DShow);
const dataStatus = computed(() => store.state.model.dataStatus);
const modelImportType = computed(() => store.state.model.modelImportType);
// const create2Dvisible = ref(false);
const setBladeVisible= ref(false);
const block2dInput = computed(() => store.state.model.block2dInput);
const block3dInput = computed(() => store.state.model.block3dInput);

const loading = computed(() => store.state.control.loading);
const points2D = computed(() => store.state.control.points2D);


const advancedData = computed(() => store.state.model.inputAddData);

const setBlade=()=>{
  setBladeVisible.value = !setBladeVisible.value;
}
const generateModel=async()=>{
  
  store.commit('SET_LOADING', true);
  const paramsData={
      block2dInput:block2dInput.value,
      block3dInput:block3dInput.value.typeValue[block3dInput.value.type],
      inputAddData:advancedData.value,
      points:points2D.value
    }

  const [err,res] = await to(
    model.createModal(paramsData)
  );

    if(res.data==false){
      window.addLog.error('Data Invalid,Please check your input data');
      store.commit('SET_LOADING', false);
      return;
    }
  if (err) {
    window.addLog.error('Network error, please refresh');
    return;

  }
  const [err2] = await to(model.generateBlocks());
  if (err2) {
    window.addLog.error('Network error, please refresh');
    return;
  }
  await store.dispatch('resetModelItems')
  await store.dispatch('resetBlock')
  await store.dispatch('resetMesh')
  store.commit('SET_MODEL_IMPORT_TYPE', false);

  store.commit("SET_MODEL_2D_SHOW", false);

  store.commit("SET_DATA_STATUSAdd");
  store.commit('SET_LOADING', false);
}
// function showCreate2D() {
//   create2Dvisible.value = !create2Dvisible.value;
// }
// function showCreate3D() {
//   create3Dvisible.value = !create3Dvisible.value;
// }
// const create3Dvisible = ref(false);

const exportCurrent2dData= async()=>{
  store.commit('RESET_GET_CURRENT_2D_DATA_FALG')
}
const exportFilesModelCurves = async () => {
  const paramsData={
      block2dInput:block2dInput.value,
      block3dInput:block3dInput.value.typeValue[block3dInput.value.type],
      inputAddData:advancedData.value,
      points:points2D.value
    }
const hide = message.loading('Generating model curves file...', 0);
const [err, res] = await to(
  model.exportFilesModelCurves(paramsData)
);

if (err) {

  window.addLog.error(err);
  return;
}
hide();
openDownloadDialog('/api' +res.data,'model_curves_'+ Date.parse(new Date()))
// window.open('/api' +res.data)
}
</script>

<style scoped>
form {
  margin: 10px;
}

.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

label {
  height: 25px;
  float: left;
  width: 80px;
  line-height: 25px;
  font-size: 14px;
}

input {
  width: 110px;
  font-size: 14px;
}
</style>
