<template>
  <a-spin :spinning="loading">
    <a-form
      class="form"
      labelAlign="left"
      :model="secondPartFrom"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item
        label="Index"
        :labelCol="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-input
          size="default"
          placeholder="please click"
          v-model:value="secondPartFrom.index"
          :class="[isSelectStatus ? 'selected' : '']"
          disabled
        >
          <template #addonAfter>
            <div class="icon-select" @click="selectMesh">
              <select-outlined style="font-size: 16px" />
            </div>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item
        label="Length"
        :labelCol="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-input
          size="default"
          v-model:value="secondPartFrom.m_length"
          disabled
        />
      </a-form-item>
      <a-form-item
        label="Number"
        :labelCol="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-input size="default" v-model:value="secondPartFrom.m_num" :min="2" />
      </a-form-item>

      <!-- <a-divider /> -->
      <div class="split-line"></div>

      <a-form-item
        label="Bunching law"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-select
          ref="select"
          v-model:value="secondPartFrom.m_law"
          :options="concatenationRulesSelectData"
        ></a-select>
      </a-form-item>

      <a-form-item label="Spacingl">
        <a-input-number
          :controls="false"
          v-model:value="secondPartFrom.m_spacing1"
        />
      </a-form-item>
      <a-form-item label="Ratiol">
        <a-input-number
          :controls="false"
          v-model:value="secondPartFrom.m_ratio1"
        />
      </a-form-item>
      <a-form-item label="Spacing2">
        <a-input-number
          :controls="false"
          v-model:value="secondPartFrom.m_spacing2"
        />
      </a-form-item>
      <a-form-item label="Ratio2">
        <a-input-number
          :controls="false"
          v-model:value="secondPartFrom.m_ratio2"
        />
      </a-form-item>
      <div>
        <a-checkbox v-model:checked="sameType"
          >Select lines of the same type</a-checkbox
        >
      </div>
      <a-space :style="{ width: '100%' }" direction="vertical">
        <a-row :style="{ width: '100%' }">
          <a-col :span="12">
            <a-upload
              style="display: inline-block;width: 100%"
              :fileList="[]"
              :customRequest="() => {}"
              :before-upload="handleBeforeUpload"
            >
              <a-button block>
                Import DataFile
              </a-button>
            </a-upload>
          </a-col>
          <a-col :span="12">
            <a-button @click="exportToTxt" block>Export DataFile</a-button>
          </a-col>
        </a-row>


        <a-button block @click="secondPartApply" :disabled="!isGetLine"
          >Apply</a-button>
          
        <a-button block @click="generateMeshClick" :loading="loading"
          >GenerateMesh</a-button>

          
      </a-space>
    </a-form>
    
  <ToolComponent :generateMeshClick="generateMeshClick"/>
  </a-spin>
</template>

<script setup>
import { computed, ref, watch, onDeactivated } from "vue";
import { SelectOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import model from "@/api/modules/model";

import { message } from "ant-design-vue";
import { saveAs } from "file-saver";
import { to } from "@/utils/utils";

import ToolComponent from "@/components/ToolComponent/index.vue";
const store = useStore();
const sameType = ref(true);
const oldVal = ref(null);
const loading = computed(() => store.state.control.loading);
const historyFalg = computed(() => store.state.control.historyFalg);
const historyList = computed(() => store.state.control.historyList);
const historyCurrent = computed(() => store.state.control.historyCurrent);
const concatenationRulesSelectData = [
  {
    value: 0,
    label: "Average",
  },
  {
    value: 1,
    label: "BiGeometric",
  },
  {
    value: 2,
    label: "Hyperbolic",
  },
];

const lineMeshMertrics = computed(() => store.state.model.lineMeshMertrics);
const lineIndex = computed(() => store.state.model.lineIndex);
const secondPartFrom = ref({
  ...lineMeshMertrics.value,
});

// 变更选择mesh状态
const isSelectStatus = computed(() => store.state.model.isSelectStatus);
const isGetLine = ref(false);

const BableMesh = computed(() => store.state.model.BableMesh);
const BableTipMesh = computed(() => store.state.model.BableTipMesh);

const meshShells = computed(() => store.state.model.meshShells);
const updateBladeMesh = async (currentName) => {
  let type = currentName.indexOf("BladeTip") == -1 ? 0 : 1;
  let arr1 = currentName.split("_");
  let data = [...meshShells.value];
  let newArr2 = [...BableMesh.value];
  let newArr3 = [...BableTipMesh.value];
  [newArr2, newArr3][type][arr1[2]][arr1[3]] = data.flat();
  store.commit(
    ["SET_BABLE_MESH", "SET_BABLE_TIP_MESH"][type],
    [newArr2, newArr3][type]
  );
  store.commit("SET_COOLINGS_CUT_STATUS", false);
};

async function secondPartApply() {
  const [err] = await to(model.parameterAdjustApply({...secondPartFrom.value,type:sameType.value?1:0}));

  if (err) {
    window.addLog.error(err);
    return;
  }
  window.addLog.info("update success");
  const [err2, res] = await to(model.getLineMeshMetrics(lineIndex.value));

  if (err2) {
    window.addLog.error(err);
    return;
  }
  await store.dispatch("resetEdges");
  secondPartFrom.value = res.data;
  store.commit("SET_HISTORY", {
    hisType: "ParameterAdjust",
    ParameterAdjust: {
      sameType: sameType.value,
      oldVal: { ...oldVal.value },
      newVal: secondPartFrom.value,
    },
  });

  oldVal.value = { ...res.data };
}
async function generateMeshClick() {
  store.commit("SET_LOADING", true);
  const [err] = await to(model.createMeshModel());

  if (err) {
    window.addLog.error(err);
    return Promise.reject();;
  }

  await store.dispatch("resetMesh");

  const currentName = await store.dispatch("getCurrentModelName");
  if (currentName.indexOf("Blade") != -1) {
    updateBladeMesh(currentName);
    
  }
  if (currentName.indexOf("_cut") != -1) {
    await resetBladeMesh(0);
    if(BableTipMesh.value){  
      await resetBladeMesh(1);
      
    }
    
    store.commit("SET_COOLINGS_CUT_STATUS", false);
  }
  store.commit("SET_LOADING", false);
 
  return Promise.resolve();
}
const resetBladeMesh = async (b) => {
  let mn = window.mnMax[b];
  const [err4, res4] = await to(model.getCoolingMatrixVolumns({ type: 1, pos: b }));
  if (err4) {
    window.addLog.error('Network error, please refresh');
    return
  }
  if (res4.code == 200) {
    let newArr2 = [];
    for (let i = 0; i < mn[0]; i++) {
      newArr2.push(res4.data.slice(i * mn[1], (i + 1) * mn[1]));
    }

    for (let index = 0; index < newArr2.length; index++) {
      const element = newArr2[index];
      for (let index2 = 0; index2 < element.length; index2++) {
        const item2 = element[index2];
        if (b == 1 && ((index == 0 || index == newArr2.length - 1) && (index2 == 0 || index2 == element.length - 1))) {
          continue;
        }
        if (item2?.length == 0&&window.conformal==false) {
          const res0 = await store.dispatch('selectModel', ["Cooling_Blade_", "Cooling_BladeTip_"][b] + index + '_' + index2);
          if (res0.code == 200) {
            const [err, res] = await to(model.getSurfaces(1));

            if (err) {
              window.addLog.error('Network error, please refresh');
              return
            }
            if (res.code == 200) {
              newArr2[index][index2] = res.data.flat();
            }
          }

        }
      }
    }
    
store.commit(["SET_BABLE_MESH", "SET_BABLE_TIP_MESH"][b], newArr2)
}

return Promise.resolve();
}
watch(lineIndex, async () => {
  isGetLine.value = true;
  if (lineIndex.value == undefined) {
    store.commit("SET_SELECT_LINEGROUP", []);
    return;
  }
  if (sameType.value) {
    const [lineGroupErr, lineGroupRes] = await to(
      model.getRelateLinesGroup(lineIndex.value)
    );
    if (lineGroupErr) {
      window.addLog.error(lineGroupErr);
      return;
    }
    store.commit("SET_SELECT_LINEGROUP", lineGroupRes.data);
  } else {
    store.commit("SET_SELECT_LINEGROUP", [lineIndex.value]);
  }
  const [err, res] = await to(model.getLineMeshMetrics(lineIndex.value));

  if (err) {
    window.addLog.error(err);
    return;
  }

  secondPartFrom.value = res.data;
  oldVal.value = { ...res.data };
});

function selectMesh() {
  if (!isSelectStatus.value) {
    store.commit("SET_LINE_INDEX", undefined);

    store.commit("SET_SELECT_LINEGROUP", []);
    isGetLine.value = false;
  }
  store.commit("SET_IS_SELECT_STATUS", !isSelectStatus.value);
}

onDeactivated(() => {
  store.commit("SET_LINE_INDEX", undefined);

  store.commit("SET_SELECT_LINEGROUP", []);
  store.commit("SET_IS_SELECT_STATUS", false);

  secondPartFrom.value = {
    ...lineMeshMertrics.value,
  };
  isGetLine.value = false;
});

watch(historyCurrent, async (newInd, oldInd) => {
  if (!historyFalg.value) return;
  if (historyList.value[newInd]?.hisType == "ParameterAdjust") {
    const current = historyList.value[newInd].ParameterAdjust;

    const params =
      newInd < oldInd
        ? historyList.value[oldInd].ParameterAdjust.oldVal
        : historyList.value[newInd].ParameterAdjust.newVal;
    const sameType =
      newInd < oldInd
        ? historyList.value[oldInd].ParameterAdjust.sameType
        : historyList.value[newInd].ParameterAdjust.sameType;
    if (sameType) {
      const [lineGroupErr, lineGroupRes] = await to(
        model.getRelateLinesGroup(params.index)
      );
      if (lineGroupErr) {
        window.addLog.error(lineGroupErr);
        return;
      }
      store.commit("SET_SELECT_LINEGROUP", lineGroupRes.data);
    } else {
      store.commit("SET_SELECT_LINEGROUP", [params.index]);
    }
    const [err] = await to(model.parameterAdjustApply({...params,type:sameType?1:0}));

    if (err) {
      window.addLog.error(err);
      return;
    }
    const [err2, res] = await to(model.getLineMeshMetrics(params.index));

    if (err2) {
      window.addLog.error(err);
      return;
    }

    secondPartFrom.value = res.data;
    oldVal.value = { ...res.data };
    if (current.oldVal == null) {
      historyList.value[oldVal];
    }
    window.addLog.info(newInd < oldInd ? "Undo Success" : "Redo Success");

    store.commit("SET_LOADING", false);
  }
});

const handleBeforeUpload = (file) => {
  const reader = new FileReader();

  store.commit("SET_LOADING", true);
  reader.onload = async (e) => {
    const contents = e.target.result;

    try {
      const data = JSON.parse(contents);
      for (let index = 0; index < data.length; index++) {
        const his = data[index];
        const params = his.ParameterAdjust.newVal;
        const sameType = his.ParameterAdjust.sameType;
        if (sameType) {
          const [lineGroupErr, lineGroupRes] = await to(
            model.getRelateLinesGroup(params.index)
          );
          if (lineGroupErr) {
            window.addLog.error(lineGroupErr);
            return;
          }
          store.commit("SET_SELECT_LINEGROUP", lineGroupRes.data);
        } else {
          store.commit("SET_SELECT_LINEGROUP", [params.index]);
        }
    const [err] = await to(model.parameterAdjustApply({...params,type:sameType?1:0}));

        if (err) {
          window.addLog.error(err);
          return;
        }
        const [err2, res] = await to(model.getLineMeshMetrics(params.index));

        if (err2) {
          window.addLog.error(err);
          return;
        }

        secondPartFrom.value = res.data;
        oldVal.value = { ...res.data };
      }
    } catch (error) {
      console.error(error);
      message.error("File Read Error:" + error);
      window.addLog.error("File Read Error:" + error);
    }

    store.commit("SET_LOADING", false);
  };

  reader.readAsText(file);
};
const exportToTxt = () => {
  let jsonData = [];
  for (
    let index = historyList.value.length - 1;
    index >= 0 && historyList.value[index]?.hisType == "ParameterAdjust";
    index--
  ) {
    let li = {
      hisType: "ParameterAdjust",
      ParameterAdjust: {
        sameType: historyList.value[index].ParameterAdjust.sameType,
        newVal: historyList.value[index].ParameterAdjust.newVal,
      },
    };
    jsonData.unshift(li);
  }

  const jsonStr = JSON.stringify(jsonData, null, 2); // 使用两个空格进行缩进，保持可读性

  const blob = new Blob([jsonStr], {
    type: "text/plain;charset=utf-8",
  });
  saveAs(blob, "data.txt");
};
</script>
<style lang="scss" scoped>
.form ::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
::v-deep .ant-upload.ant-upload-select{
  width: 100%;
}
.ant-input-group-addon {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 8px;
}

.split-line {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.icon-select {
  display: flex;
  padding: 7px 11px;
  line-height: 32px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.ant-input-number {
  width: 100%;
}

.ant-input-group-addon {
  background-color: white;
  padding: 0 5px;
}
</style>
<style>
.selected .ant-input-group-addon {
  background-color: #409eff;
  color: white;
}
</style>
