import * as THREE from "three";

function addLines(listData,lineMaterial) {
  const geometry = new THREE.BufferGeometry();
  const points = [];
  listData.forEach((listItem) => {
    points.push(new THREE.Vector3(listItem[0], listItem[1], listItem[2]));
  });
  geometry.setFromPoints(points);
  geometry.computeBoundingBox();
  const line = new THREE.Line(geometry, lineMaterial);
  return line;
}

const add2dLines=(lineGroup,lineMaterial,pointMaterial)=>{
  const group=new THREE.Group();
  lineGroup.forEach((listData)=>{
    const pointsList = [];
    const lineList=[];
    const geometry = new THREE.BufferGeometry();
    const pointsGeo = new THREE.BufferGeometry();
    let i=0;
    while(i<listData.length){
      lineList.push(new THREE.Vector3(listData[i], listData[i+1], 0));
      pointsList.push(new THREE.Vector3(listData[i], listData[i+1], 1));
      i+=3;
    }
    
    geometry.setFromPoints(lineList);
    pointsGeo.setFromPoints(pointsList);
    const line = new THREE.Line(geometry, lineMaterial);
    const points=  new THREE.Points( pointsGeo, pointMaterial );
    line.add(points)
    group.add(line);
  })
  return group
}
export { addLines,add2dLines };
