<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logList {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.icon {
  width: 0.5rem;
  height: 0.5rem;
}

.form {
  padding: 10px !important;
  height: calc(100% - 35px);
  overflow-x: hidden;
  overflow-y: auto;
}
.viewer-backdrop{
  background-color: rgba(0, 0, 0, 0.1)!important;
}
.viewer-prev,.viewer-play,.viewer-next{
  display: none;
}
.viewer-canvas{
  opacity: 0.6;
}
/* 设置全局滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
  position: absolute;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  /* border-radius: 4px; */
  background-color: #BFBFBF;
  transition: background-color 0.3s;
}

/* 滚动条滑块悬停样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  /* border-radius: 4px; */
  background-color: #F5F5F5;
}
.ant-spin-nested-loading{
  height: 100%;
}
.ant-spin-nested-loading>div{
  height: 100%;
}
.ant-card-meta-description{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-card-actions > li{
  margin: 0!important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
.ant-modal-footer>span{
  display: inline-block;
  margin-right:8px;
}
.ant-modal-body{
  max-height: calc( 100vh - 200px );
    overflow: overlay;
}
.ant-dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
