<template>
  <el-form ref="formRef" :model="twoDInputMap1">
    <el-form-item label="Axial Chord Length (mm)" :label-width="200">
      <el-input v-model="twoDInputMap1.axial_chord_length" type="number" @input="twoDInputMapChange(twoDInputMap1)"  />
    </el-form-item>
    <el-form-item label="Pitch (mm)" :label-width="200">
      <el-input v-model="twoDInputMap1.pitch" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Stagger Angle" :label-width="200">
      <el-input v-model="twoDInputMap1.stagger_angle" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Throat Length (mm)" :label-width="200">
      <el-input v-model="twoDInputMap1.throat_length" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Unguided Turning" :label-width="200">
      <el-input v-model="twoDInputMap1.unguided_turning" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Leading Edge Radius (mm)" :label-width="200">
      <el-input v-model="twoDInputMap1.leading_edge_radius" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Trailing Edge Radius (mm)" :label-width="200">
      <el-input v-model="twoDInputMap1.trailing_edge_radius" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Leading Edge Wedge Angle" :label-width="200">
      <el-input v-model="twoDInputMap1.leading_edge_wedge_angle" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Trailing Edge Wedge Angle" :label-width="200">
      <el-input v-model="twoDInputMap1.trailing_edge_wedge_angle" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Leading Edge Metal Angle" :label-width="200">
      <el-input v-model="twoDInputMap1.leading_edge_metal_angle" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Trailing Edge Metal Angle" :label-width="200">
      <el-input v-model="twoDInputMap1.trailing_edge_metal_angle" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Length Over Chord Inlet" :label-width="200">
      <el-input v-model="twoDInputMap1.length_over_chord_inlet" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Length Over Chord Outlet" :label-width="200">
      <el-input v-model="twoDInputMap1.length_over_chord_outlet" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <!-- <el-form-item label="Per 18" :label-width="200">
      <el-input-number :min="0.000001" :max="0.999999" :controls="false" v-model="twoDInputMap1.per_18" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Per 19" :label-width="200">
      <el-input-number :min="0.000001" :max="0.999999" :controls="false"  v-model="twoDInputMap1.per_19" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Por 19" :label-width="200">
      <el-input-number :min="0.000001" :max="1" :controls="false"  v-model="twoDInputMap1.por_19" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Por 6 0" :label-width="200">
      <el-input-number :min="0" :max="twoDInputMap1.por_6_1-0.000001"  :controls="false" v-model="twoDInputMap1.por_6_0" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item>
    <el-form-item label="Por 6 1" :label-width="200">
      <el-input-number :min="twoDInputMap1.por_6_0+0.000001" :max="1" :controls="false" v-model="twoDInputMap1.por_6_1" type="number" @input="twoDInputMapChange(twoDInputMap1)" />
    </el-form-item> -->

    <a-row v-if="advanced" :gutter="24">
      <a-col :span="6">
        <el-form-item label="Dx P1" :label-width="60">
          <a-input-number v-model:value="inputAddData1[0]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">
        <el-form-item label="Dx P2" :label-width="60">
          <a-input-number v-model:value="inputAddData1[1]" style="width: 150px" @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">
        <el-form-item label="Var P1" :label-width="60">
          <a-input-number v-model:value="inputAddData1[2]" style="width: 150px" @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">
        <el-form-item label="Var P2" :label-width="60">
          <a-input-number v-model:value="inputAddData1[3]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">
        <el-form-item label="Dx P22" :label-width="60">
          <a-input-number v-model:value="inputAddData1[4]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Dx P3" :label-width="60">
          <a-input-number v-model:value="inputAddData1[5]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Var P22" :label-width="60">
          <a-input-number v-model:value="inputAddData1[6]" style="width: 150px" @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Var P3" :label-width="60">
          <a-input-number v-model:value="inputAddData1[7]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">
        <el-form-item label="Dx P4" :label-width="60">
          <a-input-number v-model:value="inputAddData1[8]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Dx P5" :label-width="60">
          <a-input-number v-model:value="inputAddData1[9]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Var P4" :label-width="60">
          <a-input-number v-model:value="inputAddData1[10]" style="width: 150px" @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
      <a-col :span="6">

        <el-form-item label="Var P5" :label-width="60">
          <a-input-number v-model:value="inputAddData1[11]" style="width: 150px"  @input="inputAddDataChange(inputAddData1)"
            :controls="false" />
        </el-form-item>
      </a-col>
    </a-row>

  </el-form>
</template>

<script setup>
import { defineProps, defineEmits,defineExpose, ref,onMounted} from "vue";

const props=defineProps({
  advanced: Boolean,
  twoDInputMap: Object,
  inputAddData: Array,
});

const formRef=ref(null);
defineExpose({
  formRef
})
const twoDInputMap1 = ref({
      axial_chord_length: 26.0458,
      pitch: 21.9775,
      stagger_angle: -30,
      throat_length: 1.040721e+01,
      unguided_turning: 2.384479e+01,
      leading_edge_radius: 0.781374,
      trailing_edge_radius: 0.520916,
      leading_edge_wedge_angle: 30,
      trailing_edge_wedge_angle: 5,
      leading_edge_metal_angle: 46,
      trailing_edge_metal_angle: -66.5,
      length_over_chord_inlet:0.4,
      length_over_chord_outlet:1,
      // per_18:0.34,
      // per_19:0.34,
      // por_19:1,
      // por_6_0:0,
      // por_6_1:1,
    })
const inputAddData1 = ref([6.845508e-01, 7.345243e-01, 2.268695e-01, 3.353179e-01,9.918515e-01, 4.358876e-01, -2.223638e-01, 2.230405e-01,2.632947e-01, 2.384253e+00, 5.488735e-01, 1.496262e-01])

onMounted(()=>{
  twoDInputMap1.value={...props.twoDInputMap};
  inputAddData1.value =[...props.inputAddData];
})
const emit = defineEmits(["update:twoDInputMap", "update:inputAddData"]);

const twoDInputMapChange = (e) => {
  console.log(e.axial_chord_length);
  emit('update:twoDInputMap', e)
}
const inputAddDataChange = (e) => {
  console.log(e);
  emit('update:inputAddData', e)
}

</script>

<style scoped lang="scss">
form {
  margin: 10px;
}

.line {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

label {
  flex: 1;
  height: 25px;
  float: left;
  width: 80px;
  line-height: 25px;
  font-size: 14px;
}

input {
  width: 150px;
  height: 20px;
  font-size: 14px;
}

.apply-btn {
  width: 324px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #adadad;
  background-color: #e1e1e1;
  margin-top: 15px;
  cursor: pointer;
}
</style>
