
import { Split,ColorPicker  } from 'view-ui-plus';
import Antd from 'ant-design-vue';
// let plugins = [Tooltip,Checkbox,CheckboxGroup,Slider,SubMenu,Menu,MenuItem, Tree,Form,FormItem, Select,Modal, Radio,Button,Space,InputNumber,Divider,Input,Row,Col,Switch,Steps,Step ];

import 'view-ui-plus/dist/styles/viewuiplus.css'
export default function getVant(app) {
  app.use(Antd);
  app.component('Split', Split);
  app.component('ColorPicker', ColorPicker);
  // plugins.forEach((item) => {
  //   return app.use(item);
  // });
}
