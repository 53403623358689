import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/js/rem";
import "../public/iconfont/iconfont";
import getVant from "./plugins";
import "@/assets/css/common.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import './scss/app.scss';
const app = createApp(App);

getVant(app);
app.use(store);
app.use(ElementPlus);
app.use(router).mount("#app");

app.use(Viewer)