import { post, get, upload } from "../request";

export default {
  // 创建模型:CreateModal模块的Create Modal按钮对应的接口,没有返回值
  createModal(data) {
    return post("/mesh/createModal", data);
  },
  getGeoBlade2D(data) {
    return post("/mesh/getGeoBlade2D", data);
  },
  // 初始化模型数据，返回boolean
  generateBlocks() {
    return get("/mesh/generateBlocks");
  },

  // getLines:一级菜单下的第一个二级菜单，返回一个二维数组
  getLines(type) {
    return get(`/mesh/getLines/${type}`);
  },

  // getSurfaces:一级菜单下的第二个二级菜单，返回一个二维数组
  getSurfaces(type) {
    return get(`/mesh/getSurfaces/${type}`);
  },

  // getVolumns:一级菜单下的第三个二级菜单，返回一个二维数组
  getVolumns(type) {
    return get(`/mesh/getVolumns/${type}`);
  },
  // coolinigCutApply:CoolingCut模块的Apply按钮对应的接口，返回boolean
  coolingCutApply(data) {
    return post("/mesh/coolingCutApply", data);
  },
  // GenerateMesh:ParameterAdjust模块的GenerateMesh按钮对应的接口，没有返回值
  createMeshModel() {
    return get("/mesh/createMeshModel");
  },
  // 点击ShowBladeCut按钮和ShowBladeTipCut按钮后，获取跳出页面前的最后一次Apply的默认数据，返回一个二维double数组
  getCoolingDesignMatrix(data) {
    return get(`/mesh/getCoolingDesignMatrix`, data);
  },
  getCoolingsMatrix(type) {
    return get(`/mesh/getCoolingsMatrix/${type}`,);
  },
  // ParameterAdjust模块,点击选择线条按钮并选中线条之后,用index去获取对应的数据,用于前端展示，返回一个对象
  getLineMeshMetrics(index) {
    return get(`/mesh/getLineMeshMetrics/${index}`);
  },
  // 获取BlockingIndexControl模块的Max值，返回数组
  getMaxIndicesDataOfIJK() {
    return get("/mesh/getMaxIndicesDataOfIJK");
  },
  // 获取柱状图的数据，返回数组
  getMeshQualityHistogramDefault(type) {
    return get(`/mesh/getMeshQualityHistogramDefault/${type}`);
  },
  // 获取动态table的Row和Column:CoolingCut模块的ShowBladeCut按钮和ShowBladeTipCut按钮对应的接口，返回数组
  getRowAndColumn(pos) {
    return get(`/mesh/getRowAndColumn/${pos}`);
  },
  // parameterAdjustApply:ParameterAdjust模块的Apply按钮对应的接口，没有返回值
  parameterAdjustApply(data) {
    return post("/mesh/parameterAdjustApply", data);
  },
  // 获取与指定线数据同类的所有线数据的编号集合
  getCoolingMatrixVolumns(data) {
    return get(`/mesh/getCoolingMatrixVolumns`, data);
  },
  // 获取冷却孔矩阵对应的block编号信息
  getCoolingBlocksIndexMatrix(pos) {
    return get(`/mesh/getCoolingBlocksIndexMatrix/${pos}`);
  },
  // 获取冷却孔矩阵对应的block编号信息
  getCoolingMeshSurfacesIndexMatrix(pos) {
    return get(`/mesh/getCoolingMeshSurfacesIndexMatrix/${pos}`);
  },
  
  // 判断冷却孔 分组是否合格
  checkIfMerge(data) {
    return post(`/mesh/checkIfMerge`,data);
  },
  
  // 切割冷却孔
  coolingsCut(pos) {
    return get(`/mesh/coolingsCut/${pos}`);
  },
  // 切割冷却孔
  coolingsCut0(f) {
    return get(`/mesh/coolingsCut0/${f}`);
  },
  // 获取与指定线数据同类的所有线数据的编号集合
  getRelateLinesGroup(pos) {
    return get(`/mesh/getRelateLinesGroup/${pos}`);
  },
  // 点击ShowBladeCut按钮和ShowBladeTipCut按钮后，第二次及以后所有Apply对应的接口，没有返回值
  setCoolingDesignMatrix(data) {
    return post("/mesh/setCoolingDesignMatrix", data);
  },
  // 点击ShowBladeCut按钮和ShowBladeTipCut按钮后，第一次Apply对应的接口，没有返回值
  setCoolingsMatrix(data) {
    return post("/mesh/setCoolingsMatrix", data);
  },
  // 通过两个IJK坐标定位对应的体数据编号集合
  getLineIndexesFromIJK(data) {
    return get("/mesh/getLineIndexesFromIJK", data);
  },
  // 通过两个IJK坐标定位对应的体数据编号集合
  getSurfaceIndexesFromIJK(data) {
    return get("/mesh/getSurfaceIndexesFromIJK", data);
  },
  // 通过两个IJK坐标定位对应的体数据编号集合
  getVolumnIndexesFromIJK(data) {
    return get("/mesh/getVolumnIndexesFromIJK", data);
  },
  // 获取扫描平面范围
  getScanPlaneRange() {
    return get("/mesh/getScanPlaneRange");
  },
  // 获取所有模型的名称
  getAllModelNames() {
    return get("/mesh/getAllModelNames");
  },
  // 以指定名称的模型作为当前模型
  selectModel(pos) {
    return get(`/mesh/selectModel/${pos}`);
  },

  // 获取扇形冷却孔参数错误的矩阵坐标信息
  checkFanShapedHoleMetrics(pos) {
    return get(`/mesh/checkFanShapedHoleMetrics/${pos}`);
  },
  // 获取扫描处的网格数据
  getGridPatchesByScanPlanes(data) {
    return get("/mesh/getGridPatchesByScanPlanes", data);
  },
  // 获取指定柱状图组对应的网格单元数据
  getMeshQualityHistogramElementData(data) {
    return get("/mesh/getMeshQualityHistogramElementData", data);
  },
  // 生成轮缘封严模型
  generateRimSealingModel(data) {
    return get("/mesh/generateRimSealingModel", data);
  },
  // 获取可用于生成轮缘封严的模型名称
  getAllModelNamesAboutRimSealing() {
    return get("/mesh/getAllModelNamesAboutRimSealing");
  },
  // 输出网格文件
  exportFiles(pos) {
    return get(`/mesh/exportFiles/${pos}`);
  },
  // 输出模型几何曲线的txt文件
  exportFilesModelCurves(data) {
    return post(`/mesh/exportFilesModelCurves`, data);
  },

  // 输出混合网格文件
  exportFilesMultiModels(data) {
    return get(`/mesh/exportFilesMultiModels`, data);
  },
  // 获取当前模型名字
  getCurrentModelName() {
    return get(`/mesh/getCurrentModelName`);
  },
  // 获取当前模型类型
  getModelTypeToBeCut() {
    return get(`/mesh/getModelTypeToBeCut`);
  },
  // 输出模型几何曲线的txt文件
  readGeoFiles(data) {
    return upload(`/mesh/readGeoFiles`, data);
  },
  
    // 批量节点调节
    batchCoolingsMesh(data) {
      return post(`/mesh/batchCoolingsMesh`, data);
    },
  // 删除模型
  deleteModel(pos) {
    return get(`/mesh/deleteModel/${pos}`);
  },


  //下载生成好的文件
  downloadFile() {
    return get(`/mesh/downloadFile`);
  },
  getElementsInfo(){
    return get(`/mesh/getElementsInfo`);
  }
};
