<template>
  <div class="modelParent" ref="modelParent">
    <div
      id="moel2D"
      :style="{ width: width2D + 'px', height: height2D + 'px' }"
    >
      <div
        class="pointMark"
        :style="{
          position: 'absolute',
          left: pointMark.x + 'px',
          top: pointMark.y + 'px',
          pointerEvent: 'none',
          opacity: pointMark.vis ? 1 : 0,
        }"
      ></div>
      <div
        v-for="(item, index) in divList"
        :key="item"
        :style="{
          opacity:
            parseInt(model2DType / (125 / chartList.length)) == index ? 1 : 0,
          zIndex:
            parseInt(model2DType / (125 / chartList.length)) == index ? 1 : 0,
        }"
        :id="item"
      ></div>
    </div>

    <div class="typeChange" v-if="chartList.length">
      <div :style="{ width: '50%', margin: 'auto' }">
        <div
          style="
            width: 60px;
            font-size: 16px;
            line-height: 32px;
            display: inline-block;
            text-align: right;
            float: left;
          "
        >
          layer: &nbsp;
        </div>
        <div
          style="display: inline-block; width: calc(100% - 140px); float: left"
        >
          <a-slider
            v-model:value="model2DType"
            @change="changeType"
            :tip-formatter="formatter"
            :min="0"
            :max="124"
          >
            <template #mark="{ label, point }">
              <template v-if="point % (125 / chartList.length) == 0">
                {{ label }}
              </template>
              <template v-else></template>
            </template>
          </a-slider>
        </div>
        <div
          style="
            display: inline-block;
            font-size: 14px;
            line-height: 32px;
            width: 80px;
            float: left;
            text-align: right;
          "
        >
          <left-outlined @click="paginationChange(-1)" /> {{ pagination }} /
          {{ chartList.length }} <right-outlined @click="paginationChange(1)" />
        </div>
      </div>
    </div>
    <div class="tips2D">
      <a-form-item label="Periodic boundary alignment:">
        <a-switch :disabled="chartList.length<=1" v-model:checked="alignState" @change="changeAlign" size="small" />
      
    </a-form-item>
      
     {{ tips }}
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { message } from 'ant-design-vue';
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";
import { Line } from "@antv/g2plot";
import { saveAs } from "file-saver";

import { notification } from 'ant-design-vue';
const formatter = (value) => {
  pagination.value = parseInt(value / (125 / chartList.value.length)) + 1;
  return `${parseInt(value / (125 / chartList.value.length)) + 1}`;
};
const pagination = ref(1);
const alignState=ref(false);
const alignClass=[
  [9,16],[10,18],[11,19]
  // ,[4,17]
];
const tips=ref("");
const paginationChange = (f) => {
  if (
    pagination.value + f < 1 ||
    pagination.value + f > chartList.value.length
  ) {
    return;
  }
  pagination.value += f;
  model2DType.value = (pagination.value - 1) * (125 / chartList.value.length);
};
const store = useStore();
const model2D = computed(() => store.state.control.model2D);
const getCurrent2dDataFalg = computed(
  () => store.state.control.getCurrent2dDataFalg
);
const model2DType = ref(0);
const width2D = ref(100);
const height2D = ref(100);
const modelParent = ref(null);
const divList = ref(["moel2D0", "moel2D1", "moel2D2", "moel2D3", "moel2D4"]);
const chartList = ref([]);
const chartFilterData = ref([]);

const change2DFalg = ref(true);
const change2DPointId = ref(null);
const points2D = computed(() => store.state.control.points2D);
watch(getCurrent2dDataFalg, (newValue) => {
  if (newValue) {
    const dataText = chartFilterData.value[
      parseInt(model2DType.value / (125 / chartList.value.length))
    ]
      .map((obj) => `${obj.x}, ${obj.y}`)
      .join("\n");

    const blob = new Blob([dataText], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "data.txt");
  }
});
const updateWidHei = (entry) => {
  if (!model2D.value?.xAxis) {
    return;
  }
  const x = model2D.value.xAxis[1] - model2D.value.xAxis[0]; //计算x轴大小
  const y = model2D.value.yAxis[1] - model2D.value.yAxis[0]; //计算y轴大小
  let height = entry?.clientHeight || entry?.contentRect?.height;
  let width = entry?.clientWidth || entry?.contentRect?.width;
  let hei = height - 104;
  let wid = width - 64;
  if ((hei * x) / y <= wid) {
    width2D.value = (hei * x) / y + 64;
    height2D.value = height;
  } else if ((wid * y) / x <= hei) {
    width2D.value = width;
    height2D.value = (wid * y) / x + 104;
  }
};
onMounted(() => {
  const resizeObserver = new ResizeObserver((e) => {
    for (const entry of e) {
      updateWidHei(entry);
    }
  });
  resizeObserver.observe(modelParent.value);
});
const loadChart = (data, id) => {
  if (chartList.value[id] != null) {
    chartList.value[id].update({
      xAxis: {
        type: "linear",
        line: {
          style: {
            stroke: "#aaa",
          },
        },
        tickLine: {
          style: {
            stroke: "#aaa",
          },
        },
        minLimit: model2D.value.xAxis[0],
        maxLimit: model2D.value.xAxis[1],
      },
      yAxis: {
        line: {
          style: {
            stroke: "#aaa",
          },
        },

        tickLine: {
          style: {
            stroke: "#aaa",
          },
        },
        maxLimit: model2D.value.yAxis[1],
        minLimit: model2D.value.yAxis[0],
      },
    });
    chartList.value[id].changeData(data);
    return;
  }

  chartList.value[id] = new Line("moel2D" + id, {
    data,
    xField: "x",
    yField: "y",
    label: false,
    autoFit: true,
    animation: false,
    padding: 32,
    seriesField: "line",
    color: "#5B8FF9",
    point: {
      size: 1,
      style: (d) => {
        let f = 0;
        if (
          d.line.indexOf("line") != -1 &&
          d.line.split("line")[1] > 2 &&
          d.line.split("line")[1] != 37
        ) {
          f = 1;
        }
        return {
          stroke: "red",
          lineWidth: f ? 4 : 0,
        };
      },
      state: {
        active: {
          style: {
            lineWidth: 10,
          },
        },
      },
    },
    xAxis: {
      type: "linear",
      line: {
        style: {
          stroke: "#aaa",
        },
      },
      tickLine: {
        style: {
          stroke: "#aaa",
        },
      },
      minLimit: model2D.value.xAxis[0],
      maxLimit: model2D.value.xAxis[1],
    },
    yAxis: {
      line: {
        style: {
          stroke: "#aaa",
        },
      },

      tickLine: {
        style: {
          stroke: "#aaa",
        },
      },
      maxLimit: model2D.value.yAxis[1],
      minLimit: model2D.value.yAxis[0],
    },
    tooltip: false,
    brush: {
    enabled: true,
    action: 'highlight',
    isStartEnable: (context) => {
      // 按住 shift 键，才能开启交互
      if (context.event.gEvent.originalEvent?.shiftKey) {
        return true;
      }
      return false;
    },
  },
  });
  
// chartList.value[id].on('brush:filter', (e) => {
//   console.log(e);
// })
  chartList.value[id].render();
//   console.log(chartList.value[id]);
//   chartList.value[id].interaction('brush', {
//   start: [
//     {
//       trigger: 'mousedown', // 当鼠标按下时触发
//       isEnable: (e) => e.event.shiftKey, // 仅当按住 Shift 键时启用 brush
//     },
//   ],
// });

  chartList.value[id].on("legend-item:click", (args) => {
    chartFilterData.value[id] = args.view.filteredData;
  });
  // chartList.value[id].on("point:click", checkPoint);
  chartList.value[id].on("point:mousedown", (e) => {
    checkPoint(e);
    // let timeout;
    if (
      e?.data?.data?.pointId == change2DPointId.value &&
      e?.data?.data?.pointId !== undefined
    ) {
      val = null;
      chartList.value[id].on("mousemove", (e) => {
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        pointMove(e);
        // }, 1000);
      });
      chartList.value[id].on("mouseup", (e) => {
        chartList.value[id].off("mousemove");
        chartList.value[id].off("mouseup");
        // clearTimeout(timeout);

        pointMove(e);
        pointMark.value = {
          vis: false,
          x: 0,
          y: 0,
        };
        if (movePoint.value?.x && change2DPointId.value >= 0) {
          val = {
            id: change2DPointId.value,
            x: movePoint.value.x,
            y: movePoint.value.y,
          };
          
          let pointAlignClass = alignClass.filter(n=>{return n[0]==val.id||n[1]==val.id});
          let pointResult=false;
          if(alignState.value&&pointAlignClass.length>0){
            
            let alignVithLine2 = model2D.value.list[id].filter((n) => {
              return n.line == "line2";
            });
            pointResult={
              id:pointAlignClass[0][0]==val.id?pointAlignClass[0][1]:pointAlignClass[0][0],
              ...getPointFromLine(val.x,val.y, alignVithLine2)
            }
          }

          let pointsData = JSON.parse(JSON.stringify(points2D.value));
          pointsData[id][change2DPointId.value * 2] = val.x;
          pointsData[id][change2DPointId.value * 2 + 1] = val.y;
          if(pointResult){
            pointsData[id][pointResult.id * 2] = pointResult.x;
            pointsData[id][pointResult.id * 2 + 1] = pointResult.y;
          }
          newData[id] = newData[id] || model2D.value.list[id];

          if (alignVithLine.value) {
            let otherNum = findOtherNum(val.id, pointLine1List);
            const newData2 = {
              id: otherNum,
              ...updatePoint(val.x, val.y, alignVithLine.value),
            };

            pointsData[id][otherNum * 2] = newData2.x;
            pointsData[id][otherNum * 2 + 1] = newData2.y;
            newData[id] = newData[id].map((n) => {
              if (n.pointId == val.id) {
                return { ...n, x: val.x, y: val.y, initX: n.x, initY: n.y };
              }
              if (n.pointId == otherNum) {
                return {
                  ...n,
                  x: newData2.x,
                  y: newData2.y,
                  initX: n.x,
                  initY: n.y,
                };
              }
              return n;
            });
          } else {
            newData[id] = newData[id].map((n) => {
              
              if(pointResult&&n.pointId==pointResult?.id){
                return {
                  ...n,
                  x: pointResult.x,
                  y: pointResult.y,
                  initX: n.x,
                  initY: n.y,
                };
              }
              return n.pointId == val.id
                ? { ...n, x: val.x, y: val.y, initX: n.x, initY: n.y }
                : n;
            });
          }
          chartList.value[id].changeData(newData[id]);
          movePoint.value = null;
          val = null;

          store.commit("SET_POINTS_2D", pointsData);
        }
      });
    }
  });
};
const pointLine1List = [
  [24, 20],
  [25, 21],
  [23, 27],
  [22, 26],
];
const findOtherNum = (id, list) => {
  for (let i = 0; i < list.length; i++) {
    const group = list[i];

    if (group.includes(id)) {
      const otherNum = group.filter((n) => n !== id)[0];
      return otherNum;
    }
  }
  return null;
};
const pointRule = [
  {
    type: 0, //line0移动规则
    data: [20, 21, 22, 23],
    line: "blade curve",
  },
  {
    type: 1, //line1移动规则
    data: [24, 25, 27, 26],
    line: "line1",
  },
  {
    type: 2, //line2移动规则
    data: [9, 10, 11, 12, 13, 19, 18, 17, 16, 14, 15],
    // data: [9, 4, 10, 11, 12, 13, 19, 18, 17, 16, 14, 15],
    line: "line2",
  },
  {
    type: 3,
    // data: [2, 3],
    data: [0, 1, 2, 3],
    line: "any",
  },
];
const closedLine = [
  [1, 25, 24, 4, 9, 8, 15, 14],
  [16, 17, 25, 24, 0, 15, 14, 7],
  [19, 6, 13, 12, 3, 27, 26, 18, 19],
  [2, 13, 12, 5, 11, 10, 27, 26],
];
const closedLine2 = [
  [1, 24],
  [0, 25],
  [3, 26],
  [2, 27],
];
const currentRuleLine = ref([]);
const currentRuleClosedLine = ref([]);
let currentRuleNoCosedLine = [];
const alignVithLine = ref([]);
const movePoint = ref(null);
const pointMark = ref({
  vis: false,
  x: 0,
  y: 0,
});
function isPointInPolygon(point, polygon) {
  let x = point.x,
    y = point.y;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].x,
      yi = polygon[i].y;
    let xj = polygon[j].x,
      yj = polygon[j].y;

    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  let aId = closedLine2[change2DPointId.value][0];
  let bId = closedLine2[change2DPointId.value][1];
  let currentChartId = parseInt(
    model2DType.value / (125 / chartList.value.length)
  );
  let pointA = {
    x: points2D.value[currentChartId][aId * 2],
    y: points2D.value[currentChartId][aId * 2 + 1],
  };
  let pointB = {
    x: points2D.value[currentChartId][bId * 2],
    y: points2D.value[currentChartId][bId * 2 + 1],
  };
  console.log(checkSegments(pointA, point, pointB, currentRuleNoCosedLine));
  if (!checkSegments(pointA, point, pointB, currentRuleNoCosedLine)) {
    inside = false;
  }
  return inside;
}
function findSegmentIndex(point, points) {
  for (let i = 0; i < points.length; i++) {
    const p1 = points[i];
    const p2 = points[(i + 1) % points.length];

    const d1 = Math.hypot(point.x - p1.x, point.y - p1.y);
    const d2 = Math.hypot(point.x - p2.x, point.y - p2.y);
    const dSegment = Math.hypot(p2.x - p1.x, p2.y - p1.y);

    if (Math.abs(d1 + d2 - dSegment) < 0.001) {
      return i;
    }
  }
  return -1;
}
function extractSegment(points, indexA, indexB) {
  let segment = [];

  if (indexA <= indexB) {
    // 从 A 点所在的下一个顶点开始，到 B 点所在的前一个顶点
    for (let i = indexA + 1; i < indexB; i++) {
      segment.push(points[i]);
    }
  } else {
    // 从 A 点所在的下一个顶点开始，经过曲线末尾回到 B 点所在的前一个顶点
    for (let i = indexA + 1; i < points.length; i++) {
      segment.push(points[i]);
    }
    for (let i = 0; i < indexB; i++) {
      segment.push(points[i]);
    }
  }

  return segment;
}

// Find the closest point on a line segment
function closestPointOnLineSegment(p1, p2, p0) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const length = dx * dx + dy * dy;
  let t = ((p0.x - p1.x) * dx + (p0.y - p1.y) * dy) / length;
  t = Math.max(0, Math.min(1, t));
  return { x: p1.x + t * dx, y: p1.y + t * dy };
}

function checkSegments(pointA, pointB, pointC, points) {
  const segments = [];
  for (let i = 0; i < points.length - 1; i++) {
    segments.push({ p1: points[i], p2: points[i + 1] });
  }
  const segmentAB = { p1: pointA, p2: pointB };
  const segmentBC = { p1: pointB, p2: pointC };

  let abIntersects = false;
  let bcIntersects = false;

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    const isFirstOrLastSegment = i === 0 || i === segments.length - 1;

    // 检查 AB 线段是否与当前线段相交
    if (checkIntersection(segmentAB, segment)) {
      if (!isFirstOrLastSegment || abIntersects) {
        return false; // AB 与非首尾线段相交，或与首尾线段相交超过一次
      }
      abIntersects = true;
    }

    // 检查 BC 线段是否与当前线段相交
    if (checkIntersection(segmentBC, segment)) {
      if (!isFirstOrLastSegment || bcIntersects) {
        return false; // BC 与非首尾线段相交，或与首尾线段相交超过一次
      }
      bcIntersects = true;
    }
  }

  return true; // 没有无效相交
}
function checkIntersection(segment1, segment2) {
  // 解构线段的起点和终点
  const { p1: p1, p2: q1 } = segment1;
  const { p1: p2, p2: q2 } = segment2;

  // 计算方向
  const d1 = direction(p2, q2, p1);
  const d2 = direction(p2, q2, q1);
  const d3 = direction(p1, q1, p2);
  const d4 = direction(p1, q1, q2);

  // 判断相交
  if (d1 != d2 && d3 != d4) return true;

  // 特殊情况：线段和端点共线
  if (d1 == 0 && onSegment(p2, p1, q2)) return true;
  if (d2 == 0 && onSegment(p2, q1, q2)) return true;
  if (d3 == 0 && onSegment(p1, p2, q1)) return true;
  if (d4 == 0 && onSegment(p1, q2, q1)) return true;

  return false; // 不相交
}
function direction(p, q, r) {
  // 用向量叉乘判断转向
  const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
  if (val == 0) return 0; // 共线
  return val > 0 ? 1 : 2; // 顺时针或逆时针
}

function onSegment(p, q, r) {
  // 检查点 q 是否在线段 pr 上
  if (
    q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)
  ) {
    return true;
  }
  return false;
}
let newData = null;
let val = null;
const updatePoint = (x, y, line) => {
  let closestDistance = Infinity;
  let closestPoint = null;

  for (let i = 0; i < line.length; i++) {
    const p1 = line[i];
    const p2 = line[(i + 1) % line.length]; // Loop back for the last segment
    const point = closestPointOnLineSegment(p1, p2, { x, y });

    const distance = Math.hypot(point.x - x, point.y - y);

    if (distance < closestDistance) {
      closestDistance = distance;
      closestPoint = point;
    }
  }
  return closestPoint;
};
const pointMove = (evt) => {
  const { x, y } = evt;
  let currentChartId = parseInt(
    model2DType.value / (125 / chartList.value.length)
  );
  const plotWidth = chartList.value[currentChartId].chart.coordinateBBox.width;
  const plotHeight =
    chartList.value[currentChartId].chart.coordinateBBox.height;
  // 将画布坐标转换为数据坐标
  const xValue = chartList.value[currentChartId].chart
    .getXScale()
    .invert((x - 32) / plotWidth);
  const yValue = chartList.value[currentChartId].chart
    .getYScales()[0]
    .invert(1 - (y - 32) / plotHeight);

  if (currentRuleLine.value.length)
    movePoint.value = updatePoint(xValue, yValue, currentRuleLine.value);
  else {
    if (isPointInPolygon({ x: xValue, y: yValue }, currentRuleClosedLine.value))
      movePoint.value = { x: xValue, y: yValue };
  }
  //pointmark  计算位置
  const xScale = chartList.value[currentChartId].chart.getXScale();
  const yScale = chartList.value[currentChartId].chart.getYScales()[0];

  const pixelX = xScale.scale(movePoint.value.x);
  const pixelY = yScale.scale(movePoint.value.y);

  const finalPixelX = pixelX * plotWidth + 32;
  const finalPixelY = (1 - pixelY) * plotHeight + 32;
  pointMark.value = {
    vis: true,
    x: finalPixelX,
    y: finalPixelY,
  };
  
  changeTips(change2DPointId.value,movePoint.value.x,movePoint.value.y)
  //  updateLine()
};
let elePoint = null;
const changeTips=(id,x,y)=>{
  tips.value=`id:${id}\nx:${x}\ny:${y}`
}
const checkPoint = (e) => {
  currentRuleLine.value = [];

  if (elePoint) {
    elePoint.setState("active", false);
  }

  if (change2DFalg.value === false || e?.data?.data?.pointId === undefined)
    return;

  elePoint = e.target.get("element");
  if (elePoint) {
    elePoint.setState("active", true);
  }
  change2DPointId.value = e.data.data.pointId;
  movePoint.value = e.data.data;
  changeTips(change2DPointId.value,e.data.data.x,e.data.data.y);
  let currentChartId = parseInt(
    model2DType.value / (125 / chartList.value.length)
  );

  let lineRule = pointRule.find((n) => {
    return (
      n.data.findIndex((j) => {
        return j == e.data.data.pointId;
      }) != -1
    );
  });
  currentRuleLine.value = model2D.value.list[currentChartId].filter((n) => {
    return n.line == lineRule.line;
  });
  if (lineRule.line == "any") {
    let i = 0;
    let line = [];
    let pointsLine1 = model2D.value.list[currentChartId].filter((n) => {
      return n.line == "line1";
    });

    while (i < closedLine[change2DPointId.value].length) {
      let i1 = closedLine[change2DPointId.value][i];
      let i2 =
        closedLine[change2DPointId.value][
          (i + 1) % closedLine[change2DPointId.value].length
        ];
      let pointA = {
        x: points2D.value[currentChartId][i1 * 2],
        y: points2D.value[currentChartId][i1 * 2 + 1],
      };
      let pointB = {
        x: points2D.value[currentChartId][i2 * 2],
        y: points2D.value[currentChartId][i2 * 2 + 1],
      };
      line.push({
        x: points2D.value[currentChartId][i1 * 2],
        y: points2D.value[currentChartId][i1 * 2 + 1],
      });
      if (i1 >= 20 && i2 >= 20) {
        const indexA = findSegmentIndex(pointA, pointsLine1);
        const indexB = findSegmentIndex(pointB, pointsLine1);
        currentRuleNoCosedLine = extractSegment(pointsLine1, indexA, indexB);
        line.push(...currentRuleNoCosedLine);
      }
      i++;
    }
    currentRuleClosedLine.value = line;
  }
  if (lineRule.type <= 1) {
    alignVithLine.value = model2D.value.list[currentChartId].filter((n) => {
      return n.line == (lineRule.type == 1 ? "blade curve" : "line1");
    });
  } else {
    alignVithLine.value = null;
  }
};
const changeType = (e) => {
  store.commit("SET_MODEL_2D_TYPE", e);
};
function getPointFromLine(x, y, points) {
  let results = [];

    for (let i = 0; i < points.length; i++) {
      let [x1, y1] =[points[i].x,points[i].y];
        let [x2, y2] = [points[(i + 1) % points.length].x,points[(i + 1) % points.length].y]; // Ensuring the last point connects to the first point


        // Check if x is within the range of the segment
        if ((x >= x1 && x <= x2) || (x >= x2 && x <= x1)) {

            // Handle vertical line case to avoid division by zero
            if (x1 === x2) {
                if (x === x1) {
                  let yList=[];
                    // If x is exactly the same as the vertical segment's x value
                    for(let j=i;points[(j + 1) % points.length].x==points[j].x; j=(j + 1) % points.length){
                      yList.push(points[j].y);
                      if(j==i-1) break;
                    }
                    let minY = Math.min(...yList);
                    let maxY = Math.max(...yList);

                    if (y >= minY && y <= maxY) {
                        let midY = (maxY + minY) / 2;
                        let symY = 2 * midY - y;
                        results={x: x, y: symY};
                    }

                    break;
                }
            } else {
                // Calculate the slope (m) and intercept (b) of the line segment
                let m = (y2 - y1) / (x2 - x1);
                let b = y1 - m * x1;

                // Calculate the y coordinate corresponding to the given x
                let yCoord = m * x + b;

                if (yCoord !== y) {
                    // Add the point to the result array
                    results={x: x, y: yCoord};
                }
            }
        }
    }
    return results;

}
const changeAlign=(e)=>{
  let alignList=[...alignClass,[4,17]]
  if(chartList.value?.length<=1) return;
 
  if(e){
    
    let pointsData = JSON.parse(JSON.stringify(points2D.value));
    chartList.value.forEach((n,id)=>{
      newData[id] = newData[id] || model2D.value.list[id];
    let alignVithLine2 = model2D.value.list[id].filter((n) => {
      return n.line == "line2";
    });
    alignList.forEach(n=>{
      let pointResult=getPointFromLine(pointsData[id][n[0]*2],pointsData[id][n[0]*2+1], alignVithLine2)
            
      pointsData[id][n[1]*2]=pointsData[id][n[0]*2];
      pointsData[id][n[1]*2+1]=pointResult.y;

      newData[id] = newData[id].map((li) => {
            
              return li?.pointId == n[1]
                ? { ...li, x: pointsData[id][n[0]*2], y: pointResult.y, initX: li.x, initY: li.y }
                : li;
            });
          
    })
    chartList.value[id].changeData(newData[id]);
    })
    
    
    store.commit("SET_POINTS_2D", pointsData);
  }
}
const mesContent = ref('Loading...');
const updatePoints2D=computed(() => store.state.control.updatePoints2D);
watch(updatePoints2D,async (newVal)=>{
  if(newVal>0){
    mesContent.value=`Importing files...`;
    let changeList=[];
    message.loading({ content: mesContent.value, key:'load2d' });
    
      for (let id = 0; id < chartList.value.length; id++) {
    await new Promise((resolve) => {
      setTimeout(() => {
        mesContent.value = `Layer ${id + 1} is being calculated`;

        // 立即显示加载信息
        message.loading({ content: mesContent.value, key: 'load2d' });

        newData[id] = newData[id].map((li) => {
      if( li?.pointId !== undefined && (li.x!=points2D.value[id][li?.pointId * 2] || li.y!=points2D.value[id][li?.pointId * 2 + 1])){
        let result={
            ...li,
            x: points2D.value[id][li?.pointId * 2],
            y: points2D.value[id][li?.pointId * 2 + 1],
            initX: li.x,
            initY: li.y,
          }
        changeList.push({class:id,...result});
        return result
      }else return li;
      
    });

        chartList.value[id].changeData(newData[id]);
        resolve(); // 标记当前层的操作完成
      }, id * 50); // 50ms 延迟
    }); 
   }
    mesContent.value=`Verification completed`;
    message.success({ content: mesContent.value, key:'load2d', duration: 2 });
    
    if (changeList.length>0){
      
      let mes='';
      changeList.map((n)=>{
        mes+=`Layer ${n.class+1} point ${n.pointId+1} is changed from (${n.initX},${n.initY}) to (${n.x},${n.y})\n`;
      })
      console.log(mes);
      notification.open({
    message: 'Import tips',
    description:mes
  });
    }
  }
})
watch(model2D, (newVal) => {
  newData = JSON.parse(JSON.stringify(model2D.value.list));
  if (newVal?.list?.length) {
    chartList.value.forEach((n) => {
      if (n) {
        n.destroy();
      }
    });
    chartList.value = newVal.list.map(() => {
      return null;
    });
    
    updateWidHei(modelParent.value);
    newVal.list.forEach((data, id) => {
      chartFilterData.value[id] = data;
      loadChart(data, id);
    });
  }
});
</script>
<style scoped>
.modelParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  display: -moz-box;
  -moz-box-orient: horizontal;
  -moz-box-pack: center;
  -moz-box-align: center;

  display: -o-box;
  -o-box-orient: horizontal;
  -o-box-pack: center;
  -o-box-align: center;

  display: -ms-box;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -ms-box-align: center;

  display: box;
  box-orient: horizontal;
  box-pack: center;
  box-align: center;
}
.pointMark {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: green;
}
.typeChange {
  z-index: 2;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

#moel2D {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#moel2D > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 40px;
  right: 0;
  z-index: 1;
}
.tips2D{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-size: 12px;
  padding-left: 10px;
  
}
.tips2D .ant-form-item{
    margin-bottom: 0;
  }
</style>
