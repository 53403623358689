<template>
  <form class="form">
    <div class="line">
      <label class="column1">Dim</label>
      <label class="column2">Min</label>
      <label class="column3">Max</label>
    </div>

    <div class="line">
      <label>I</label>
      <a-input-number v-model:value="iMin" :min="0" :max="iMax" @change="changValue" />
      <a-input-number v-model:value="iMax" :min="iMin" :max="maxVals[0]" @change="changValue" />
    </div>

    <div class="line">
      <label>J</label>
      <a-input-number v-model:value="jMin" :min="0" :max="jMax" @change="changValue" />
      <a-input-number v-model:value="jMax" :min="jMin" :max="maxVals[1]" @change="changValue" />
    </div>

    <div class="line">
      <label>K</label>
      <a-input-number v-model:value="kMin" :min="0" :max="kMax" @change="changValue" />
      <a-input-number v-model:value="kMax" :min="kMin" :max="maxVals[2]" @change="changValue" />
    </div>

    <div class="line">
      <label>O3</label>
      <a-input-number v-model:value="o3Min" :min="0" :max="o3Max" @change="changValue" />
      <a-input-number v-model:value="o3Max" :min="o3Min" :max="maxVals[3]" @change="changValue" />
    </div>

    <a-button @click="reset" block>Reset</a-button>
  </form>
</template>

<script setup>
import { ref,onDeactivated,computed,watch } from "vue";
import model from "@/api/modules/model";
import { to } from "@/utils/utils";
import { useStore } from "vuex";
import {debounce} from 'lodash'

const store = useStore();

const iMin = ref(0);
const iMax = ref(0);
const jMin = ref(0);
const jMax = ref(0);
const kMin = ref(0);
const kMax = ref(0);
const o3Min = ref(0);
const o3Max = ref(0);
const maxVals = ref([]);

const currentModelName = computed(() => store.state.control.currentModelName);
watch(currentModelName,()=>{
  getMaxVal();
})
async function getMaxVal() {
  const [err, res] = await to(model.getMaxIndicesDataOfIJK());
  if (err) {
    return;
  }

  window.addLog.info("getMaxIndicesDataOfIJK successfully");
  maxVals.value = res.data || [];
  iMax.value = maxVals.value[0] || 8;
  jMax.value = maxVals.value[1] || 5;
  kMax.value = maxVals.value[2] || 6;
  o3Max.value = maxVals.value[3] || 3;
}

getMaxVal();

function reset() {
  iMin.value = 0;
  iMax.value = maxVals.value[0] || 8;
  jMin.value = 0;
  jMax.value = maxVals.value[1] || 5;
  kMin.value = 0;
  kMax.value = maxVals.value[2] || 6;
  o3Min.value = 0;
  o3Max.value = maxVals.value[3] || 3;

  changValue();
}

// const IJKLines = computed(() => store.state.control.IJKLines);
// const IJKFaces = computed(() => store.state.control.IJKFaces);
// const IJKVolumns = computed(() => store.state.control.IJKVolumns);
// const getDiff=(arr1, arr2)=> {
//   const set1 = new Set(arr1);
// const set2 = new Set(arr2);

// return [...arr1.filter(x => !set2.has(x)), ...arr2.filter(x => !set1.has(x))];

// }
const changValue=debounce(async()=> {
  const a = [iMin.value, jMin.value, kMin.value, o3Min.value];
  const b = [iMax.value, jMax.value, kMax.value, o3Max.value];

  const [err1, res1] = await to(model.getLineIndexesFromIJK({ a, b }));
  if (err1) {
    return;
  }
  
  store.commit("SET_IJK_LINES",res1.data)
  const [err2, res2] = await to(model.getSurfaceIndexesFromIJK({ a, b }));
  if (err2) {
    return;
  }

  store.commit("SET_IJK_FACES",res2.data)
  const [err3, res3] = await to(model.getVolumnIndexesFromIJK({ a, b }));
  if (err3) {
    return;
  }
    
  store.commit("SET_IJK_VOLUMNS",res3.data);
  setTimeout(() => {
    store.commit("RESET_IJK_STATUS");    
  });
},300)

onDeactivated(()=>{
  reset();
  changValue();
})
</script>

<style scoped>
form {
  padding: 10px;
  font-family: serif;
}

.line {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

label {
  height: 25px;
  float: left;
  width: 80px;
  line-height: 25px;
  font-size: 14px;
}
.ant-input-number{
  width: 60px;
}
.ant-input-number:nth-of-type(even){
  margin-left: 20px;
}
</style>
